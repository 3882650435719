<!--会员管理-->
<template>
  <div class="billRecord">
    <el-card class="box-card">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="记录编号：">
          <el-input placeholder="请输入记录编号" v-model="formInline.id" clearable></el-input>
        </el-form-item>
        <el-form-item label="用户手机号：">
          <el-input placeholder="请输入手机号" v-model="formInline.phone" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="userTab_box">
      <el-table v-loading="loading" class="c-table" :data="tableData.list" style="width: 100%">
        <el-table-column prop="id" label="记录编号">
          <template slot-scope="scope">
            <span :title="scope.row.id">{{scope.row.id}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="consumptionType" label="类型" width="120px">
          <template slot-scope="scope">
            {{typeConsumptionMap[scope.row.consumptionType]}}
          </template>
        </el-table-column>
        <el-table-column prop="chargeMoney" label="金额" align="right" widht="200px">
          <template slot-scope="scope">
            {{(getPayType(scope.row.consumptionType) + '￥' + toMoney(Math.abs(scope.row.chargeMoney)))}}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号">
          <template slot-scope="scope">
            {{scope.row.phone}}
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="交易方式" width="100px">
          <template slot-scope="scope">
            {{typeChanelMap[scope.row.channel]}}
          </template>
        </el-table-column>
        <el-table-column prop="serialNumber" label="流水号" width="300px">
          <template slot-scope="scope">
            {{scope.row.serialNumber }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <yd-page
      :page-config="pageConfig"
      :size-change="handleSizeChange"
      :current-change="handleCurrentChange"
    ></yd-page>
  </div>
</template>

<script>
import {constantValue} from '../../plugins/constantValue';

export default {
  name: 'billRecord',
  data() {
    return {
      tableData: [],
      formInline: {
        id: '',
        phone: ''
      },
      typeConsumptionMap: constantValue.typeConsumptionMap,
      typeChanelMap: constantValue.typeChanelMap,
      pageConfig: {
        page: 1,
        rows: 10
      },
      loading: false,
      oldSearchCondition: {}
    };
  },
  methods: {
    getPayType(type) {
      const addMap = {
        1: true,
        2: true,
        7: true
      }
      return addMap[type] ? '+' : '-'
    },
    // 搜索
    onQuery() {
      this.pageConfig.page = 1;
      this.oldSearchCondition = { ...this.formInline };
      this.getUserManageList();
    },
    handleSizeChange(val) {

      this.pageConfig.page = 1;
      this.pageConfig.rows = val;
      this.getUserManageList();
    },
    handleCurrentChange(val) {
      this.pageConfig.page = val;
      this.getUserManageList();
    },
    // 获取用户列表
    getUserManageList() {
      this.loading = true;
      this.formInline = {...this.oldSearchCondition, ...this.pageConfig};
      this.$axios({
          method: 'get',
          url: '/finance/getBillingList',
          params: this.formInline
        }).then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.tableData = res.data;
            this.pageConfig = this.$getPageConfig(res)
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
          this.$showAlert(e.message, 'error')
        })
    },
    // 重置
    onReset() {
      this.formInline.id = '';
      this.formInline.phone = '';
      this.pageConfig = {};
      this.oldSearchCondition = {};
      this.getUserManageList();
    }
  },
  created() {
    this.getUserManageList();
    console.log(this.$store.state.menuActive);
  }
};
</script>

<style scoped lang="scss">
.billRecord {
  .box-card {
    margin-bottom: 20px;
  }
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .userTab_box {
    margin-bottom: 20px;
  }
}
</style>
