<!--伞位订单详情-->
<template>
  <div class="OrderDetail order-common" v-loading="loading">
    <div class="title-box">
      <div class="icon"></div>
      <div class="title">订单编号：{{orderDetailObj.orderCode}}</div>
      <div
        class="operate"
      >{{orderDetailObj.orderStatus ? orderStatus[orderDetailObj.orderStatus] : '-'}}</div>
    </div>
    <div class="main-box">
      <div class="head">订单信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">订单来源：</div>
          <div
            class="value"
          >{{orderDetailObj.orderSource ? orderSource[orderDetailObj.orderSource] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单编号：</div>
          <div class="value">{{orderDetailObj.orderCode ? orderDetailObj.orderCode : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单金额：</div>
          <div
            class="value"
          >{{orderDetailObj.totalPrice ? ('￥' + toMoney(orderDetailObj.totalPrice)) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="value">{{orderDetailObj.createTime ? orderDetailObj.createTime : '-'}}</div>
        </div>
        <div class="item">
          <!-- 预付部分/免单/全额付款 -->
          <div class="label">付款类型：</div>
          <div
            class="value"
          >{{orderDetailObj.payStatus ? payTypeMap[orderDetailObj.payType] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付状态：</div>
          <div
            class="value"
          >{{orderDetailObj.payStatus ? payStatus[orderDetailObj.payStatus] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付金额：</div>
          <div
            class="value"
          >{{orderDetailObj.actualAmount !== null ? ('￥' + toMoney(orderDetailObj.actualAmount)) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付时间：</div>
          <div class="value">{{orderDetailObj.payTime ? orderDetailObj.payTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付方式：</div>
          <div
            class="value"
          >{{orderDetailObj.platformType ? platformType[orderDetailObj.platformType] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">流水号：</div>
          <div class="value">{{orderDetailObj.transactionNo ? orderDetailObj.transactionNo : '-'}}</div>
        </div>
        <div class="item">
          <!-- 待支付/已支付/免单 -->
          <div class="label">尾款状态：</div>
          <div
            class="value"
          >{{orderDetailObj.payStatus ? payStatus[orderDetailObj.payStatus] : '-'}}</div>
        </div>
        <div class="item" v-if="orderDetailObj.payType === 'part'">
          <div class="label">尾款金额：</div>
          <div class="value">¥ {{orderDetailObj.partPayMoney ? orderDetailObj.partPayMoney : '-'}}</div>
        </div>
        <div class="item" v-if="orderDetailObj.payType === 'part'">
          <div class="label">尾款支付时间：</div>
          <div class="value">{{orderDetailObj.partPayTime ? orderDetailObj.partPayTime : '-'}}</div>
        </div>
        <div class="item" v-if="orderDetailObj.payType === 'part'">
          <div class="label">尾款支付方式：</div>
          <div
            class="value"
          >{{orderDetailObj.partPayType ? platformType[orderDetailObj.partPayType] : '-'}}</div>
        </div>
        <div v-if="orderDetailObj.payType === 'free'" class="item">
          <!-- 免单理由 -->
          <div class="label">免单理由：</div>
          <div
            class="value"
          >
          {{orderDetailObj.reason ? orderDetailObj.reason : '-'}}
          </div>
        </div>
        
      </div>
    </div>
    <div class="main-box">
      <div class="head">租赁信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">会员姓名：</div>
          <div class="value">{{orderDetailObj.userName ? orderDetailObj.userName : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">会员手机号：</div>
          <div class="value">{{orderDetailObj.userTelephone ? orderDetailObj.userTelephone : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">使用时间：</div>
          <div
            class="value"
          >{{orderDetailObj.useStartTime ? formatTime(orderDetailObj.useStartTime) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">伞位信息：</div>
          <div class="value umbre-value">
            <div v-for="(item,index) in orderDetailObj.leaseOrderItemList" :key="index">
              <span>{{item.leaseName ? item.leaseName : '-'}}</span>
              <span>{{item.leaseCode ? item.leaseCode : '-'}}</span>
              <span>{{item.orderStatus ? orderStatus[item.orderStatus] : '-'}}</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">备注信息：</div>
          <div class="value">{{orderDetailObj.remark ? orderDetailObj.remark : '-'}}</div>
        </div>
      </div>
    </div>
    <div class="main-box" v-if="orderDetailObj.orderStatus === 'CONSULTATION_CANCEL'">
      <div class="head">协商结果</div>
      <div class="item-list">
        <div class="item">
          <div class="label">退款金额：</div>
          <div
            class="value"
          >{{orderDetailObj.refundAmount ? toMoney(orderDetailObj.refundAmount) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">取消时间：</div>
          <div class="value">{{orderDetailObj.cancelTime ? orderDetailObj.cancelTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">退款方式：</div>
          <div
            class="value"
          >{{orderDetailObj.refundType ? refundType[orderDetailObj.refundType] : '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constantValue } from '../../plugins/constantValue';

export default {
  name: 'OrderDetail',
  data() {
    return {
      loading: false,
      orderDetailObj: {},
      leaseCode: '',
      payStatus: constantValue.payStatus,
      orderSource: constantValue.orderSource,
      platformType: constantValue.platformType,
      refundType: constantValue.refundType,
      orderStatus: constantValue.orderStatus,
      payTypeMap: {
        'all': '全额付款',
        'part': '预付部分',
        'free': '免单'
      }
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      this.loading = true;
      let id = this.$route.query.id;
      this.$axios({
        method: 'get',
        url: `/leaseOrder4Web/${id}`
      })
        .then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.orderDetailObj = res.data;
            if (res.data.leaseOrderItemList.length > 0) {
              let leaseCode = res.data.leaseOrderItemList.map(function(item) {
                return item.leaseCode;
              });
              this.leaseCode = leaseCode.toString();
            }
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/css/order-detail.scss";

.OrderDetail {
  width: 800px;
  margin: 0 auto;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
