<!--灯控管理-->
<template>
  <div class="LightManage" v-loading="loading">

      <el-card>
        <div class="div-row">
          <span style="margin-right: 20px;"><b>项目名称:</b> {{item.name}}</span>
          <span style="margin-right: 20px;"><b>所在位置:</b> {{item.locationName}}</span>
          <span style="margin-right: 20px;"><b>项目编号:</b> {{item.projectNo}}</span>
          <div class="div-right">
            <span style="margin-right: 20px;"><b>装机数:</b> {{item.machineCount}}</span>
            <span style="margin-right: 20px;"><b>网关数:</b> {{item.gatewayCount}}</span>
            <span style="margin-right: 20px;"><b>在线:</b> {{item.onlineCount}}</span>
            <span style="margin-right: 20px;"><b>故障:</b> {{item.faultCount}}</span>
            <span><b>离线：</b>{{item.offlineCount}}</span>
          </div>
        </div>
        <div class="div-btn">
          <el-button type="primary" class="c-btn" size="mini" @click="operateAll(item, 0)">全开</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="operateAll(item, 1)">全关</el-button>
        </div>
      </el-card>

      <div class="card-table">
        <el-table class="c-table" :data="lamps" style="width: 100%">
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="number" label="路灯编号" >
            <template slot-scope="scope">
              <span>{{scope.row.number}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="section" label="路段" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.section}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="路灯状态" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.status}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="light" label="亮度" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.light}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="online" label="在线状态" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.online}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.updateTime}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="fault" label="是否故障" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.fault ? '是' : '否'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <div class="operate">
                <el-button
                  type="text"
                  size="small"
                  @click="operate(scope.row)"
                >{{scope.row.onoff ? '关闭' : '打开'}}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>


    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isConfirmShow"
      width="420px"
      title="提示"
      center
      v-loading="confirmLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>是否进行{{type == 0 ? '全开' : '全关'}}操作</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onConfirm()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'lightManage',
  data() {
    return {
      loading: false,
      item:{},
      lamps:[],
      tableData: [
        {
          id: 0,
          cardNumber: '12345',
          kammi: 'A001',
          denomination: '1000',
          cardPrice: '80',
          status: '已使用'
        },
      ],
      currentData: {},
      type: 0,
      isConfirmShow: false,
      confirmLoading: false,
      websocket: null,
      randomCode: ''
    };
  },
  created() {
    this.getProjectList();
    this.initWebSocket();
  },
  destroyed(){
    this.websocket.close();
  },
  methods: {
    getProjectList() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/streetlamp/project',
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          if(res.data != null){
            this.item = res.data[0];
            this.getLampList(this.item.id);
          }
        } else {
          this.showAlert(res.message, 'error');
        }
      })
      .catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    genericUserCode(){
        var code = '';
        var codeLength = 8;
        var random = new Array(0,1,2,3,4,5,6,7,8,9);
        for(var i = 0; i < codeLength; i++){
          var index = Math.floor(Math.random() * 9);
          code += random[index];
        }
        this.randomCode = code;
    },
    initWebSocket(){
        this.genericUserCode();
        //初始化websocket
        var wsuri = process.env.VUE_APP_BASE_PATH + ':' + process.env.VUE_APP_BASE_PORT + "/ws/"+ this.randomCode;
        var index = wsuri.indexOf("//");
        wsuri = wsuri.substr(index,wsuri.length);
        wsuri = "ws:" + wsuri;
        console.log("wsuri->"+wsuri);
        this.websocket = new WebSocket(wsuri);
        this.websocket.onmessage = this.onWebSocketMessage;
        this.websocket.onopen = this.onWebSocketOpen;
        this.websocket.onerror = this.onWebSocketError;
        this.websocket.onclose = this.onWebSocketClose;
    },
    onWebSocketMessage(e){
      // const receivedData = JSON.parse(e.data);
      console.log(JSON.stringify(e));
    },
    onWebSocketOpen(){
      let info = {"userId":randomCode};
      this.onWebSocketSend(JSON.stringify(info));
    },
    onWebSocketError(){
      initWebSocket();
    },
    onWebSocketClose(e){
      console.log("断开连接",e);
    },
    onWebSocketSend(data){
      this.websocket.send(data);
    },
    getLampList(id){
      this.$axios({
        method: 'get',
        url: '/streetlamp/'+id+'/lamp',
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.lamps = res.data;
          console.log(this.lamps);
        } else {
          return [];
        }
      })
      .catch(e => {
        return [];
      });
    },
    operateAll(data, type) {
      this.currentData = data;
      this.type = type;
      this.isConfirmShow = true;
    },
    onConfirm() {
      this.$axios({
        method: 'post',
        url: '/streetlamp/'+this.item.id+'/allcontrol',
        params: {
          onoff: this.type,
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('操作成功', 'success');
        } else {
          this.$showAlert(res.message, 'error');
        }
      })
      .catch(e => {
        this.$showAlert(e.message, 'error');
      });
      this.isConfirmShow = false;
    },
    operate(row){
      if(row.onoff){
        this.type = 1;
      }else{
        this.type = 0;
      }
      this.$axios({
        method: 'post',
        url: '/streetlamp/'+row.id+'/control',
        params: {
          onoff: this.type,
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert(res.message, 'success');
        } else {
          this.$showAlert(res.message, 'error');
        }
      })
      .catch(e => {
        this.$showAlert(e.message, 'error');
      });
    }
  }
};
</script>

<style scoped lang="scss">
.LightManage {
  padding:20px;
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .div-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    color: #676a6c;
    margin-bottom: 20px;
    .div-right {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .div-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
