




















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({

  /* 这里和js版本编写的 vue 组件内容相同，
   * 凡是不能在ts里面完成的都可以在这里完成
   * 最终会被合并到一个实例中。
   * 在这里定义的内容，不会被语法器获取到，因此必须要同步在class中声明
   */
  data() {
    return {}
  },
  props: {
    // isShowResDialog:
  }
})

export default class ReservationDialog extends Vue {
  // data
  loading: boolean = false

  // props
  @Prop(Boolean) isShowConfirmDialog!: boolean;
  @Prop(Object) confirmData: any;

  // methods
  handleClose() {
    this.$emit('close')
  }

  saveAccount() {
    this.$axios({
      method: 'post',
      url: '/leaseOrder4Web/createOrder',
      data: this.confirmData
    }).then((res: any) => {
      // this.loading = false;
      if (res.code === 200) {
        console.log('预约成功res', res);
        this.$emit('handleClose');
        this.$emit('refreshUmbrella');
      } else {
        // 请求出错
        this.$showAlert(res.message, 'error')
      }
    }).catch(() => {
      this.loading = false;
    })
  }

  // computed
  // 生命周期
  created() {
    console.log('created')
  }
}
