/* eslint-disable space-in-parens */
<!--房型管理-->
<template>
  <div class="RoomType">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="房型">
          <el-input v-model="formData.type" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-div">
          <el-button type="primary" class="c-btn" size="mini" @click="showAddModal({})">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="cardNumber" label="房型">
          <template slot-scope="scope">
            <span>{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="创建时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showAddModal(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="showDeleteModal(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>确认删除当前房型</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      :title="currentData.id != null ? '编辑房型' : '新增房型'"
      :visible.sync="isAddShow"
      width="50%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="currentData" status-icon :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="房型：" prop="type">
            <el-input v-model="currentData.type" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RoomType',
  data() {
    return {
      loading: false,
      formData: {},
      deleteId: 0,
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableData: [],
      isDeleteShow: false,
      isAddShow: false,
      deleteLoading: false,
      addLoading: false,
      currentData: {},
      rules: {
        type: [{ required: true, message: '房型不可为空！', trigger: 'blur' }]
      },
      typeList: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios({
        method: 'get',
        url: '/room/type/list',
        params: this.pageConfig
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.pageConfig = this.$getPageConfig1(res);
        } else {
          this.$showAlert('获取房型信息异常', 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getList();
    },
    onQuery() {
      // this.pageConfig.pageNum = 1;
      this.queryRoomType();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getList();
    },
    showDeleteModal(data) {
      this.currentData = { ...data };
      this.isDeleteShow = true;
    },
    onDelete() {
      this.delRoomType();
    },
    showAddModal(data) {
      this.currentData = { ...data };
      this.isAddShow = true;
    },
    submitData() {
      if (this.currentData.id == null) {
        this.addRoomType();
      } else {
        this.editRoomType();
      }
    },
    addRoomType() {
      this.$axios({
        method: 'post',
        url: '/room/type',
        params: {
          type: this.currentData.type
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('新增房型成功', 'success');
          this.isAddShow = false;
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    editRoomType() {
      this.$axios({
        method: 'put',
        url: '/room/type',
        params: {
          id: this.currentData.id,
          type: this.currentData.type
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('修改房型成功', 'success');
          this.isAddShow = false;
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    delRoomType() {
      this.$axios({
        method: 'delete',
        url: '/room/type',
        params: {
          id: this.currentData.id
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('删除房型成功', 'success');
          this.isDeleteShow = false;
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    queryRoomType() {
      this.$axios({
        method: 'get',
        url: '/room/type/like',
        params: {
          param: this.formData.type
        }
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data;
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    }
  }
};
</script>

<style scoped lang="scss">
.RoomType {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .add-div {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
