<!--房位描述-->
<template>
  <div class="RoomDesc">
    <el-card>
      <el-form
        :inline="true"
        :model="formData"
        class="form-inline"
        style="padding-right: 100px;"
        label-width="80px"
      >
        <el-form-item label="房号">
          <el-input v-model="formData.roomNumber" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="房名">
          <el-input v-model="formData.roomName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="formData.commidity" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="房型">
          <el-select v-model="formData.typeId" placeholder="请选择">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.type" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="退款规则">
           <el-select v-model="formData.ruleId" placeholder="请选择">
            <el-option v-for="(item, index) in ruleList" :key="index" :label="item.ruleName" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 20px;" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" style="margin-left: 20px;" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        class="c-btn"
        size="mini"
        @click="showAddModal({})"
        style="margin-top: 10px;"
      >新增</el-button>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="roomNumber" label="房号">
          <template slot-scope="scope">
            <span>{{scope.row.roomNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roomName" label="房名">
          <template slot-scope="scope">
            <span>{{scope.row.roomName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="commidity" label="商品名称">
          <template slot-scope="scope">
            <span>{{scope.row.commidity}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="房型">
          <template slot-scope="scope">
            <span>{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roomPrice" label="价格">
          <template slot-scope="scope">
            <span>{{scope.row.roomPrice}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ruleName" label="退款规则">
          <template slot-scope="scope">
            <span>{{scope.row.ruleName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roomImg" label="商品图片">
          <template slot-scope="scope">
            <img :src="scope.row.roomImg" class="img">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="toDetail(scope.row.id)">详情</el-button>
              <el-button type="text" size="small" @click="showAddModal(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="showDeleteModal(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>确认删除当前房位描述</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      :title="currentData.id != null ? '编辑房位描述' : '新增房位描述'"
      :visible.sync="isAddShow"
      width="50%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="currentData" status-icon :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="房名：" prop="roomName">
            <el-select v-model="currentData.roomName" placeholder="请选择" @change="getRoomName">
            <el-option v-for="(item, index) in nameList" :key="index" :label="item.roomName" :value="{value:item.roomPrice,label:item.roomName,id:item.id}"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称：" prop="name">
            <el-input v-model="currentData.commidity" disabled></el-input>
          </el-form-item>
          <el-form-item label="价格：" prop="price">
            <el-input v-model="currentData.roomPrice" type="number" v-enter-number disabled></el-input>
          </el-form-item>
          <el-form-item label="退款规则：" prop="refundRule">
            <el-select v-model="currentData.ruleId" placeholder="请选择">
            <el-option v-for="(item, index) in ruleList" :key="index" :label="item.ruleName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品描述：" prop="productDesc">
            <el-input
              v-model="currentData.desc"
              placeholder="请输入"
              type="textarea"
              :maxlength="200"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="入住须知：" prop="checkInDesc">
            <el-input
              v-model="currentData.accessNotice"
              placeholder="请输入"
              type="textarea"
              :maxlength="200"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品图片：" prop="imgs">
            <el-upload
              name="uploadFile"
              :action="imgUrl"
              list-type="picture-card"
              :file-list="fileList"
              :on-success="handleFileSuccess"
              :on-preview="handlePreview"
              :on-remove="handleFileRemove"
              :headers="headers"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="展示详情：" prop="detail">
            <yd-ue v-model="currentData.detail"></yd-ue>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="imgDialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>

<script>
import { baseServiceUrl,getCookie} from "@/plugins/util";
export default {
  name: "RoomDesc",
  data() {
    return {
      loading: false,
      deleteId: 0,
      imgUrl: baseServiceUrl() + "/image/upload",
      formData: {},
      searchCondition: {},
      headers: {
        token: getCookie('token')
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableData: [
        {
          id: 0,
          cardNumber: "12345",
          kammi: "A001",
          denomination: "1000",
          cardPrice: "80",
          status: "已使用"
        },
        {
          id: 1,
          cardNumber: "12345",
          kammi: "A001",
          denomination: "1000",
          cardPrice: "80",
          status: "未使用"
        }
      ],
      dialogImageUrl: '',
      imgDialogVisible: false,
      isDeleteShow: false,
      isAddShow: false,
      deleteLoading: false,
      addLoading: false,
      currentData: {},
      fileList: [],
      nameList: [],
      ruleList: [],
      typeList: [],
      imgList: [],
      rules: {
        roomName: [{ required: true, message: "房名不可为空！", trigger: "blur" }],
        name: [{ required: true, message: "商品名称不可为空！", trigger: "blur" }],
        price: [{ required: true, message: "价格不可为空！", trigger: "blur" }],
        refundRule: [{ required: true, message: "退款规则不可为空！", trigger: "blur" }]
      }
    };
  },
  created() {
    this.getList();
    this.getName();
    this.getRule();
    this.getType();
    console.log(this.imgUrl)
  },
  methods: {
    getList() {
       this.loading = true;
      this.$axios({
        method: "post",
        url: "/room/desc/list",
        params: this.pageConfig,
        data: {
          roomNumber: this.formData.roomNumber,
          commidity: this.formData.commidity,
          roomName: this.formData.roomName,
          roomType: this.formData.typeId,
          ruleId: this.formData.ruleId,
        }
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getList();
    },
    /**
     * 上传文件成功返回
     */
    handleFileSuccess(response, file) {
      this.fileList.push({
        url: response.data[file.name]
      })
      for(var i = 0;i < this.fileList.length;i++){
        if(this.imgList.indexOf(this.fileList[i].url) == -1){
          this.imgList.push(this.fileList[i].url)
        }
        this.currentData.roomImg = this.imgList.toString()
      }
      console.log(this.currentData.roomImg)
    },
    /**
     * 移除文件
     */
    handleFileRemove(file, fileList) {
      this.fileList = fileList;
      this.imgList = []
      for(var i = 0;i < this.fileList.length;i++){
          this.imgList.push(this.fileList[i].url)
        }
        this.currentData.roomImg = this.imgList.toString()
      console.log(this.currentData.roomImg)
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    toDetail(data) {
      this.currentData = { ...data };
      this.isAddShow = true;
    },
    showDeleteModal(data) {
      this.currentData = { ...data };
      this.isDeleteShow = true;
    },
    onDelete() {
      this.delRoomDesc();
    },
    showAddModal(data) {
      this.currentData = { ...data };
      this.fileList = []
      let arr = []
      if(this.currentData.roomImg != null){
        arr = this.currentData.roomImg.split(",")
      }
      for(var i = 0;i<arr.length;i++){
        this.fileList.push({
          url: arr[i]
      })
      }
      this.isAddShow = true;
    },
    submitData() {
       if (this.currentData.id == null) {
        this.addRoomDesc();
      } else {
        this.editRoomDesc();
      }
    },
    addRoomDesc(){
      this.$axios({
        method: 'put',
        url: '/room/desc',
        data: this.currentData
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('新增成功', 'success');
          this.isAddShow = false
          this.fileList = []
          this.imgList = []
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$$showAlert(e.message, 'error');
      })
    },
    editRoomDesc(){
      this.$axios({
        method: 'put',
        url: '/room/desc',
        data: this.currentData
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('修改成功', 'success');
          this.isAddShow = false
          this.fileList = []
          this.imgList = []
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$$showAlert(e.message, 'error');
      })
    },
    getName() {
      this.$axios({
        method: "get",
        url: "/room/desc/name"
      }).then(res => {
        if (res.code === 200){
          this.nameList = res.data;
        } else{
          this.$showAlert(res.message, 'error');
        }
      }).catch (e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getRule() {
      this.$axios({
        method: "get",
        url: "/room/desc/rule"
      }).then(res => {
        if (res.code === 200){
          this.ruleList = res.data;
        } else{
          this.$showAlert(res.message, 'error');
        }
      }).catch (e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getType() {
      this.$axios({
        method: "get",
        url: "/room/type"
      }).then(res => {
        if (res.code === 200){
          this.typeList = res.data;
        } else{
          this.$showAlert(res.message, 'error');
        }
      }).catch (e => {
        this.$showAlert(e.message, 'error');
      })
    },
    delRoomDesc() {
      this.$axios({
        method: 'delete',
        url: '/room/desc',
        params: {
          id: this.currentData.id
        }
      }).then(res => {
        this.isDeleteShow = false;
        if (res.code === 200) {
          this.$showAlert('删除成功', 'success');
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getRoomName(params){
    const { value, label, id} = params;
    this.currentData.commidity = label
    this.currentData.roomName = label
    this.currentData.roomPrice = value
    this.currentData.id = id
    console.log(this.currentData)
  }
  },
  
};
</script>

<style scoped lang="scss">
.RoomDesc {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .img {
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }
}
</style>
