// 配置所有页面的路由和页面地址
import RoleManage from '../views/baseManage/RoleManage.vue';
import UserManage from '../views/baseManage/UserManage.vue';
import AccountManage from '../views/baseManage/AccountManage.vue';
import MenuManage from '../views/settings/MenuManage.vue';
import PublicNumberSet from '../views/settings/PublicNumberSet.vue';
import HolidaysSet from '../views/settings/HolidaysSet.vue';
import WxMenuSet from '../views/settings/WxMenuSet.vue';
import WxReplySet from '../views/settings/WxReplySet.vue';
import CardManage from '../views/marketManage/CardManage.vue';
import RechargeSet from '../views/marketManage/RechargeSet.vue';
import BillRecord from '../views/financialManage/BillRecord.vue';
import Umbrella from '../views/ordeCenter/Umbrella.vue';
import UmbrellaManage from '@/views/smartService/UmbrellaManage.vue';
import Wristband from '../views/ordeCenter/wristband/Wristband.vue';
import WristbandManage from '@/views/smartService/wristband/WristbandManage.vue';
import LockerManage from '@/views/smartService/locker/LockerManage.vue';
import Locker from '../views/ordeCenter/locker/Locker.vue';
import Room from '../views/ordeCenter/room/Room.vue';
import RoomManage from '@/views/smartService/room/RoomManage.vue';
import LightManage from '@/views/smartService/light/LightManage.vue';
import OperateAnalysis from '@/views/operateAnalysis/OperateAnalysis.vue'

export const pageAndRouter = [
  {
    path: 'roleManage',
    component: RoleManage
  },
  {
    path: 'accountManage',
    component: AccountManage
  },
  {
    path: 'userManage',
    component: UserManage
  },
  {
    path: 'menuManage',
    component: MenuManage
  },
  {
    path: 'publicNumberSet',
    component: PublicNumberSet
  },
  {
    path: 'holidaysSet',
    component: HolidaysSet
  },
  {
    path: 'wxMenuSet',
    component: WxMenuSet
  },
  {
    path: 'wxReplySet',
    component: WxReplySet
  },
  {
    path: 'cardManage',
    component: CardManage
  },
  {
    path: 'rechargeSet',
    component: RechargeSet
  },
  {
    path: 'billRecord',
    component: BillRecord
  },
  {
    path: 'umbrella',
    component: Umbrella
  },
  {
    path: 'umbrellaManage',
    component: UmbrellaManage
  },
  {
    path: 'wristband',
    component: Wristband
  },
  {
    path: 'wristbandManage',
    component: WristbandManage
  },
  {
    path: 'lockerManage',
    component: LockerManage
  },
  {
    path: 'locker',
    component: Locker
  },
  {
    path: 'room',
    component: Room
  },
  {
    path: 'roomManage',
    component: RoomManage
  },
  {
    path: 'lightManage',
    component: LightManage
  },
  {
    path: 'userAnalysis',
    component: OperateAnalysis
  },
  {
    path: 'orderAnalysis',
    component: OperateAnalysis
  },
  {
    path: 'financeAnalysis',
    component: OperateAnalysis
  },
  {
    path: 'umbrellaAnalysis',
    component: OperateAnalysis
  }
];
