<template>
  <el-dialog
    class="c-dialog menu-add-dialog"
    :title="pageType === 'edit' ? '编辑菜单' : '新增菜单'"
    v-loading="loading"
    :visible.sync="isShowDialog"
    :close-on-click-modal="false"
    width="700px"
    :before-close="handleClose">
    <div class="content">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="上级菜单：" prop="parentId">
          <el-select v-model="ruleForm.parentId" placeholder="请选择">
            <el-option :label="item.label" :value="item.value" v-for="(item,index) in menuDataList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型：" prop="type" v-if="false">
          <el-select v-model="ruleForm.type" placeholder="请选择菜单类型">
            <el-option label="菜单" :value="1"></el-option>
            <el-option label="按钮" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单链接：" prop="href">
          <el-input v-model="ruleForm.href" placeholder="请输入菜单链接"></el-input>
        </el-form-item>
        <el-form-item label="排序号：" prop="sort">
          <el-input v-model="ruleForm.sort" type="number" v-enter-number placeholder="请输入排序号"></el-input>
        </el-form-item>
        <el-form-item label="权限码：" v-if="false" prop="permission">
          <el-input v-model="ruleForm.permission" placeholder="请输入权限码"></el-input>
        </el-form-item>
        <el-form-item label="icon图标：" prop="css">
          <el-select v-model="ruleForm.css" placeholder="请选择菜单类型">
            <el-option :label="item" :value="item" :key="index" v-for="(item,index) in allIconArr">
              <span class="menu-desc">{{ item}}</span>
              <i class="menu-img-icon" :class="item"></i>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button class="c-btn" @click="handleClose">取 消</el-button>
    <el-button type="primary" class="c-btn" @click="saveMenuData">确 定</el-button>
   </span>
  </el-dialog>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'menuDialog',
    props: {
      menuId: {
        // type: Number,
        require: false
      },
      isShowDialog: {
        type: Boolean,
        require: true
      }
    },
    computed: {
      pageType() {
        if (this.menuId) {
          return 'edit'
        } else {
          return 'add'
        }
      },
      menuTypeId() {
        return this.menuId
      }

    },
    data() {
      return {
        down: true,
        publicPath: process.env.BASE_URL,
        ruleForm: {},
        menuDataList: [],
        allIconArr: [],
        rules: {
          name: [{required: true, message: '请输入菜单名称', trigger: 'blur'}],
          type: [{required: true, message: '请选择菜单类型', trigger: 'blur'}],
          sort: [{required: true, message: '请输入排序号', trigger: 'blur'}],
          parentId: [{required: true, message: '请选择上级菜单', trigger: 'change'}]
        },
        loading: false
      }
    },
    methods: {
      getRoleMenu() {
        this.$axios({
          url: '/menu/currentPermission',
          method: 'get'
        }).then(res => {
          // 根据菜单地址，来添加权限
          if (res.code === 200) {
            let copyData = res.data
            copyData = copyData.filter(row => row.status === 1)
            copyData = copyData.map(item => ({'value': item.id, 'label': item.name}))
            copyData.unshift({'value': 0, 'label': 'root'})
            this.menuDataList = copyData
          } else {
            this.$showAlert(res.message, 'error')
          }
        })
      },
      getIconList() {
        axios({
          url: this.publicPath + 'selection.json',
          method: 'get'
        }).then(res => {
          const resData = res.data.icons
          this.loading = false;
          let arr = resData.map(row => `icon-${row.properties.name}`)
          this.allIconArr = arr;
        }).catch(e => {
          this.loading = false;
        })
      },
      handleClose() {
        this.$emit('close')
      },
      // 保存
      saveMenuData() {
        if (this.menuTypeId) {
          this.updateMenuData();
        } else {
          this.addMenuData();
        }
      },
      // 新增
      addMenuData() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$axios({
              url: '/menu/save',
              method: 'post',
              data: Object.assign(this.ruleForm, {parentId: this.ruleForm.parentId || 0})
            }).then(res => {
              this.loading = false;
              if (res.code === 200) {
                this.$emit('close');
                this.$emit('callBackList');
                this.$showAlert(res.message)
              } else {
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false;
            })
          }
        })
      },
      // 编辑获取菜单详情
      getMenuDetail() {
        this.loading = true;
        this.$axios({
          url: `/menu/${this.menuTypeId}`,
          method: 'get'
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.ruleForm = res.data;
          } else {
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      },
      // 更新
      updateMenuData() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$axios({
              url: '/menu/update',
              method: 'put',
              data: {...this.ruleForm, id: this.menuTypeId, parentId: this.ruleForm.parentId || 0}
            }).then(res => {
              this.loading = false;
              if (res.code === 200) {
                this.$emit('close');
                this.$emit('callBackList');
                this.$showAlert(res.message)
              } else {
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false;
            })
          }
        })
      }
    },
    created() {
      this.getRoleMenu()
      this.getIconList()
      if (this.menuTypeId) {
        this.getMenuDetail();
      } else {
        this.ruleForm.type = 1
      }
    }
  }
</script>
<style scoped lang="scss">
    .menu-desc {
      float: left;
    }
    .menu-img-icon {
      float: right;
      color: #8492a6;
      font-size: 13px
    }
</style>
