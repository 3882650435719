<template>
  <div class="menu">
    <el-menu
      :default-active="menuActive"
      :collapse="isCollapse"
      :unique-opened="true">
      <el-submenu v-for="(item,index) in menuData" :index="index + ''" :key="index + ''">
        <template slot="title">
          <i :class="item.css" class="icon-style"></i>
          <span v-text="item.name"></span>
        </template>
        <el-menu-item-group v-for="(row,index1) in item.child" :key="index1" :index="index1">
          <el-menu-item :index="`${index}-${index1}`">
            <router-link :to="row.href" tag="a">{{row.name}}</router-link>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
  export default {
    name: 'MenuComponent',
    data() {
      return {
        menuData: []
      }
    },
    // 在父组件中通过provider来提供变量，然后在子组件中通过inject来注入变量。这里的父组件是App.vue
    /* 
        provide: {
          for: "demo"
        },
        ...
        inject: ['for'], 
    */
    inject: ['getMenuPermission'],
    computed: {
      menuActive() {
        let indexArr = []
        const arr = this.$store.state.menuActive;
        arr.forEach(row => {
          if (row.index !== undefined) {
            indexArr.push(row.index)
          }
        })
        return indexArr.join('-')
      },
      isCollapse() {
        // 获取展开收起
        return this.$store.state.isCollapse
      }
    },
    methods: {},
    created() {
      this.getMenuPermission().then(res => {
        this.menuData = res
      })
    }
  }
</script>

<style lang="scss">
  .menu {
    .icon-style{
      margin-right: 5px;
    }
    .el-submenu__title {
      color: #a7b1c2;;
    }
    .el-menu {
      background: #1c2d3b;
      border: none;

      .el-menu-item-group {
        background: #15212b;
      }

      .el-submenu__title {
        &:hover, &:focus {
          background: #1ab394;
          color: #fff;
          i{
            color: white;
          }
          .nav-icon:before, .el-submenu__icon-arrow {
            color: #fff;
          }
        }
      }

        .el-menu-item {
          &.is-active,
          &:hover, &:focus {
            background: #1ab394;
            color: #fff;
          }
        }
      }

      .el-menu-item-group__title {
        padding: 0;
      }
      .el-submenu {
        &.is-opened {
          > .el-submenu__title {
            color: #fff;
            background: #182733;
            i {
              color: white;
            }
          }
        }
      }
      .el-submenu .el-menu-item {
        height: 36px;
        line-height: 36px;
        padding: 0;
        min-width: 200px;
        padding-left: 0 !important;
        a {
          text-decoration: none;
          color: #fff;
          display: inline-block;
          padding-left: 50px;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }

    .el-menu--vertical {
      .el-menu {
        background-color: #15212B;
      }
      .el-menu-item-group__title {
        padding: 0;
        line-height: normal;
        font-size: 12px;
        color: #909399;
      }
      .el-menu-item, .el-submenu__title {
        height: 36px;
        line-height: 36px;
      }
      .el-menu--popup-right-start {
        margin: 0;
      }
      .el-menu--popup {
        padding: 0;
        border-radius: 4px;
        overflow: hidden;
        width: 180px;
      }
      .el-menu-item:hover {
        outline: 0;
        background-color: #1ab394;
        a {
          color: #fff;
        }
      }
      .el-menu-item {
        a {
          text-decoration: none;
          color: #8892A1;
          box-sizing: border-box;
        }
      }
    }
</style>
