<!--角色管理-->
<template>
  <div class="roleManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchModel" class="demo-form-inline" style="padding-right: 100px;">
        <el-form-item label="年度">
          <el-input v-model="searchModel.year" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-role">
          <el-button type="primary" class="c-btn" @click="showDialog()">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="role-table" v-loading="loading">
      <el-table class="c-table" :data="tableData">
        <el-table-column type="index" width="200"></el-table-column>
        <el-table-column prop="year" label="年度"></el-table-column>
        <el-table-column prop="beginDate" label="假期开始时间"></el-table-column>
        <el-table-column prop="endDate" label="假期结束时间"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="priceFlag" label="状态">
          <template slot-scope="scope">
            <span v-text="scope.row.priceFlag ? '禁用' : '启用'"></span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showDialog(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="changeStatus(scope.row)"
                         :class="{'red': scope.row.priceFlag === false}">
                {{scope.row.priceFlag === true ? '启用' : '禁用'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <holidayDialog :is-show-dialog="isShowDialog" v-if="isShowDialog" :operate-id="operateId" @close="isShowDialog = false"
                @operateCallBack="searchList"></holidayDialog>
    <yd-page :page-config="pageConfig" :size-change="handleSizeChange" :current-change="handleCurrentChange"></yd-page>
  </div>
</template>

<script>
  import holidayDialog from '../baseManage/dialog/HolidayDialog'

  export default {
    name: 'HolidaysSet',
    components: {
      holidayDialog
    },
    data() {
      return {
        deleteDialog: false,
        isShowDialog: false,
        operateId: '',
        loading: false,
        pageConfig: {
          rows: 10
        },
        oldSearchCondition: {},
        searchModel: {
          year: ''
        },
        tableData: []
      }
    },
    methods: {
      onQuery() {
        // this.pageConfig.page = 1;
        this.oldSearchCondition = {...this.searchModel}
        this.searchList()
      },
      searchList() {
        // this.searchModel = {...this.oldSearchCondition}
        // const params = Object.assign(this.searchModel, this.pageConfig)
        this.loading = false
        this.$axios({
          url: `/holiday/${this.searchModel.year}`,
          method: 'get',
        }).then(res => {
          this.loading = false
          if (res.code === 200) {
            this.tableData = res.data
            this.pageConfig = this.$getPageConfig(res)
          } else {
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false
        })
      },
      showDialog(id) {
        this.operateId = id
        this.isShowDialog = true
      },
      // 重置
      reset() {
        this.searchModel = {}
        this.pageConfig = {}
        this.oldSearchCondition = {}
        this.searchList()
      },
      handleSizeChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.rows = val;
        this.searchList()
      },
      handleCurrentChange(val) {
        this.pageConfig.page = val
        this.searchList()
      },
      // 禁用和启用切换
      changeStatus(item) {
        let value = '';
        if (item.status === 1) {
          value = '禁用'
        } else {
          value = '启用'
        }
        this.$confirm(`是否${value}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'post',
            url: '/role/updateStatus',
            data: {
              id: item.id,
              status: item.status === 0 ? 1 : 0
            }
          }).then(res => {
            if (res.code === 200) {
              this.searchList();
            } else {
              // 请求出错
              this.$showAlert(res.message, 'error')
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    created() {
      this.searchList()
    }
  }
</script>

<style scoped lang="scss">
  .roleManage {
    .box-card {
      margin-bottom: 20px;
    }
    .demo-form-inline {
      position: relative;
      .add-role {
        position: absolute;
        right: 0;
      }
    }
    .c-table {
      .el-table__row {
        .operate {
          .el-button.red {
            color: red;
          }
        }
      }
    }
    .role-table {
      margin-bottom: 20px;
    }

    .el-card.is-always-shadow {
      box-shadow: none;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
</style>
