import Vue from 'vue';
import Router from 'vue-router';
import Login from '../views/Login.vue';
import standardPage from '../views/standardPage.vue';
import umbrellaMapPage from '../views/UmbrellaMapPage.vue';

Vue.use(Router);

const baseRoute = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/standardPage',
    name: 'standardPage',
    component: standardPage
  },
  {
    path: '/screen',
    name: 'umbrellaMapPage',
    component: umbrellaMapPage
  }
];
export default new Router({
  routes: baseRoute
});
