














































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { constantValue } from '@/plugins/constantValue';
import ComfirmDialog from './ComfirmDialog.vue';
import { deepCopy } from '@/plugins/util';

@Component({
  components: {
    ComfirmDialog
  },
  props: {}
})
export default class ReservationDialog extends Vue {
  // data
  loading: boolean = false;
  methodForm: any = {};
  typeListMap: any = constantValue.webPlatformType;
  typeList: Array<any> = [
    { label: '全额付款', value: 'All' },
    { label: '预付部分', value: 'part' },
    { label: '免单', value: 'free' }
  ];
  orderTypeMap: Array<any> = [
    {label: '个人', value: 0},
    {label: '团体', value: 1}
  ];
  validPhone = (rule: any, value: any, callback: any) => {
      const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      if (value !== '' && value !== null) {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的11位手机号码'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
  payMoney = (rule: any, value: any, callback: any) => {
    console.log(this.methodForm.payType)
      if (this.methodForm.payType !== 'free' && !value) {
        callback(new Error('请输入支付金额'));
      } else {
        callback()
      }
  };
  noPayReason=(rule: any, value: any, callback: any) => {
    console.log(this.methodForm.payType)
      if (this.methodForm.payType === 'free' && !value) {
        callback(new Error('免单理由不能为空！'));
      } else {
        callback()
      }
  };
  methodValid=(rule: any, value: any, callback: any) => {
      if (this.methodForm.payType !== 'free' && !value) {
        callback(new Error('支付方式不能为空！'));
      } else {
        callback()
      }
  };
  rules = {
        userName: [
          { required: true, message: '姓名不能为空！', trigger: 'blur' }
        ],
        phone: [
          {
            message: '请输入正确的11位手机号码！',
            trigger: 'blur',
            validator: this.validPhone
          },
          { required: true, message: '手机号不能为空！', trigger: 'blur' }
        ],
        method: [
          { validator: this.methodValid, message: '支付方式不能为空！', trigger: 'change' }
        ],
        receivedAmount: [
          { validator: this.payMoney, message: '支付金额不能为空！', trigger: 'blur' }
        ],
        payType: [
          { required: true, message: '付款类型不能为空！', trigger: 'change' }
        ],
        reason: [
          { validator: this.noPayReason, message: '免单理由不能为空！', trigger: 'blur' }
        ],
        orderMode: [
          { required: true, message: '订单类型不能为空！', trigger: 'change' }
        ]
      }
  
  // reservDataList: any = {};
  isShowConfirmDialog: boolean = false;
  currentDate: Date = new Date();
  nextDate: Date = new Date(this.currentDate.getTime() + 24 * 60 * 60 * 1000);
  afterDate: Date = new Date(this.nextDate.getTime() + 24 * 60 * 60 * 1000);
  // rentalTime: string = '';
  // useStartTime: string = '';
  // useEndTime: string = '';
  confirmData: object = {};
  // props
  @Prop(Boolean) isShowResDialog!: boolean;
  @Prop(Object) injectData: any;
  @Prop(String) rentalTime!: String;

  @Watch('methodForm.payType')
  getMethodForm(val: any) {
      if (val === 'free') {
        this.methodForm.receivedAmount = '0';
      }
  }

  // methods
  handleClose() {
    this.$emit('close');
  }

  handleInput(e: any) {
    // 通过正则过滤小数点后两位
    e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
  }

  saveAccount() {
    let el: any = this.$refs.orderForm;
    const checkFun = (valid: boolean) => {
      console.log('肯定看的肯定看的肯定快');
      console.log('true or false', valid);
      if (valid) {
        console.log('12321321321312321')
        this.isShowConfirmDialog = true;
        this.confirmData = {
          useStartTime: this.rentalTime + ' 00:00:00',
          useEndTime: this.rentalTime + ' 23:59:59',
          leaseIdList: this.leaseIdList,
          receivedAmount: this.methodForm.receivedAmount ? this.methodForm.receivedAmount : '0',
          platformType: this.methodForm.method,
          userTelephone: this.methodForm.phone,
          userName: this.methodForm.userName,
          remark: this.methodForm.remark,
          payType: this.methodForm.payType,
          orderMode: this.methodForm.orderMode,
          reason: this.methodForm.reason
        };
      }
    };
    el.validate(checkFun);
  }

  handleCloseTag(tag: any) {
    let tagList = this.injectData.selectedList;
    let index;
    if (tagList.length > 1) {
      for (let i in tagList) {
        if (tagList[i].id === tag.id) {
          index = i;
          tagList.splice(index, 1);
        }
      }
    }
  }

  refreshUmbrella() {
    this.$emit('successCallBack');
  }

  init() {
    // this.reservDataList = deepCopy(this.injectData);
    // this.rentalTime = this.formatTime(
    //   this.reservDataList.currentDate,
    //   'YYYY-MM-DD'
    // );
    // this.useStartTime = this.rentalTime + ' 00:00:00';
    // this.useEndTime = this.rentalTime + ' 23:59:59';
    // switch (this.reservDataList.activeDate) {
    //   case 0:
    //     this.rentalTime = this.formatTime(this.currentDate, 'YYYY-MM-DD')
    //     this.useStartTime = this.rentalTime + ' 00:00:00'
    //     this.useEndTime = this.rentalTime + ' 23:59:59'
    //     break;
    //   case 1:
    //     this.rentalTime = this.formatTime(this.nextDate, 'YYYY-MM-DD')
    //     this.useStartTime = this.rentalTime + ' 00:00:00'
    //     this.useEndTime = this.rentalTime + ' 23:59:59'
    //     break;
    //   case 2:
    //     this.rentalTime = this.formatTime(this.afterDate, 'YYYY-MM-DD')
    //     this.useStartTime = this.rentalTime + ' 00:00:00'
    //     this.useEndTime = this.rentalTime + ' 23:59:59'
    //     break;
    //   default:
    //     console.log(this.reservDataList.activeDate)
    // }
  }

  // computed
  // 获取总金额
  get totalPrice() {
    let total = 0;
    if (this.injectData.selectedList) {
      for (let i in this.injectData.selectedList) {
        total += parseFloat(this.injectData.selectedList[i].currentPrice);
      }
      return total.toFixed(2);
    }
  }

  // 伞位id列表
  get leaseIdList() {
    let arr = [];
    if (this.injectData.selectedList) {
      for (let i in this.injectData.selectedList) {
        arr.push(this.injectData.selectedList[i].id);
      }
      return arr;
    }
  }

  // 生命周期
  created() {
    // this.init();
  }
}
