<!--会员管理-->
<template>
  <div class="userManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="昵称：">
          <el-input placeholder="请输入昵称" v-model="formInline.nickName" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input placeholder="请输入手机号" v-model="formInline.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select placeholder="请选择类型" v-model="formInline.userType" clearable>
            <el-option v-for="(val,key) in typeListMap" :label="val" :value="key"
                       :key="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="openId：">
          <el-input placeholder="请输入openid" v-model="formInline.openId" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="userTab_box">
      <el-table
        v-loading="loading"
        class="c-table"
        :data="tableData"
        style="width: 100%">
        <el-table-column prop="ID" label="序号" width="50px">
          <template slot-scope="scope">
            <span>{{scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="昵称">
          <template slot-scope="scope">
            <span :title="scope.row.nickName">{{scope.row.nickName ? scope.row.nickName : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="headimgurl" label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.headimgurl" width="40px">
          </template>
        </el-table-column>
        <el-table-column prop="openId" label="openid" width="300px">
          <template slot-scope="scope">
            <span :title="scope.row.openId">{{scope.row.openId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subscribe" label="关注公众号" width="100px">
          <template slot-scope="scope">
            <span :title="scope.row.subscribe">{{scope.row.subscribe === '0' ? '否' : '是'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120px">
          <template slot-scope="scope">
            <span :title="scope.row.phone">{{scope.row.phone ? scope.row.phone : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userType" label="用户类型" width="100px">
          <template slot-scope="scope">
            <span :title="scope.row.userType">{{typeListMap[scope.row.userType]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="registTime" label="注册时间" width="160px">
          <template slot-scope="scope">
            <span :title="scope.row.registTime">{{scope.row.registTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" label="最近登录时间" width="160px">
          <template slot-scope="scope">
            <span :title="scope.row.lastLoginTime">{{scope.row.lastLoginTime}}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="lookDetail(scope.row.openId)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <yd-page :page-config="pageConfig" :size-change="handleSizeChange" :current-change="handleCurrentChange"></yd-page>
  </div>
</template>

<script>
  export default {
    name: 'userManage',
    data() {
      return {
        tableData: [],
        formInline: {
          nickName: '',
          phone: '',
          userType: '',
          openId: ''
        },
        typeListMap: {
          1: '普通用户',
          2: '会员用户',
          3: '贵宾用户'
        },
        pageConfig: {
          rows: 10
        },
        loading: false,
        oldSearchCondition: {}
      }
    },
    created() {
      this.getUserManageList();
    },
    methods: {
      // 搜索
      onQuery() {
        this.pageConfig.page = 1;
        this.oldSearchCondition = {...this.formInline}
        this.getUserManageList()
      },
      handleSizeChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.rows = val;
        this.getUserManageList()
      },
      handleCurrentChange(val) {
        this.pageConfig.page = val
        this.getUserManageList()
      },
      // 获取用户列表
      getUserManageList() {
        this.loading = true;
        console.log(JSON.stringify(this.pageConfig))
        this.formInline = {...this.oldSearchCondition}
        this.$axios({
          method: 'post',
          url: '/weixin/getWxUserList',
          data: {...this.formInline, ...this.pageConfig}
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig(res)
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      },
      // 重置
      onReset() {
        this.formInline.nickName = '';
        this.formInline.phone = '';
        this.formInline.userType = '';
        this.formInline.openId = '';
        this.pageConfig = {}
        this.oldSearchCondition = {}
        this.getUserManageList();
      },
      // 查看详情
      lookDetail(id) {
        this.$router.push({path: '/userDetail', query: {id: id}})
      }
    }
  }
</script>

<style scoped lang="scss">
  .userManage {
    .box-card {
      margin-bottom: 20px;
    }
    .el-card.is-always-shadow {
      box-shadow: none;
      .el-form-item {
        margin-bottom: 10px;
      }
    }
    .userTab_box {
      margin-bottom: 20px;
    }
  }
</style>
