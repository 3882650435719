<!--充值设置-->
<template>
  <div class="rechargeSet">
    <div class="layout-box" v-loading="loading">
      <div class="left-title" style="margin-top: 7px;">优惠设置：</div>
      <div class="right-content">
        <el-form :model="sendData" status-icon  ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <transition-group name="list" tag="div">
            <div v-for="(item,index) in sendData.settings" :key="index" class="add-group">
              <div class="group">
                <el-form-item label="单笔支付满（元）:" :ref="'moneyForm' + index" :prop="'settings.' + index  + '.totalMoney'" :rules="rules">
                  <el-input v-model="item.totalMoney" maxlength="5" clearable class="add-pay" v-enter-number-int placeholder="请输入金额"></el-input>
                </el-form-item>
              </div>
              <div class="group">
                <el-form-item label="优惠（元）:">
                  <el-input v-model="item.discountMoney" clearable class="add-pay" @input="checkData(index)" v-enter-number-int placeholder="请输入金额"></el-input>
                </el-form-item>
              </div>
              <div class="add-button" @click="addPay" v-show="sendData.settings.length - 1 === index && sendData.settings.length < 5">+</div>
              <div class="add-button" @click="reducePay(index)" v-show="sendData.settings.length > 1">-</div>
            </div>
          </transition-group>
        </el-form>
      </div>
    </div>
    <div class="layout-box">
      <div class="left-title">
        <div>充值协议：</div>
      </div>
      <div class="right-content">
        <yd-ue v-model ="sendData.agreeMent"></yd-ue>
        <el-button class="c-btn" @click="saveData">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>

  import {constantValue} from '../../plugins/constantValue';

  export default {
    name: 'rechargeSet',
    data() {
      const validatePass = (rule, value1, callback) => {
        const index = rule.field.split('.')[1]
        const item = this.sendData.settings[index]
        const value = item.totalMoney
        if (value > 0 && value < item.discountMoney) {
          callback(new Error('优惠金额应该小于单笔支付金额'))
        } else {
          callback()
        }
      }
      return {
        rules: {validator: validatePass, trigger: 'blur'},
        loading: false,
        sendData: {
          agreeMent: '',
          type: constantValue.protocolType.wallet,
          settings: [
            {
              totalMoney: '',
              discountMoney: ''
            }
          ]
        }
      }
    },
    methods: {
      checkData(index) {
        // this.$refs.ruleForm.resetFields()
        this.$refs.ruleForm.validateField('settings.' + index + '.totalMoney')
        // console.log(this.$refs['moneyForm' + index][0].validate())
      },
      reducePay(index) {
        this.sendData.settings.splice(index, 1)
        this.$nextTick(() => {
          this.$refs.ruleForm.validateField('settings.' + index + '.totalMoney')
        })
      },
      addPay() {
        this.sendData.settings.push({
          totalMoney: '',
          discountMoney: ''
        })

      },
      saveData() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.$axios({
              url: '/apps/addWalletSet',
              method: 'post',
              data: Object.assign(this.sendData, {type: constantValue.protocolType.wallet})
            }).then(res => {
              this.loading = false
              if (res.code === 200) {
                this.getRechargeSetDetail()
                this.$showAlert(res.message)
              } else {
                this.$showAlert(res.message, 'error')
              }
            }).catch(() => {
              this.loading = false
            })
          }
        })
      },
      getRechargeSetDetail() {
        this.$axios({
          url: '/apps/getSet',
          method: 'get',
          params: {type: constantValue.protocolType.wallet}
        }).then(res => {
          if (res.code === 200) {
            this.sendData = res.data
            if (this.sendData.settings && this.sendData.settings.length === 0) {
              this.sendData.settings.push({
                totalMoney: '',
                discountMoney: ''
              })
            }
          } else {
            this.$showAlert(res.message, 'error')
          }
        })
      }
    },
    created() {
      this.getRechargeSetDetail()
    }
  }
</script>

<style scoped lang="scss">
  .rechargeSet {
    .list-enter-active, .list-leave-active {
      transition: all 0.5s;
    }
    .list-enter, .list-leave-to
      /* .list-leave-active for below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(30px);
    }

    .layout-box{
      padding-top: 20px;
      display: flex;
      .left-title {
        font-size: 16px;
        flex: 0 0 150px;
        text-align: right;
        padding-right: 20px;
      }
      .right-content{
        flex: 1;
      }
    }
    font-size: 14px;
    padding-bottom:20px;
    padding-right:20px;
    .add-group {
      div.group {
        display: inline-block;
      }
      .group:nth-child(2) {
        margin-left: 50px;
      }
    }
    .add-pay {
      width: 200px;
    }
    .add-button {
      width: 30px;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 18px;
      display: inline-block;
      text-align: center;
      margin-left: 30px;
      border: 1px solid #eee;
      &:hover {
        border-color: #1ab394;
        color: #1ab394;
      }
    }
  }

</style>
