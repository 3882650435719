<!--手环租赁-->
<template>
  <div class="wristband">
    <el-card class="box-card">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        style="padding-right: 100px;"
      >
        <el-form-item label="订单编号">
          <el-input v-model="formData.orderCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.phone" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item class="btn-div">
          <el-button type="primary" class="c-btn" size="mini" @click="toBind">绑定</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="toReturn">退还</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="orderCode" label="订单编号">
          <template slot-scope="scope">
            <span>{{scope.row.orderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="用户手机号">
          <template slot-scope="scope">
            <span>{{scope.row.userTelephone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="braceletNum" label="数量">
          <template slot-scope="scope">
            <span>{{scope.row.braceletNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="金额">
          <template slot-scope="scope">
            <span>{{scope.row.totalPrice ? scope.row.totalPrice + '元' : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间">
          <template slot-scope="scope">
            <span>{{scope.row.payTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态">
          <template slot-scope="scope">
            <span>{{orderStatus[scope.row.orderStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="toDetail(scope.row.orderCode)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';
export default {
  name: 'Wristband',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      orderStatus: constantValue.orderStatus,
      tableData: []
    };
  },
  created() {
    this.getWristbandList();
  },
  methods: {
    getWristbandList() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/bracelet/getOrderDetailByCriteria',
        params: {...this.pageConfig, ...this.searchCondition}
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getWristbandList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getWristbandList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getWristbandList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getWristbandList();
    },
    toDetail(id) {
      this.$router.push({ path: '/wristbandDetail', query: { id } });
    },
    toBind() {
      this.$router.push({ path: '/wristbandBind' });
    },
    toReturn() {
      this.$router.push({ path: '/wristbandReturn' });
    }
  }
};
</script>

<style scoped lang="scss">
.wristband {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .box-card {
    margin-bottom: 20px;
  }
  .demo-form-inline {
    position: relative;
    .btn-div {
      position: absolute;
      right: 0;
    }
  }
}
</style>
