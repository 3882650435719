













































































































































import { Vue, Component } from 'vue-property-decorator';
import { constantValue } from '@/plugins/constantValue';

@Component({
  components: {}
})
export default class UmbrellaDetail extends Vue {
  // data
  activeName: string = 'first';
  umbrellaDetail: object = {};
  statusMap: object = constantValue.umbrellaStatusMap;
  orderSource: object = constantValue.orderSource;
  orderStatus: object = constantValue.orderStatus;
  loading: boolean = false;
  // methods
  getDetailData() {
    // this.loading = true
    let id = this.$route.query.id;
    let date =
      this.$route.query.date ||
      this.formatTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
    this.loading = true;
    this.$axios({
      method: 'get',
      url: '/umbrella/getInformation',
      params: { id: id, date: date }
    })
      .then((res: any) => {
        this.loading = false;
        if (res.code === 200) {
          this.umbrellaDetail = res.data;
          console.log('res', res);
        } else {
          // 请求出错
        }
      })
      .catch((e: any) => {
        this.loading = false;
        console.log(e);
      });
  }

  // computed
  get computedMsg() {
    return 'computed';
  }

  // 生命周期
  created() {
    this.getDetailData();
  }
}
