<!--套卡管理-->
<template>
  <div class="cardManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="formData" class="demo-form-inline" style="padding-right: 100px;">
        <el-form-item label="套卡卡号">
          <el-input v-model="formData.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="充值面额">
          <el-input v-model="formData.telephone" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-select v-model="formData.roleId" placeholder="请选择" clearable>
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in roleLists" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini">重置</el-button>
        </el-form-item>
        <el-form-item class="add-account">
          <el-button type="primary" class="c-btn" size="mini">导出样本</el-button>
          <el-button type="primary" class="c-btn" size="mini">导入</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table
        class="c-table"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column prop="name" label="套卡卡号">
          <template slot-scope="scope">
            <span :title="scope.row.cardNumber">{{scope.row.cardNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="套卡卡密">
          <template slot-scope="scope">
            <span :title="scope.row.username">{{scope.row.kammi}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="充值面额(元)">
          <template slot-scope="scope">
            <span :title="scope.row.telephone">{{scope.row.denomination}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="卡售价(元)">
          <template slot-scope="scope">
            <span :title="scope.row.createTime">{{scope.row.cardPrice}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="使用状态">
          <template slot-scope="scope">
            <span :title="scope.row.status">{{scope.row.status}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {},
        roleLists: [{
          name: '待审批',
          id: 1
        }, {
          name: '审批通过',
          id: 2
        }],
        tableData: [{
          cardNumber: '12345',
          kammi: 'A001',
          denomination: '1000',
          cardPrice: '80',
          status: '已使用'
        }, {
          cardNumber: '12345',
          kammi: 'A001',
          denomination: '1000',
          cardPrice: '80',
          status: '未使用'
        }]
      }
    },
    name: 'cardManage'
  }
</script>

<style scoped lang="scss">
.cardManage{
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}
</style>
