<!--角色管理-->
<template>
  <div class="roleManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchModel" class="demo-form-inline" style="padding-right: 100px;">
        <el-form-item label="年度">
          <el-input v-model="searchModel.year" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="reset">重置</el-button>
        </el-form-item> -->
        <el-form-item class="add-role">
          <el-button type="primary" class="c-btn" @click="handleAdd()">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="role-table" v-loading="loading">
      <el-table class="c-table" :data="tableData">
        <el-table-column type="index" width="200"></el-table-column>
        <el-table-column prop="year" label="关键词"></el-table-column>
        <el-table-column prop="beginDate" label="匹配类型"></el-table-column>
        <el-table-column prop="endDate" label="回复消息类型"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="handleDel(scope.row.id)">删除</el-button>
              <!-- <el-button type="text" size="small" @click="changeStatus(scope.row)"
                         :class="{'red': scope.row.priceFlag === false}">
                {{scope.row.priceFlag === true ? '启用' : '禁用'}}
              </el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="handleType == 'add' ? '新增回复消息' : '修改回复消息'" :visible.sync="dialog1Visible" width="50%">
        <el-form label-width="100px">
          <el-form-item label="请求消息类型" v-if="type == '2'">
            <el-select v-model="objData.reqType" placeholder="请选择">
              <el-option
                v-for="item in dictData.get('wx_req_type')"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
                v-if="item.value !== 'event'">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="匹配类型" v-if="type == '3'">
            <el-select v-model="objData.repMate" placeholder="请选择" style="width: 100px">
              <el-option
                v-for="item in dictData.get('wx_rep_mate')"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键词" v-if="type == '3'">
            <el-input placeholder="请输入内容" v-model="objData.reqKey" clearable> </el-input>
          </el-form-item>
          <el-form-item label="回复消息">
            <wxReply :objData="objData" v-if="hackResetWxReplySelect"></wxReply>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog1Visible = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmit">确 定</el-button>
        </span>
      </el-dialog>
    <yd-page :page-config="page" :size-change="handleSizeChange" :current-change="handleCurrentChange"></yd-page>
  </div>
</template>

<script>
    import  wxReply from '@/components/wx/wxReply'
  export default {
    name: 'WxReplySet',
    components: {
        wxReply
    },
    data() {
      return {
        tableData: [],
        page: {
          total: 0, // 总页数
          currentPage: 1, // 当前页数
          pageSize: 10, // 每页显示多少条
          ascs:[],//升序字段
          descs:[]//降序字段
        },
        paramsSearch:{},
        loading: false,
        // tableOption1:tableOption1,
        // tableOption2:tableOption2,
        // tableOption3: tableOption3,
        dialog1Visible:false,
        type:'3',//类型（1、关注时回复；2、消息回复；3、关键词回复）
        objData:{
          repType : 'text'
        },
        handleType: null,
        dictData: new Map(),
        hackResetWxReplySelect: false,
        searchModel:{},
      }
    },
    methods: {
     handleAdd(){
        this.hackResetWxReplySelect = false//销毁组件
        this.$nextTick(() => {
          this.hackResetWxReplySelect = true//重建组件
        })
        this.handleType = 'add'
        this.dialog1Visible = true
        this.objData = {
          repType : 'text'
        }
      },
      handleEdit(row){
        this.hackResetWxReplySelect = false//销毁组件
        this.$nextTick(() => {
          this.hackResetWxReplySelect = true//重建组件
        })
        this.handleType = 'edit'
        this.dialog1Visible = true
        this.objData = Object.assign({}, row)
      },
      handleClick(tab, event){
        this.tableData = []
        this.page.currentPage = 1
        this.type = tab.name
        this.getPage(this.page)
      },
      searchChange(params,done){
        params = this.filterForm(params)
        this.paramsSearch = params
        this.page.currentPage = 1
        this.getPage(this.page,params)
        done()
      },
      sortChange(val){
        let prop = val.prop ? val.prop.replace(/([A-Z])/g,"_$1").toLowerCase() : '';
        if(val.order=='ascending'){
          this.page.descs = []
          this.page.ascs = prop
        }else if(val.order=='descending'){
          this.page.ascs = []
          this.page.descs = prop
        }else{
          this.page.ascs = []
          this.page.descs = []
        }
        this.getPage(this.page)
      },
      getPage(page, params) {
        this.loading = false
        // getPage(Object.assign({
        //   current: page.currentPage,
        //   size: page.pageSize,
        //   descs: this.page.descs,
        //   ascs: this.page.ascs,
        //   type: this.type
        // }, params, this.paramsSearch)).then(response => {
        //   this.tableData = response.data.records
        //   this.page.total = response.data.total
        //   this.page.currentPage = page.currentPage
        //   this.page.pageSize = page.pageSize
        //   this.loading = false
        //   if(this.type == '2'){
        //     let wxReqType = this.dictData.get('wx_req_type')
        //     for (let i=0;i<wxReqType.length;i++) {
        //       wxReqType[i].disabled = false
        //       for(let j=0;j<this.tableData.length;j++){
        //         if(wxReqType[i].value == this.tableData[j].reqType){
        //           wxReqType[i].disabled = true
        //         }
        //       }
        //     }
        //   }
        // }).catch(() => {
        //   this.loading = false
        // })
      },
      handleDel: function(row, index) {
        var _this = this
        this.$confirm('是否确认删除此数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function() {
            // return delObj(row.id)
          }).then(data => {
          _this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
          this.getPage(this.page)
        }).catch(function(err) { })
      },
      handleSubmit(row){
        if(this.handleType == 'add'){
        //   addObj(Object.assign({
        //     type:this.type
        //   }, this.objData)).then(data => {
        //     this.$message({
        //       showClose: true,
        //       message: '添加成功',
        //       type: 'success'
        //     })
        //     this.getPage(this.page)
        //     this.dialog1Visible = false
        //   })
        }
        if(this.handleType == 'edit'){
        //   putObj(this.objData).then(data => {
        //     this.$message({
        //       showClose: true,
        //       message: '修改成功',
        //       type: 'success'
        //     })
        //     this.getPage(this.page)
        //     this.dialog1Visible = false
        //   })
        }
      },
      /**
       * @title 数据更新
       * @param row 为当前的数据
       * @param index 为当前更新数据的行数
       * @param done 为表单关闭函数
       *
       **/
      handleUpdate: function(row, index, done, loading) {
        putObj(row).then(data => {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          done()
          this.getPage(this.page)
        }).catch(() => {
          loading()
        })
      },
      /**
       * @title 数据添加
       *
       **/
      handleSave: function(row, done, loading) {
        // addObj(Object.assign({
        //   type:this.type
        // }, this.objData)).then(data => {
        //   this.$message({
        //     showClose: true,
        //     message: '添加成功',
        //     type: 'success'
        //   })
        //   this.getPage(this.page)
        //   this.dialog1Visible = false
        // }).catch(() => {
        //   done()
        // })
      },
      /**
       * 刷新回调
       */
      refreshChange(page) {
        this.objData={
            repType : 'text'
        }
        this.getPage(page)
    },
    handleSizeChange(val) {
        this.page.currentPage = 1;
        this.page.pageSize = val;
        this.getPage()
      },
      handleCurrentChange(val) {
        this.page.pageSize = val
        this.getPage()
      },
    },
    created() {
      this.getPage(this.page)
      this.dictData.set('wx_rep_mate',[{
        value: '1',
        label: '全匹配'
      },{
        value: '2',
        label: '半匹配'
      }])
      this.dictData.set('wx_req_type',[{
        value: 'text',
        label: '文本'
      },{
        value: 'image',
        label: '图片'
      },{
        value: 'voice',
        label: '语音'
      },{
        value: 'video',
        label: '视频'
      },{
        value: 'shortvideo',
        label: '小视频'
      },{
        value: 'location',
        label: '地理位置'
      },{
        value: 'link',
        label: '链接消息'
      },{
        value: 'event',
        label: '事件推送'
      }])
    }
  }
</script>

<style scoped lang="scss">
  .roleManage {
    .box-card {
      margin-bottom: 20px;
    }
    .demo-form-inline {
      position: relative;
      .add-role {
        position: absolute;
        right: 0;
      }
    }
    .c-table {
      .el-table__row {
        .operate {
          .el-button.red {
            color: red;
          }
        }
      }
    }
    .role-table {
      margin-bottom: 20px;
    }

    .el-card.is-always-shadow {
      box-shadow: none;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
</style>
