// 系统常量
export const constantValue = {
  orderStatus: {
    PRE_PAYMENT: '待支付',
    PRE_BIND: '待绑定',
    IN_USED: '使用中',
    PRE_USE: '交易完成',
    CONSULTATION_CANCEL: '协商取消',
    CANCEL: '交易取消',
    REFUNDED: '已退款',
    COMPLETE: '管理员确认',
    OVERDUE: '已逾期',
    FINISH_USED: '订单完成'
  },
  orderStatusSelect: {
    PRE_PAYMENT: '待支付',
    PRE_USE: '交易完成',
    CONSULTATION_CANCEL: '协商取消',
    CANCEL: '交易取消'
  },
  // 订单来源
  orderSource: {
    SUBSCRIPTION: '公众号预约',
    BACKSTAGE_ESERVATION: '后台预约'
  },
  // 支付方式
  platformType: {
    ALIPAY: '支付宝',
    WECHAT: '微信',
    WECHAT_SMALL: '微信小程序',
    CASH: '现金',
    WALLET: '在线钱包',
    PUBLIC_TRANSFER: '对公转账'
  },
  // 后台预约支付平台
  webPlatformType: {
    ALIPAY: '支付宝',
    WECHAT: '微信',
    CASH: '现金',
    PUBLIC_TRANSFER: '对公转帐'
  },
  // 支付状态
  payStatus: {
    PRE_PAYMENT: '待支付',
    ALREADY_PAYMENT: '已支付',
    REFUNDING: '退款中',
    REFUNDED: '已退款'
  },
  // 退款平台
  refundType: {
    ALIPAY: '支付宝',
    WECHAT: '微信',
    WECHAT_SMALL: '微信小程序',
    CASH: '现金',
    WALLET: '在线钱包',
    PUBLIC_TRANSFER: '对公转帐'
  },
  protocolType: {
    wallet: 1,
    umbrella: 2
  },
  typeConsumptionMap: {
    1: '普通充值',
    2: '套卡充值',
    3: '手环支付',
    4: '伞位预约',
    5: '房车租赁',
    6: '储物柜租赁',
    7: '伞位预约退款',
    8: '提取'
  },
  typeChanelMap: {
    1: '微信',
    2: '套卡',
    3: '钱包退款',
    4: '手环',
    5: '在线钱包'
  },
  umbrellaStatusMap: {
    1: '可预约',
    2: '已预约',
    3: '已使用',
    0: '不可预约'
  },
  umbrellaStatus: [
    {
      name: '可预约',
      status: 1,
      key: 'openNumber',
      color: '#82E7B0'
    },
    {
      name: '已预约',
      key: 'orderNumber',
      status: 2,
      color: '#67A5F8'
    },
    {
      name: '已使用',
      key: 'useNumber',
      status: 3,
      color: '#EE795A'
    },
    {
      name: '不可预约',
      key: 'lockNumber',
      status: 0,
      color: '#B4B4B4'
    }
  ],
  deviceStatus: {
    0: '未使用',
    1: '使用中',
    2: '已挂失',
    3: '未退还'
  },
  devicePhysicalStatus: {
    0: '启用',
    1: '禁用'
  }
};
