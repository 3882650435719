/**
 * 设置cookie
 * @param {string} key 设置的key
 * @param {string} value 设置的值
 * @return {any} 无
 */
export const setCookie = function (key:string, value:string) {
  if (typeof (value) === 'object' || Array.isArray(value)) {
    value = 'thw-' + JSON.stringify(value);
  }
  sessionStorage.setItem(key, value);
}

/**
 * 获取cookie
 * @param {string} key 需要获取的key
 * @return {string | null} 获取到的值
 */
export const getCookie = function(key:string):string | null {
  const lsVal: string | null = sessionStorage.getItem(key);
  if (lsVal && lsVal.indexOf('thw-') === 0) {
    return JSON.parse(lsVal.split('thw-')[1]);
  } else {
    return lsVal;
  }
}

/**
 * 深拷贝
 * @param {any} source 需要拷贝的对象
 * @return {any} 拷贝后的对象
 */
export const deepCopy = function(source:any) {
  if (!source) {
    return source;
  }
  let sourceCopy = source instanceof Array ? [] : {};
  for (let item in source) {
    // @ts-ignore
    sourceCopy[item] = typeof source[item] === 'object' ? deepCopy(source[item]) : source[item];
  }
  return sourceCopy;
};

/**
 * 获取服务器基础地址
 * @return {string} 服务器地址
 */
export const baseServiceUrl = function() {
  if (process.env.NODE_ENV !== 'production') {
   return process.env.VUE_APP_BASE_PATH + ':' + process.env.VUE_APP_BASE_PORT
  } else {
  return process.env.VUE_APP_BASE_PATH + ':' + process.env.VUE_APP_BASE_PORT
  }
}
