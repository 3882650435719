<!--登录页面-->
<template>
  <div class="login-page">
    <div class="login-main">
      <div class="login-logo"></div>
      <div class="login-content">
        <div class="text-error" v-if="errorMsg">{{errorMsg}}</div>
        <el-form :model="submitModel" status-icon ref="ruleForm" class="demo-ruleForm">
          <el-form-item class="username">
            <div class="el-input">
              <input type="text" autocomplete="off" placeholder="用户名" @keyup.enter="submitForm" @focus="errorMsg = ''" v-model="submitModel.username" class="el-input__inner">
            </div>
            <div class="flag"></div>
            <i class="icon-username"></i>
          </el-form-item>
          <el-form-item class="password">
            <div class="el-input">
              <input type="password" autocomplete="off" placeholder="密码" class="el-input__inner" @focus="errorMsg = ''"  @keyup.enter="submitForm" v-model="submitModel.password">
            </div>
            <div class="flag"></div>
            <i class="icon-password"></i>
          </el-form-item>
          <el-button class="btn login-submit" type="primary" :class="{'is-disabled': isDisabled || isLoginIng}" @click="submitForm" :disabled="isDisabled || isLoginIng">
            {{loginText}}
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {setCookie} from '../plugins/util';

export default {
  name: 'Login',
  data() {
    return {
      submitModel: {},
      isLoginIng: false,
      loginText: '登录',
      errorMsg: ''
    };
  },
  inject: ['setMenuPermission'],
  methods: {
    // 登录
    submitForm() {
      if (!this.submitModel.username || !this.submitModel.password) {
        this.errorMsg = '用户名或密码不可为空！'
        return;
      }
      this.errorMsg = ''
      this.loginText = '登录中'
      this.isLoginIng = true
      this.$axios({
        method: 'post',
        url: '/login',
        data: this.submitModel
      }).then(res => {
        this.isLoginIng = false
        if (res.token) {
          // 将token存到缓存里面
          setCookie('token', res.token)
          setCookie('username', this.submitModel.username)
          // 跳转页面
          this.setMenuPermission()
        } else {
          // 请求出错
          this.loginText = '登录'
          this.errorMsg = res.message
        }
      }).catch(e => {
        this.isLoginIng = false
        this.loginText = '登录'
        this.errorMsg = e.message
      })
    }
  },
  computed: {
    isDisabled() {
      return !(this.submitModel.username && this.submitModel.password)
    }
  },
  mounted() {
    setCookie('path', '/login');
  }
};
</script>

<style lang="scss">
  .login-page {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: url(../assets/img/login/bg.png);
    background-size: 100% 100%;
    .login-main {
      width:424px;
      height:465px;
      background:rgba(54,64,103,0.7);
      box-shadow:0px 8px 28px 0px rgba(0, 0, 0, 0.06), 0px 0px 2px 0px rgba(81,212,249,0.5);
      border-radius:4px;
      .login-logo{
        width: 164px;
        height: 49px;
        background: url(../assets/img/login/logo1.png);
        background-size: 100% 100%;
        margin-left: 120px;
        margin-top: 54px;
      }
      .login-content {
        position: relative;
        margin-top: 74px;
        .text-error {
          font-size: 14px;
          top: -24px;
          left: 47px;
          text-align: left;
          position: absolute;
          color: #F56C6C;
          margin: 0 auto;
        }
        .el-form {
          width: 330px;
          margin: 0 auto;
          .flag{
            position: absolute;
            width:1px;
            height:16px;
            background:rgba(228,228,228,1);
          }
          .username{
            position: relative;
            .flag{
              top: 12px;
              left: 41px;
            }
            .icon-username{
              position: absolute;
              left: 14px;
              top: 13px;
              background-image: url("../assets/img/login/account.png");
              background-size: contain;
              width: 14px;
              height: 16px;
              display: inline-block;
            }
          }
          .password{
            .flag{
              top: 12px;
              left: 41px;
            }
            position: relative;
            .icon-password{
              position: absolute;
              left: 14px;
              top: 13px;
              width: 14px;
              height: 16px;
              display: inline-block;
              background-image: url("../assets/img/login/icon_password.png");
              background-size: contain;
            }
          }
          .el-input {
            input {
              padding-left: 55px;
            }
          }
        }

        .login-submit {
            width: 100%;
            height: 40px;
            margin-top:25px;
            color:#FEFEFFFF;
            border: none;
            font-size: 18px;
            background: #1AB394FF;
            border-radius:4px;
            &.is-disabled{
              background-color: #82848a;
            }
        }
      }
    }
  }
</style>
