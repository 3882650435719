import Vue from 'vue';
import JsonExcel from 'vue-json-excel';
import './plugins/axios';
import App from './App.vue';
import router from './router/router';
import store from './store';
import './registerServiceWorker';
import './plugins/element.js';
import './plugins/common'
import './plugins/directive'
import './plugins/filters'
// 公用样式
import './assets/css/button.scss'
import './assets/icomoon/style.css'
import './assets/css/dialog.scss'
import './assets/css/form.scss'
import './assets/css/mixin.scss'
import './assets/css/table.scss'
import './assets/css/animate.scss'
import './assets/css/common.scss'
// 全局组件
import ydPage from './components/common/ydPage.vue'
import ue from './components/common/ue.vue'

//金额格式化
import numFormat from '@/plugins/numFormat';


Vue.component('yd-page', ydPage)
Vue.component('yd-ue', ue)
Vue.component('downloadExcel', JsonExcel)

Vue.filter('numFormat',numFormat)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
