/* eslint-disable standard/object-curly-even-spacing */
/* eslint-disable standard/object-curly-even-spacing */
<!--订单分析-->
<template>
  <div class="orderAnalysis">
    <div class="bar">
      <div class="line"></div>
      <div class="title">关键指标</div>
      <el-select
        v-model="key.date"
        @change="indexTypeChange"
        placeholder="请选择"
        style="width: 150px;margin-right: 15px;"
        size="mini"
      >
        <el-option
          v-for="item in keyDateList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="key-div" v-loading="keyLoading">
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_list.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">{{indexData.totalNum}}</div>
        <div class="key-title">订单总数</div>
        <div class="content">环比<img v-if="indexData.numPercent != null" :src="getImgSrc(indexData.numPercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.numPercent == null ? ' -' : (indexData.numPercent >= 0 ? (indexData.numPercent+'%') : ((-indexData.numPercent)+'%'))}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_money.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">￥{{indexData.totalAmount}}</div>
        <div class="key-title">订单总额</div>
        <div class="content">环比<img v-if="indexData.amountPercent != null" :src="getImgSrc(indexData.amountPercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.amountPercent == null ? ' -' : (indexData.amountPercent >= 0 ? (indexData.amountPercent+'%') : ((-indexData.amountPercent)+'%'))}}</div>
      </div>
    </div>
    <div class="bar">
      <div class="line"></div>
      <div class="title">订单来源</div>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="ringDate.begin"
        @change="ringBeginDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="ringDate.end"
        @change="ringEndDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </div>
    <VeRing 
        :data="ringNumData"
        :settings="ringNumSettings"
        :extend="ringExtends"
        height="350px"
        width="620px"
        style="float:left;"
        v-loading="ringLoading"
      ></VeRing>
      <VeRing 
        :data="ringAmountData"
        :settings="ringAmountSettings"
        :extend="ringExtends"
        height="350px"
        width="620px"
        style="float:left;"
        v-loading="ringLoading"
      ></VeRing>
    <div class="bar">
      <div class="line"></div>
      <div class="title">订单趋势</div>
      <el-select
        v-model="line.type"
        placeholder="请选择"
        style="width: 180px;margin-right: 10px;"
        @change="lineTypeChange"
        size="mini"
      >
        <el-option
          v-for="item in sourceList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="lineDate.begin"
        align="right"
        type="date"
        @change="lineBeginDateChange"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="lineDate.end"
        align="right"
        type="date"
        @change="lineEndDateChange"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </div>
    <VeLine
      :data="lineData"
      :settings="lineSettings"
      :legend-visible="false"
      height="300px"
      v-loading="lineLoading"
    ></VeLine>
    <div class="bar">
      <div class="line"></div>
      <div class="title">详情</div>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 10px;"
        @change="tableBeginDateChange"
        v-model="tableDate.begin"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 10px;"
        @change="tableEndDateChange"
        v-model="tableDate.end"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
      <el-button type="primary" size="mini" style="margin-right: 15px;" @click="downloadExcel">下载表格</el-button>
    </div>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="tableLoading">
        <el-table-column prop="orderCode" label="时间">
          <template slot-scope="scope">
            <span>{{subDate(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="充值">
          <template slot-scope="scope">
            <span>{{scope.row.rechargeNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="伞位预约">
          <template slot-scope="scope">
            <span>{{scope.row.umbrellaNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="储物柜租赁">
          <template slot-scope="scope">
            <span>{{scope.row.lockerNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grid" label="餐饮消费">
          <template slot-scope="scope">
            <span>{{scope.row.foodNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="手环租赁">
          <template slot-scope="scope">
            <span>{{scope.row.braceletNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="景区购票">
          <template slot-scope="scope">
            <span>{{scope.row.ticketNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="停车缴费">
          <template slot-scope="scope">
            <span>{{scope.row.parkingNum}}</span>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common';
import VeRing from 'v-charts/lib/ring.common';
import upIconUrl from '@/assets/img/icon_up.png';
import downIconUrl from '@/assets/img/icon_down.png';
export default {
  name: 'OrderAnalysis',
  data() {
    return {
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      indexData: {},
      pickerOptions: {
        disabledDate: (time) => 
          // 不能选择今天之后的天
           time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime(),        
        shortcuts: [
          {
            text: '最近7天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近15天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      pickerOptions1: {
        disabledDate: (time) => 
          // 不能选择今天之后的天
           time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime()
        
      },
      key: { date: 0 },
      line: { type: 0 },
      keyDateList: [
        { label: '今日', value: 0 },
        { label: '本周', value: 1 },
        { label: '本月', value: 2 },
        { label: '本年', value: 3 }
      ],
      sourceList: [
        { label: '全部来源', value: 0 },
        { label: '充值', value: 1 },
        { label: '伞位预约', value: 2 },
        { label: '储物柜租赁', value: 3 },
        { label: '餐饮消费', value: 4 },
        { label: '手环租赁', value: 5 },
        { label: '景区购票', value: 6 },
        { label: '停车缴费', value: 7 }
      ],
      ringLoading: false,
      keyLoading: false,
      tableLoading: false,
      lineLoading: false,
      tableData: [],
      lineData: {
        columns: ['time', 'amount'],
        rows: [
          { time: '01/01', amount: 10 },
          { time: '01/03', amount: 20 },
          { time: '01/05', amount: 30 },
          { time: '01/07', amount: 25 },
          { time: '01/09', amount: 15 },
          { time: '01/11', amount: 5 }
        ]
      },
      lineSettings: {
        metrics: ['amount'],
        dimension: ['time'],
        labelMap: { amount: '金额' }
      },
      ringExtends: {
        legend: { 
          show: true,
          // 图例组件离容器下侧的距离
          bottom: '10%',
          // 图例每项之间的间隔
          itemGap: 15,
          // 图例标记的图形宽度
          itemWidth: 15,
          // 图例标记的图形高度
          itemHeight: 15,
          // 圆角半径，单位px
          borderRadius: 0
        },  
	      series: { center: ['50%', '40%'] }
      },
      ringNumSettings: {
        dimension: 'name',
        metrics: 'count',
        // 是否开启 hover 在扇区上的放大动画效果
        hoverAnimation: true,
        // 环图内外半径
        radius: ['88px', '100px'],
         // 最小角度
        minShowLabelAngle: 10,
        // 视觉引导线
        labelLine: {
          // 引导线是否显示
          show: true,
          length: 0,
          length2: 80,
          lineStyle: {
            // color: '#666666',
            width: 1.5
          }
        },
        label: {
            show: true,
            fontSize: 13
        },
        limitShowNum: 3
      },
      ringAmountSettings: {
        dimension: 'name',
        metrics: 'amount',
        // 是否开启 hover 在扇区上的放大动画效果
        hoverAnimation: true,
        // 环图内外半径
        radius: ['88px', '100px'],
        // 最小角度
        minShowLabelAngle: 10,
        // 视觉引导线
        labelLine: {
          // 引导线是否显示
          show: true,
          length: 0,
          length2: 80,
          lineStyle: {
            // color: '#666666',
            width: 1.5
          }
        },
        label: {
            show: true,
            fontSize: 13
        },
        limitShowNum: 3
      },
      ringNumData: {
        columns: ['name', 'count'],
        rows: [
          { name: '充值', count: 0 },
          { name: '伞位预约', count: 0 },
          { name: '储物柜租赁', count: 0 },
          { name: '餐饮消费', count: 0 },
          { name: '手环租赁', count: 0 },
          { name: '景区购票', count: 0 },
          { name: '停车缴费', count: 0 }
        ]
      },
      ringAmountData: {
        columns: ['name', 'amount'],
        rows: [
          { name: '充值', amount: 1 },
          { name: '伞位预约', amount: 2 },
          { name: '储物柜租赁', amount: 3 },
          { name: '餐饮消费', amount: 4 },
          { name: '手环租赁', amount: 5 },
          { name: '景区购票', amount: 6 },
          { name: '停车缴费', amount: 7 }
        ]
      },
      ringDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      lineDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      tableDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      orderData: {

      }
    };
  },
  created() {
    this.getOrderIndex();
    this.getRingData();
    this.getTableData();
    this.getLineData();
  },
  mounted() {
    this.keyLoading = true;
    this.tableLoading = true;
    this.lineLoading = true;
    this.ringLoading = true;
  },
  components: {
    VeLine,
    VeRing
  },
  methods: {
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      // TODO:获取表格数据
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      // TODO:获取表格数据
    },
    parseDate(date) { 
      var year = date.getFullYear(); 
      var month = (date.getMonth() + 1).toString(); 
      var day = (date.getDate()).toString();  
      if (month.length === 1) { 
          month = '0' + month; 
      } 
      if (day.length === 1) { 
          day = '0' + day; 
      }
      var dateTime = year + '-' + month + '-' + day;
      return dateTime; 
    },
    subDate(date) {
      var index = date.indexOf(' ');
      return date.substring(0, index);
    },
    getImgSrc(result) {
      if (result) {
        return upIconUrl;
      } else {
        return downIconUrl;
      }
    },
    getOrderIndex() {
      this.$axios({
        method: 'get',
        url: '/analysis/order/index',
        params: {
          type: this.key.date
        }
      }).then(res => {
        if (res.code === 200) {
          this.indexData = res.data;
          this.keyLoading = false;
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getRingData() {
      if (this.ringDate.end.getTime() >= this.ringDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/order/ring',
          params: {
            begin: this.ringDate.begin,
            end: this.ringDate.end
          }
        }).then(res => {
          if (res.code === 200) {
            this.ringNumData.rows = res.data.numList;
            this.ringAmountData.rows = res.data.amountList;
            this.ringLoading = false;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.ringLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    getLineData() {
      if (this.lineDate.end.getTime() >= this.lineDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/order/line',
          params: {
            begin: this.lineDate.begin,
            end: this.lineDate.end,
            type: this.line.type
          }
        }).then(res => {
          if (res.code === 200) {
            this.lineData.rows = res.data;
            this.lineLoading = false;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.lineLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    getTableData() {
      if (this.tableDate.end.getTime() >= this.tableDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/order/table',
          params: {
            begin: this.tableDate.begin,
            end: this.tableDate.end
          }
        }).then(res => {
          if (res.code === 200) {
            this.tableData = res.data;
            this.tableLoading = false;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.tableLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    indexTypeChange(val) {
      this.keyLoading = true;
      this.getOrderIndex();
    },
    ringBeginDateChange(val) {
      this.ringDate.begin = new Date(this.ringDate.begin.getTime() + 8 * 3600 * 1000);
      this.ringLoading = true;
      this.getRingData();
    },
    ringEndDateChange(val) {
      this.ringDate.end = new Date(this.ringDate.end.getTime() + 8 * 3600 * 1000);
      this.ringLoading = true;
      this.getRingData();
    },
    lineTypeChange(val) {
      this.lineLoading = true;
      this.getLineData();
    },
    lineBeginDateChange(val) {
      this.lineDate.begin = new Date(this.lineDate.begin.getTime() + 8 * 3600 * 1000);
      this.lineLoading = true;
      this.getLineData();
    },
    lineEndDateChange(val) {
      this.lineDate.end = new Date(this.lineDate.end.getTime() + 8 * 3600 * 1000);
      this.lineLoading = true;
      this.getLineData();
    },
    tableBeginDateChange(val) {
      this.tableDate.begin = new Date(this.tableDate.begin.getTime() + 8 * 3600 * 1000);
      this.tableLoading = true;
      this.getTableData();
    },
    tableEndDateChange(val) {
      this.tableDate.end = new Date(this.tableDate.end.getTime() + 8 * 3600 * 1000);
      this.tableLoading = true;
      this.getTableData();
    },
    downloadExcel() {
      if (this.tableDate.end.getTime() >= this.tableDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/order/excel',
          params: {
            begin: this.tableDate.begin,
            end: this.tableDate.end
          },
          responseType: 'blob'
        }).then(res => {
          this.download(res);
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      }
    },
    download(data) {
       if (!data) {
           return
       }
       var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
       var url = window.URL.createObjectURL(blob);
       var aLink = document.createElement('a');
       aLink.style.display = 'none';
       aLink.href = url;
       var fileName = '订单分析表.xls';
       aLink.setAttribute('download', fileName);
       document.body.appendChild(aLink);
       aLink.click();
       document.body.removeChild(aLink); // 下载完成移除元素
       window.URL.revokeObjectURL(url); // 释放掉blob对象
    }
  }
};
</script>

<style scoped lang="scss">
.orderAnalysis {
  .bar {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(245, 244, 247);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .line {
      width: 5px;
      height: 20px;
      background-color: #1ab394;
      margin-right: 10px;
    }

    .title {
      flex: 1;
    }
  }
 

  .key-div {
    border: 1px solid rgb(196, 193, 193);
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 15px;
      margin-bottom: 15px;

      .key-title {
        font-size: 16px;
        margin-bottom: 5px;
      }

      .count {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 18px;
      }

      .content {
        font-size: 14px;
      }
    }

    .key-line {
      width: 1px;
      height: 100px;
      background-color: rgb(196, 193, 193);
    }
  }
}
</style>
