<!--手环订单详情-->
<template>
  <div class="WristbandDetail order-common" v-loading="loading">
    <div class="title-box">
      <div class="icon"></div>
      <div class="title">订单编号：{{orderDetailObj.orderCode}}</div>
      <div
        class="operate"
      >{{orderDetailObj.orderStatus ? orderStatus[orderDetailObj.orderStatus] : '-'}}</div>
    </div>
    <div class="main-box">
      <div class="head">订单信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">订单来源：</div>
          <div
            class="value"
          >{{orderDetailObj.orderSource ? orderSource[orderDetailObj.orderSource] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单编号：</div>
          <div class="value">{{orderDetailObj.orderCode ? orderDetailObj.orderCode : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单金额：</div>
          <div
            class="value"
          >{{orderDetailObj.totalPrice ? ('￥' + toMoney(orderDetailObj.totalPrice)) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="value">{{orderDetailObj.createTime ? orderDetailObj.createTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付状态：</div>
          <div
            class="value"
          >{{orderDetailObj.payStatus ? payStatus[orderDetailObj.payStatus] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付金额：</div>
          <div
            class="value"
          >{{orderDetailObj.actualAmount !== null ? ('￥' + toMoney(orderDetailObj.actualAmount)) : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付时间：</div>
          <div class="value">{{orderDetailObj.payTime ? orderDetailObj.payTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">支付方式：</div>
          <div
            class="value"
          >{{orderDetailObj.platformType ? platformType[orderDetailObj.platformType] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">流水号：</div>
          <div class="value">{{orderDetailObj.transactionNo ? orderDetailObj.transactionNo : '-'}}</div>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="head">租赁信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">会员姓名：</div>
          <div class="value">{{orderDetailObj.userName ? orderDetailObj.userName : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">会员手机号：</div>
          <div class="value">{{orderDetailObj.userTelephone ? orderDetailObj.userTelephone : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">使用时间：</div>
          <div
            class="value"
          >{{orderDetailObj.useStartTime ? formatTime(orderDetailObj.useStartTime) : '-'}}</div>
        </div>
        <div class="item" style="padding-bottom: 30px;">
          <div class="label">商品信息：</div>
          <div class="value umbre-value">
            <div v-for="(item,index) in orderDetailObj.braceletOrderItemList" :key="index">
              <span>{{item.braceletLeaseName ? item.braceletLeaseName : '-'}}</span>
              <span>{{item.braceletLeaseStatus ? deviceStatus[item.braceletLeaseStatus] : '-'}}</span>
              <span>{{item.braceletTotalPrice ? (item.braceletTotalPrice + '元') : '-'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';

export default {
  name: 'WristbandDetail',
  data() {
    return {
      loading: false,
      orderDetailObj: {},
      payStatus: constantValue.payStatus,
      orderSource: constantValue.orderSource,
      platformType: constantValue.platformType,
      orderStatus: constantValue.orderStatus,
      deviceStatus: { 1: '未使用', 2: '已使用', 3: '已退款', 4: '已逾期' }
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      this.loading = true;
      let id = this.$route.query.id;
      this.$axios({
        method: 'get',
        url: '/bracelet/getOrderInfoByOrderCode',
        params: { orderCode: id }
      })
        .then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.orderDetailObj = res.data ? res.data : {};
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";

.WristbandDetail {
  width: 800px;
  margin: 0 auto;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
