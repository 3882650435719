import Vue from 'vue'

// 只能输入2位数字
Vue.directive('enterNumber', {
  inserted: function (el) {
    let ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    ele.oninput = function() {
      let val = ele.value;
      val = val.replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
      val = val.replace(/^\./g, ''); // 验证第一个字符是数字
      val = val.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
      ele.value = val;
    }
  }
});
// 只能输入整数
Vue.directive('enterNumberInt', {
  inserted: function (el) {
    let ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    ele.oninput = function() {
      let val = ele.value;
      // 第一位不可以位0
      if (val === '0') {
        val = ''
      }
      val = val.replace(/[^\d]/g, '');
      ele.value = val;
    }
  }
});
// 英文和数字的组合
Vue.directive('enterNumberAndString', {
  inserted: function (el) {
    let ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    ele.oninput = function() {
      let val = ele.value;
      console.log(val, 'bf')
      val = val.replace(/[^0-9a-zA-Z]+$/g, '');
      console.log(val, 'af')
      ele.value = val;
    }
  }
});
