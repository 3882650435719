<!--通用规范样式-->
<template>
  <div class="standardPage">
    <div class="box c-button">
      <h5>按钮部分</h5>
      <div>
        <el-button type="primary" class="c-btn">默认按钮</el-button>
        <el-button type="primary" class="c-btn" :disabled="true">不可点击</el-button>
        <el-button class="c-btn">默认按钮</el-button>
        <el-button class="c-btn" :disabled="true">不可点击</el-button>
      </div>
      <div>
        <el-button type="danger" class="c-btn">危险按钮</el-button>
        <el-button type="danger" class="c-btn" :disabled="true">危险按钮</el-button>
        <el-button type="danger" class="c-btn" plain>危险按钮</el-button>
        <el-button type="danger" class="c-btn" plain :disabled="true">危险按钮</el-button>
      </div>
      <div>
        <el-button type="warning" class="c-btn">警告按钮</el-button>
        <el-button type="warning" class="c-btn" :disabled="true">警告按钮</el-button>
        <el-button type="warning" class="c-btn" plain>警告按钮</el-button>
        <el-button type="warning" class="c-btn" plain :disabled="true">警告按钮</el-button>
      </div>
      <div>
        <el-button type="info" class="c-btn">信息按钮</el-button>
        <el-button type="info" class="c-btn" :disabled="true">信息按钮</el-button>
        <el-button type="info" class="c-btn" plain>信息按钮</el-button>
        <el-button type="info" class="c-btn" plain :disabled="true">信息按钮</el-button>
      </div>
      <div>
        <el-button type="info" class="c-btn lager">大型按钮</el-button>
        <el-button type="warning" class="c-btn small">小型按钮</el-button>
        <el-button type="danger" class="c-btn mini">超小按钮</el-button>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-edit" class="c-btn c-icon"></el-button>
        <el-button icon="el-icon-edit" class="c-btn c-icon" plain></el-button>
        <el-button type="primary" icon="el-icon-share" class="c-btn c-icon"></el-button>
        <el-button type="primary" icon="el-icon-delete" class="c-btn c-icon"></el-button>
        <el-button type="primary" icon="el-icon-search" class="c-btn">搜索</el-button>
        <el-button type="primary" class="c-btn">上传<i class="el-icon-upload el-icon--right"></i></el-button>
      </div>
      <div>
        <el-button type="primary" class="c-btn btn-block">块级按钮</el-button>
      </div>
      <div>
        <el-button class="c-btn btn-block btn-dash">块级按钮 虚线边框按钮</el-button>
      </div>
    </div>
    <div class="box form">
      <h5>form表单</h5>
      <div>
        <el-input placeholder="请输入内容" v-model="input3" class="c-form" clearable>
          <template slot="prepend">Http://</template>
        </el-input>
      </div>
      <div>
        <el-input placeholder="请输入内容" v-model="input4" class="c-form" clearable>
          <template slot="append">.com</template>
        </el-input>
      </div>
      <div>
        <el-input placeholder="请输入内容" v-model="input5" class="c-form" clearable>
          <el-select v-model="select" style="width: 130px;" slot="prepend" placeholder="请选择">
            <el-option label="餐厅名" value="1"></el-option>
            <el-option label="订单号" value="2"></el-option>
            <el-option label="用户电话" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div>
        <el-input
          placeholder="请输入内容"
          v-model="input5"
          class="c-form"
          clearable>
        </el-input>
      </div>
    </div>
    <div class="box">
      <h5>表格</h5>
      <el-table
        :data="tableData"
        style="width: 100%"
        class="c-table">
        <el-table-column
          prop="date"
          label="日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="address"
          label="地址">
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width='100px'>
          <template>
            <el-dropdown>
              <span class="el-dropdown-link group-btn-title"></span>
              <el-dropdown-menu slot="dropdown" class="group-btn">
                <el-dropdown-item>
                  <div>查看详情</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div>编辑</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div>删除</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="box">
      <h5>对话框</h5>
      <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button>
      <el-dialog
        class="c-dialog"
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div class="content">
          <span>这是一段信息</span>
        </div>
        <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  const app = {
    name: 'standardPage',
    data() {
      return {
        input3: '',
        input4: '',
        input5: '',
        select: '',
        tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],
        dialogVisible: false
      }
    },
    methods: {
      handleClose(done) {
        this.dialogVisible = false
      }
    },
    computed: {},
    created: function () {
    }
  }
  export default app
</script>

<style lang="scss">
  .standardPage {
    width: 1200px;
    margin: 0 auto;

    .box {
      background-color: white;
      padding: 20px;
      margin-bottom: 30px;

      h5 {
        margin-bottom: 20px;
      }

      &.c-button {
        div {
          margin-bottom: 10px;
        }
      }

      &.form {
        > div {
          margin-bottom: 15px;
        }
      }
    }
  }
</style>
