/* eslint-disable eqeqeq */
<!--手环描述-->
<template>
  <div class="WristbandDesc" v-loading="loading">
    <div class="div-input">
      <span class="label">商品标题:</span>
      <el-input class="input-value" v-model="des.title" placeholder="请输入" clearable></el-input>
    </div>
    <div class="div-input">
      <span class="label">收费定价:</span>
      <el-input
        class="input-value"
        v-model="des.braceletPrice"
        placeholder="请输入"
        clearable
        type="number"
        v-enter-number
      ></el-input>
    </div>
    <div class="div-logo">
      <span class="label">类型LOG:</span>
      <el-upload
        list-type="picture-card"
        :headers="headers"
        :action="imgUrl"
        :show-file-list="false"
        name="uploadFile"
        :on-success="handleUploadSuccess"
      >
        <img v-if="des.braceletLogo && des.braceletLogo !== ''" :src="des.braceletLogo" class="pic">
        <i v-else class="el-icon-plus pic"></i>
      </el-upload>
    </div>
    <div class="div-logo">
      <span class="label">展示详情:</span>
      <yd-ue v-model="des.braceletDetails" class="introduce"></yd-ue>
    </div>
    <el-button class="c-btn" @click="onSave()">保存</el-button>
  </div>
</template>

<script>
import { getCookie, baseServiceUrl } from '@/plugins/util';
export default {
  name: 'WristbandDesc',
  data() {
    return {
      loading: false,
      headers: {
        token: getCookie('token')
      },
      des: {
        title: '',
        braceletPrice: '',
        braceletLogo: '',
        braceletDetails: ''
      },
      imgUrl: baseServiceUrl() + '/image/upload'
    };
  },
  created() {
    this.getWristbandDesc();
  },
  methods: {
    getWristbandDesc() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/bracelet/getBraceletDes'
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.des = res.data;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleUploadSuccess(res, file) {
      this.des.braceletLogo = res.data[file.name];
    },
    onSave() {
      if (this.des.title === '') {
        this.$message.warning('商品标题不能为空!');
        return;
      }
      if (this.des.braceletPrice === '') {
        this.$message.warning('收费定价不能为空!');
        return;
      }
      if (this.des.braceletLogo === '') {
        this.$message.warning('类型LOG不能为空!');
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/editBraceletDes',
        data: this.des
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.WristbandDesc {
  padding-left: 30px;
  padding-bottom: 20px;

  .div-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .input-value {
      width: 300px;
    }
  }
  .label {
    margin-right: 30px;
  }

  .div-logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .introduce {
      width: 70%;
    }

    .pic {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
