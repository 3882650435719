<!--退款规则-->
<template>
  <div class="RefundRule">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="规则名">
          <el-input v-model="formData.type" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-div">
          <el-button type="primary" class="c-btn" size="mini" @click="showAddModal({})">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="ruleName" label="规则名">
          <template slot-scope="scope">
            <span>{{scope.row.ruleName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ruleTime" label="界定时间">
          <template slot-scope="scope">
            <span>{{scope.row.ruleTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv1Day" label="一段退款时长(时）">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv1Day}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv1Percent" label="一段退款费比">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv1Percent}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv2Day" label="二段退款时长(时）">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv2Day}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv2Percent" label="二段退款费比">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv2Percent}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv3Day" label="三段退款时长(时）">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv3Day}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rulelv3Percent" label="三段退款费比">
          <template slot-scope="scope">
            <span>{{scope.row.rulelv3Percent}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showAddModal(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="showDeleteModal(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>确认删除退款规则</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      :title="currentData.id != null ? '编辑退款规则' : '新增退款规则'"
      :visible.sync="isAddShow"
      width="50%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="currentData" status-icon :rules="rules" ref="ruleForm" label-width="180px">
          <el-form-item label="规则名称：" prop="ruleName">
            <el-input v-model="currentData.ruleName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="界定时间：" prop="ruleTime">
            <el-input v-model="currentData.ruleTime" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="一段退款时长（时)：" prop="rulelv1Day">
            <el-input v-model="currentData.rulelv1Day" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="一段退款费比（%)：" prop="rulelv1Percent">
            <el-input v-model="currentData.rulelv1Percent" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="二段退款时长（时)：" prop="rulelv2Day">
            <el-input v-model="currentData.rulelv2Day" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="二段退款费比（%)：" prop="rulelv2Percent">
            <el-input v-model="currentData.rulelv2Percent" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="三段退款时长（时)：" prop="rulelv3Day">
            <el-input v-model="currentData.rulelv3Day" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="三段退款费比（%)：" prop="rulelv3Percent">
            <el-input v-model="currentData.rulelv3Percent" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="退订政策：" prop="policy">
            <el-input v-model="currentData.policy" placeholder="请输入" type="textarea" :rows="6"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RefundRule',
  data() {
    return {
      loading: false,
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableData: [],
      isDeleteShow: false,
      isAddShow: false,
      deleteLoading: false,
      addLoading: false,
      currentData: {},
      formData: {},
      rules: {
        ruleName: [
          { required: true, message: '规则名称不可为空！', trigger: 'blur' }
        ],
        ruleTime: [
          { required: true, message: '界定时间不可为空！', trigger: 'blur' }
        ],
        rulelv1Day: [
          { required: true, message: '一段退款时长不可为空！', trigger: 'blur' }
        ],
        rulelv1Percent: [
          { required: true, message: '一段退款费比不可为空！', trigger: 'blur' }
        ],
        rulelv2Day: [
          { required: true, message: '二段退款时长不可为空！', trigger: 'blur' }
        ],
        rulelv2Percent: [
          { required: true, message: '二段退款费比不可为空！', trigger: 'blur' }
        ],
        rulelv3Day: [
          { required: true, message: '三段退款时长不可为空！', trigger: 'blur' }
        ],
        rulelv3Percent: [
          { required: true, message: '三段退款费比不可为空！', trigger: 'blur' }
        ],
        policy: [
          { required: true, message: '退订政策不可为空！', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios({
        method: 'get',
        url: '/room/rule/list',
        params: {
          param: this.formData.type,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize
        }
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.pageConfig = this.$getPageConfig1(res);
        } else {
          this.showAlert(res.message, 'error');
        }
      })
      .catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getList();
    },
    showDeleteModal(data) {
      this.currentData = { ...data };
      this.isDeleteShow = true;
    },
    onDelete() {
      this.delRule();
    },
    onQuery() {
      this.getList();
    },
    onReset() {
      this.formData = {};
      this.onQuery();
    },
    showAddModal(data) {
      this.currentData = { ...data };
      this.isAddShow = true;
    },
    submitData() {
      if (this.currentData.id == null) {
        this.addRule();
      } else {
        this.editRule();
      }
    },
    addRule() {
      this.$axios({
        method: 'post',
        url: '/room/rule',
        data: this.currentData
      }).then(res => {
        if (res.code === 200) {
          this.isAddShow = false;
          this.$showAlert('新增退款规则成功', 'success');
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    editRule() {
      this.$axios({
        method: 'put',
        url: '/room/rule',
        data: this.currentData
      }).then(res => {
        if (res.code === 200) {
          this.isAddShow = false;
          this.$showAlert('修改规则成功', 'success');
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    delRule() {
      this.$axios({
        method: 'delete',
        url: '/room/rule',
        params: {
          id: this.currentData.id
        }
      }).then(res => {
        if (res.code === 200) {
          this.isDeleteShow = false;
          this.$showAlert('删除规则成功', 'success');
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    }
  }
};
</script>

<style scoped lang="scss">
.RefundRule {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .add-div {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
