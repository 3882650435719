/* eslint-disable valid-jsdoc */
// 通用工具方法，会注册到全局
import Vue from 'vue'
import moment from 'moment'

/**
 * 转换日期格式
 * @param time {时间}
 * @param format {string}
 * @returns {string}
 */
Vue.prototype.formatTime = function (time, format = 'YYYY-MM-DD') {
  return moment(time).format(format)
}

/**
 * 通知方法
 * @param time
 * @param format
 * @returns {string}
 */
  Vue.prototype.$showAlert = function(content, type = 'success', title) {
    const titleValue = {
      'success': '成功',
      'warning': '警告',
      'info': '消息',
      'error': '错误'
    }
    title = title || titleValue[type]
    this.$notify({
      title: title,
      message: content,
      type: type
    });
  }


// 公共，获取分页参数，接收返回数据
/**
 * 根据返回数据，设置分页组件
 * @param response
 * @param myConfig
 * @returns {{total: *, pageSize: number | * | default.props.pageSize | {default, type} | default.watch.pageSize | {handler, immediate}, pageNum: *}}
 */
Vue.prototype.$getPageConfig = function(response, myConfig) {
  if (response.data && response.data.list.length > 0) {
    const pageConfig = {
      page: response.data.pageNum,
      rows: response.data.pageSize,
      total: response.data.total
    }
    if (myConfig) {
      return Object.assign(pageConfig, myConfig)
    } else {
      return pageConfig
    }
  } else {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0
    }
  }
}

Vue.prototype.$getPageConfig1 = function(response, myConfig) {
  if (response.data && response.data.list.length > 0) {
    const pageConfig = {
      pageNum: response.data.pageNum,
      pageSize: response.data.pageSize,
      total: response.data.total
    }
    if (myConfig) {
      return Object.assign(pageConfig, myConfig)
    } else {
      return pageConfig
    }
  } else {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  }
}

Vue.prototype.toMoney = function (str) {
  if (!str) {
    return '0.00'
  }
  let newStr = '';
  let count = 0;
  str += ''
  // 如果是负数，则需要把负号去掉再来转换
  let flag = false
  if (str < 0) {
    flag = true
    str = str.substring(1, str.length)
  }
  if (str.indexOf('.') === -1) {
    for (let i = str.length - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr + '.00'; // 自动补小数点后两位
    return str
  } else {
    for (let i = str.indexOf('.') - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr; // 碰到3的倍数则加上“,”号
      } else {
        newStr = str.charAt(i) + newStr; // 逐个字符相接起来
      }
      count++;
    }
    str = newStr + (str + '00').substr((str + '00').indexOf('.'), 3);
    if (flag) {
      str = '-' + str
    }
    return str
  }
}
