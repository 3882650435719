<template>
  <div class="layout-header">
    <div class="menu-collapse" @click="closeOrOpenMenu">
      <img src='../../assets/img/layout/pick.png' :class="{'is-open':menuStatus}" class="nav_pick"/>
    </div>
    <div class="page-nav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: item.url }" v-for="(item,index) in menuActiveArr" :key="index">{{item.name}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="login-out">
      您好, {{username}} <span class="password" @click="openModal">修改密码</span> <span @click="loginOut"><i
      class="icon-exit layout"></i></span>
    </div>
    <passwordDialog @close="isShowDialog = false" v-if="isShowDialog"
                   :is-show-dialog="isShowDialog"></passwordDialog>
  </div>
</template>

<script>
  import {getCookie} from '../../plugins/util';
  import passwordDialog from './dialog/passwordDialog.vue'

  export default {
    name: 'LayoutHeader',
    components: {
      passwordDialog
    },
    data() {
      return {
        username: getCookie('username'),
        isShowDialog: false
      }
    },
    computed: {
      menuStatus() {
        return this.$store.state.isCollapse
      },
      menuActiveArr() {
        return this.$store.state.menuActive
      }
    },
    methods: {
      getUserInfo() {
        this.$axios({
          url: '/managementAccount/getCurrentUser',
          method: 'get'
        }).then(res => {
          // 根据菜单地址，来添加权限
          if (res.code === 200) {
            this.username = res.data.name || res.data.username
            this.$store.commit('setUserInfo', {
              id: res.data.id,
              username: res.data.username
            })
          }
        })
      },
      loginOut() {
        sessionStorage.clear()
        this.$router.push('/login')
      },
      closeOrOpenMenu() {
        this.$store.commit('setIsCollapse', !this.menuStatus)
      },
      // 打开模态框
      openModal() {
        this.isShowDialog = true
      }
    },
    created() {
      this.getUserInfo()
    }
  }
</script>

<style lang="scss">
  .layout-header {
    height: 60px;
    display: flex;
    padding-right: 20px;
    align-items: center;
    .el-breadcrumb {
      margin-left: 20px;
      font-size: 16px;
    }
  }

  .menu-collapse {
    width: 20px;
    cursor: pointer;
  }

  .page-nav {
    flex: 1;
  }

  .login-out {
    display: inline-block;
    align-content: flex-end;
    .password {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      color: #1ab394;
    }
    .layout {
      font-size: 20px;
      vertical-align: middle;
    }
    span {
      cursor: pointer;
      //text-decoration: underline;
      &:hover {
        color: #1ab394;
      }
    }
  }

  .nav_pick {
    transition: all .5s;

    &.is-open {
      transform: rotate(180deg);
    }
  }
</style>
