<!--储物柜维护-->
<template>
  <div class="Maintain">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="储物区">
          <el-select v-model="formData.area" placeholder="请选择">
            <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="柜机编号">
          <el-input v-model="formData.cabinet" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-div">
          <el-button type="primary" class="c-btn" size="mini" @click="showAddModal()">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="area" label="分区名称">
          <template slot-scope="scope">
            <span>{{scope.row.area}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="柜机编号">
          <template slot-scope="scope">
            <span>{{scope.row.cabinet}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upTime" label="上线时间">
          <template slot-scope="scope">
            <span>{{scope.row.upTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showDeleteModal(scope.row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>确认删除当前储物柜</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      title="新增储物柜"
      :visible.sync="isAddShow"
      width="30%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="addData" status-icon :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="储物区：" prop="area">
            <el-select v-model="addData.area" placeholder="请选择" style="width: 50%;">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <el-button class="c-btn" style="margin-left: 20px;" @click="showAreaDialog">维护储物区</el-button>
          </el-form-item>
          <el-form-item label="柜机编号：" prop="cabinet">
            <el-input v-model="addData.cabinet" placeholder="请输入柜机编号"></el-input>
          </el-form-item>
          
          <div class="item-div" v-for="(item,index) in addData.grids" :key="index">
            <span style="margin-left:18px;font-weight:bold;">{{item.id == 1 ? '小格口' : item.id == 2 ? '中格口' : item.id == 3 ? '大格口' : ''}}</span>
            <el-form-item label="格口编号：" prop="grids">
              <el-input v-model="lockerData[item.id - 1].data" placeholder="请输入格口编号"></el-input>
            </el-form-item>
          </div>

          <span style="font-size:11px;margin-left:20px;"><span style="color:red;font-weight:bold;">※1</span>：请输入格口数字编号，以逗号（,）分隔。</span>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      title="储物区"
      :visible.sync="isAreaShow"
      width="30%"
      :close-on-click-modal="false"
      v-loading="areaLoading"
      append-to-body
    >
      <div class="content">
        <div
          v-for="(item, index) in currentAreaData"
          :key="index"
          style="display: flex;flex-direction: row;align-items: center;width: 100%;justify-content: center;align-items: center;margin-bottom:15px;"
        >
          <el-input
            v-model="item.area"
            placeholder="请输入储物区名称"
            style="width: 80%;margin-right: 20px;"
          ></el-input>
          <i @click="removeArea(index)" style="font-size: 25px" class="el-icon-remove-outline"></i>
        </div>
        <div
          style="display: flex;flex-direction: row;align-items: center;width: 100%;justify-content: center;align-items: center;"
        >
          <i @click="addArea()" style="font-size: 25px" class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAreaShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitAreaData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Maintain',
  data() {
    return {
      lockerData: [
        {id: 1, data: ''},
        {id: 2, data: ''},
        {id: 3, data: ''}
      ],
      loading: false,
      formData: {},
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      areaList: [],
      allAreaList: [],
      addData: {},
      tableData: [],
      isDeleteShow: false,
      isAddShow: false,
      deleteLoading: false,
      addLoading: false,
      areaLoading: false,
      isAreaShow: false,
      currentId: '',
      currentAreaData: [],
      // des: { temporaryOpen: '', agreement: '', image: '', list: [], grid:'' },
      rules: {
        area: [
          { required: true, message: '储物区不可为空！', trigger: 'blur' }
        ],
        cabinet: [
          { required: true, message: '柜机编号不可为空！', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getAreaList();
    this.getAllAreaList();
    this.getList();
    // this.getSettingDetail();
  },
  watch: {
    addData(val) {
      console.log(val, 12321321312323123)
    }
  },
  methods: {
    getSettingDetail() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/locker/set'
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.addData.grids = res.data.list;
            console.log(this.addData.grids);
            // for(const item of this.addData.grids){
            //   this.addData.grids.data = '';
            // }
            // this.des = res.data;
            // this.addData.grids.list = this.addData.grids.list ? this.addData.grids.list : [];
            // this.publicImgList =
            //   this.des.image && this.des.image !== ''
            //     ? [{ url: this.des.image }]
            //     : [];
            // for (const item of this.des.list) {
            //   item.logoList =
            //     item.logo && item.logo !== '' ? [{ url: item.logo }] : [];
            // }
          } else {
            this.addData.grids = [];
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/locker/cabinet/list',
        params: this.pageConfig,
        data: this.searchCondition
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 查询条件储物区列表
    getAreaList() {
      this.$axios({
        method: 'get',
        url: '/locker/details/area'
      }).then(res => {
        if (res.code === 200) {
          this.areaList = res.data;
        }
      });
    },
    // 维护储物区列表
    getAllAreaList() {
      this.$axios({
        method: 'get',
        url: '/locker/area'
      }).then(res => {
        if (res.code === 200) {
          this.allAreaList = res.data;
          this.currentAreaData = [].concat(this.allAreaList);
        }
      });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getList();
    },
    showDeleteModal(id) {
      this.currentId = id;
      this.isDeleteShow = true;
    },
    onDelete() {
      this.deleteLoading = true;
      this.$axios({
        method: 'delete',
        url: '/locker/cabinet',
        params: { id: this.currentId }
      })
        .then(res => {
          this.deleteLoading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.isDeleteShow = false;
            this.getList();
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.deleteLoading = false;
        });
    },
    showAddModal() {
      this.addData = {};
      this.getSettingDetail();
      this.isAddShow = true;
    },
    // 添加储物柜
    submitData() {
      this.addData.gridData = this.lockerData;
      console.log(JSON.stringify(this.addData));
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addLoading = true;
          this.$axios({
            method: 'post',
            url: '/locker/cabinet',
            data: this.addData
          })
            .then(res => {
              this.addLoading = false;
              if (res.code === 200) {
                this.$showAlert(res.message);
                this.isAddShow = false;
                this.getList();
              } else {
                // 请求出错
                this.$showAlert(res.message, 'error');
              }
            })
            .catch(() => {
              this.addLoading = false;
            });
        }
      });
    },
    showAreaDialog() {
      this.currentAreaData = [].concat(this.allAreaList);
      this.isAreaShow = true;
    },
    removeArea(index) {
      this.currentAreaData.splice(index, 1);
    },
    addArea() {
      this.currentAreaData.push({ area: '' });
    },
    // 提交储物区
    submitAreaData() {
      if (this.currentAreaData.length > 0) {
        for (const item of this.currentAreaData) {
          if (item.area === '') {
            this.$message.error('请输入储物区名称');
            return;
          }
        }
      }
      this.areaLoading = true;
      this.$axios({
        method: 'post',
        url: '/locker/area',
        data: this.currentAreaData
      })
        .then(res => {
          this.areaLoading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.getAreaList();
            this.getAllAreaList();
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.areaLoading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.Maintain {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .add-div {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
