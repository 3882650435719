import Vue from 'vue'

Vue.filter('formatMoney', function (val) {
  let _val = parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  return '¥' + _val;
})

Vue.filter('toMoney', function (str) {
    if (!str) {
      return '0.00'
    }
    let newStr = '';
    let count = 0;
    str += ''
    // 如果是负数，则需要把负号去掉再来转换
    let flag = false
    if (str < 0) {
      flag = true
      str = str.substring(1, str.length)
    }
    if (str.indexOf('.') === -1) {
      for (let i = str.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0) {
          newStr = str.charAt(i) + ',' + newStr;
        } else {
          newStr = str.charAt(i) + newStr;
        }
        count++;
      }
      str = newStr + '.00'; // 自动补小数点后两位
      return str
    } else {
      for (let i = str.indexOf('.') - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0) {
          newStr = str.charAt(i) + ',' + newStr; // 碰到3的倍数则加上“,”号
        } else {
          newStr = str.charAt(i) + newStr; // 逐个字符相接起来
        }
        count++;
      }
      str = newStr + (str + '00').substr((str + '00').indexOf('.'), 3);
      if (flag) {
        str = '-' + str
      }
      return str
    }
})
