/* eslint-disable no-tabs */
<!--订单分析-->
<template>
  <div v-loading="chartLoading" class="umbrellaAnalysis">
    <!-- 导出按钮 -->
    <div class="exportBar">
      <div class="pickerBack">
        <el-date-picker
          v-model="orderTypeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          style="width: 300px"
          @change="orderTypeTimeChange"
        />
      </div>
      <el-button
        type="primary"
        class="c-btn"
        @click="exportConfirmShow = true"
      >导出
      </el-button>
    </div>
    <!-- 饼图背景 -->
    <div class="pieChartBack">
      <!-- 订单类型比例 -->
      <div class="pieBack">
        <div class="titlePickerBack">
          <div class="lineTitleBack">
            <div class="line"></div>
            <div class="title">
              订单类型比例
            </div>
          </div>
        </div>
        <VeRing 
          :data="orderTypeRingData"
          :settings="ringNumSettings"
          :extend="orderTypeRingExtends"
          height="312px"
          width="472px"
        />
      </div>
      <!-- 伞位出租率 -->
      <div class="pieBack">
        <div class="titlePickerBack">
          <div class="lineTitleBack">
            <div class="line"></div>
            <div class="title">
              伞位出租率
            </div>
          </div>

        </div>
        <VeRing 
          :data="umbRateData"
          :settings="ringNumSettings"
          :extend="orderTypeRingExtends"
          height="312px"
          width="472px"
        />
      </div>
      <!-- 支付方式占比 -->
      <div class="pieBack">
        <div class="titlePickerBack">
          <div class="lineTitleBack">
            <div class="line"></div>
            <div class="title">
              支付方式占比
            </div>
          </div>
        </div>
        <VeRing 
          :data="payTypeRingData"
          :settings="ringNumSettings"
          :extend="payTypeRingExtends"
          height="312px"
          width="472px"
        />
      </div>
    </div>
    <!-- 资金走势图 -->
    <div class="bar">
      <div class="line"></div>
      <div class="title">资金走势图</div>
    </div>
    <VeLine
      :data="lineData"
      :settings="lineSettings"
      :legend-visible="false"
      height="300px"
      v-loading="false"
    ></VeLine>
    <!-- 年度资金变化趋势 -->
    <div class="bar">
      <div class="line"></div>
      <div class="title">年度资金变化趋势</div>
    </div>
        <VeLine
      :data="yearMonyData"
      :settings="yearMoneySeting"
      :legend-visible="true"
      :extend="yearMoneyExtends"
      height="300px"
    ></VeLine>
    <!-- 确认导出弹窗 -->
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="exportConfirmShow"
      width="420px"
      title="提示"
      v-loading="exportLoading"
      center
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        请确认导出分析数据
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="exportConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common';
import VeRing from 'v-charts/lib/ring.common';
import upIconUrl from '@/assets/img/icon_up.png';
import downIconUrl from '@/assets/img/icon_down.png';
export default {
  name: 'UmbrellaAnalysis',
  data() {
    return {
      chartLoading: false,
      // 确认导出弹窗
      exportConfirmShow: false,
      exportLoading: false,

      // 订单类型占比
      orderTypeTime: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
      orderTypeRingData: {
        columns: ['name', 'count'],
        rows: [

        ]
      },
      orderTypeRingExtends: {
        legend: { 
          show: true,
          // 图例组件离容器下侧的距离
          bottom: '10%',
          // 图例每项之间的间隔
          itemGap: 50,
          // 图例标记的图形宽度
          itemWidth: 15,
          // 图例标记的图形高度
          itemHeight: 15,
          // 圆角半径，单位px
          borderRadius: 0
        },  
	      series: {
	        center: ['50%', '40%']
        },
        color: [
          '#43BDED', '#5AD5AF'
        ]
      },
      // 伞位出租率
      umbRateData: {
        columns: ['name', 'count'],
        rows: [

        ]
      },
      // 支付方式占比
      payTypeRingTime: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
      payTypeRingData: {
        columns: ['name', 'count'],
        rows: [
          { name: '全额', count: 30 },
          { name: '后付', count: 20 },
          { name: '免单', count: 20 }
        ]
      },
      payTypeRingExtends: {
        ...this.orderTypeRingExtends,
        color: [
          '#5AD5AF', '#43BDED', '#F26D86'
        ]
      },
      // 年度资金变化趋势
      yearMonyData: {
        columns: [
          '日期', '环比', '同比'
        ],
        rows: [
          {'日期': '06/01', '同比': 0.4, '环比': 0.2},
          {'日期': '06/02', '同比': 0.5, '环比': 0.22},
          {'日期': '06/03', '同比': 0.4, '环比': 0.23},
          {'日期': '06/04', '同比': 0.4, '环比': 0.35},
          {'日期': '06/05', '同比': 0.4, '环比': 0.27},
          {'日期': '06/06', '同比': 0.4, '环比': 0.28}
        ]
      },
      yearMoneySeting: {
        metrics: ['同比', '环比'],
        dimension: ['日期']
      },
      yearMoneyExtends: {
        yAxis: {
          axisLabel: {
            formatter: (value, index) => `${value * 100}%`
          }
        }
      },

      lineData: {
        columns: ['time', 'amount'],
        rows: [
          { time: '01/01', amount: 10 },
          { time: '01/03', amount: 20 },
          { time: '01/05', amount: 30 },
          { time: '01/07', amount: 25 },
          { time: '01/09', amount: 15 },
          { time: '01/11', amount: 5 }
        ]
      },
      lineSettings: {
        metrics: ['amount'],
        dimension: ['time'],
        labelMap: { amount: '金额' }
      },
      ringExtends: {
        legend: { 
          show: true,
          // 图例组件离容器下侧的距离
          bottom: '10%',
          // 图例每项之间的间隔
          itemGap: 15,
          // 图例标记的图形宽度
          itemWidth: 15,
          // 图例标记的图形高度
          itemHeight: 15,
          // 圆角半径，单位px
          borderRadius: 0
        },  
	      series: {
	        center: ['50%', '40%']
        },
        color: [
          'red', 'blue'
        ]
      }
    };
  },
  created() {
    this.getChartData();
  },
  mounted() {
    this.keyLoading = true;
    this.tableLoading = true;
    this.lineLoading = true;
    this.ringLoading = true;

    this.payTypeRingExtends = {
      ...this.orderTypeRingExtends,
      color: [
          '#5AD5AF', '#43BDED', '#F26D86'
        ]
    }
  },
  components: {
    VeLine,
    VeRing
  },
  methods: {
    // 订单类型占比选择日期改变
    orderTypeTimeChange(time) {
      this.getChartData();
    },
    getChartData() {
      this.chartLoading = true;
      this.$axios({
        method: 'post',
        url: '/leaseOrder4Web/umbrella/statistics',
        params: {
          beginDate: this.parseDate(this.orderTypeTime[0]),
          endDate: this.parseDate(this.orderTypeTime[1])
        }
      })
      .then(res => {
        this.chartLoading = false;
        if (res.code === 200) {
          // 订单类型占比
          if (res.data.orderTypeStatistics) {
            this.orderTypeRingData.rows = [
              {name: '个人', count: res.data.orderTypeStatistics.person},
              {name: '团队', count: res.data.orderTypeStatistics.group}
            ]
          } else {
            this.orderTypeRingData.rows = [];
          }
          // 伞位出租率
          if (res.data.occupancyRateStatictics) {
            this.umbRateData.rows = [
              {name: '个人', count: res.data.occupancyRateStatictics.person},
              {name: '团队', count: res.data.occupancyRateStatictics.group}
            ]
          }

        } else {
          this.$showAlert(res.message, 'error');
        }
      })
      .catch(e => {
        this.chartLoading = false;
        this.$showAlert(e.message, 'error');
      })
    },
    parseDate(date) { 
      var year = date.getFullYear(); 
      var month = (date.getMonth() + 1).toString(); 
      var day = (date.getDate()).toString();  
      if (month.length === 1) { 
          month = '0' + month; 
      } 
      if (day.length === 1) { 
          day = '0' + day; 
      }
      var dateTime = year + '-' + month + '-' + day;
      return dateTime; 
    }
  }
};
</script>

<style scoped lang="scss">
.umbrellaAnalysis {
  .bar {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(245, 244, 247);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .line {
      width: 5px;
      height: 20px;
      background-color: #1ab394;
      margin-right: 10px;
    }

    .title {
      flex: 1;
    }
  }
  .exportBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(245, 244, 247);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pieChartBack {
    width: 100%;
    background-color: rgb(245, 244, 247);
    height: 352px;
    display: flex;
    justify-content: space-between;
    .pieBack {
      width: 520px;
      height: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      .titlePickerBack {
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        width: 100%;
        .lineTitleBack {
          display: flex;
          margin-left: 5px;
          .line {
            width: 5px;
            height: 20px;
            background-color: #1ab394;
            margin-right: 10px;
          }
          .title {
            color: #212121;
            font-size: 16px;
            margin-left: 10px;
          }
        }
        .pickerBack {
          width: 300px;
          margin-right: 10px;
        }
      }
    }
  }
  .key-div {
    border: 1px solid rgb(196, 193, 193);
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 15px;
      margin-bottom: 15px;

      .key-title {
        font-size: 16px;
        margin-bottom: 5px;
      }

      .count {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 18px;
      }

      .content {
        font-size: 14px;
      }
    }

    .key-line {
      width: 1px;
      height: 100px;
      background-color: rgb(196, 193, 193);
    }
  }
}
</style>
