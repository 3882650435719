<template>
  <el-dialog
    class="c-dialog"
    title="修改密码"
    :visible.sync="isShowDialog"
    v-loading="loading"
    width="700px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <div class="content">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原始密码：" prop="oldPassword">
          <el-input v-model="ruleForm.oldPassword" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input v-model="ruleForm.newPassword" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmPassword">
          <el-input v-model="ruleForm.confirmPassword" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button class="c-btn" @click="handleClose">取 消</el-button>
    <el-button type="primary" class="c-btn" @click="saveAccount">确 定</el-button>
   </span>
  </el-dialog>
</template>
<script>

  export default {
    name: 'modal',
    props: {
      isShowDialog: {
        type: Boolean
      }
    },
    data() {
      const validConfirm = (rule, value, callback) => {
        if (this.ruleForm.confirmPassword !== '') {
          if (this.ruleForm.newPassword !== this.ruleForm.confirmPassword) {
            callback(new Error('确认密码和新密码不一致!'))
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
      // 校验密码
      const validPassword = (rule, value, callback) => {
        const reg = /^[a-z_A-Z0-9-\.!@#\$%\\\^&\*\)\(\+=\{\}\[\]\/",'/-_<>~\·`\?:;|]{6,16}$/
        if (!reg.test(value)) {
          callback(new Error('请输入6-16位英文，数字或者符号'))
        } else {
          callback()
        }
      }
      return {
        ruleForm: {},
        rules: {
          oldPassword: [
            {
              required: true,
              message: '密码不可以为空！',
              trigger: 'blur'
            }],
          newPassword: [
            {
              required: true,
              message: '密码不可以为空！',
              trigger: 'blur'
            }, {
              required: true,
              message: '请输入请输入6-16位英文，数字或者符号密码',
              trigger: 'blur',
              validator: validPassword
            }],
          confirmPassword: [
            {
              required: true,
              message: '请再次输入新密码确认！',
              trigger: 'blur'
            }, {
              required: true,
              message: '确认密码和新密码不一致！',
              trigger: 'blur',
              validator: validConfirm
            }, {
              required: true,
              message: '请输入请输入6-16位英文，数字或者符号密码',
              trigger: 'blur',
              validator: validPassword
            }]
        },
        loading: false
      }
    },
    mounted() {

    },
    methods: {
      saveAccount() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$axios({
              method: 'post',
              url: '/managementAccount/modifyPassword',
              data: {
                'oldPassword': this.ruleForm.oldPassword,
                'newPassword': this.ruleForm.newPassword
              }
            }).then(res => {
              this.loading = false;
              if (res.code === 200) {
                this.$showAlert(res.message)
                sessionStorage.clear()
                this.$router.push('/login')
              } else {
                // 请求出错
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false;
            })
          } else {
            return false;
          }
        });
      },
      handleClose() {
        this.$emit('close')
      }
    }
  }
</script>
<style scoped lang="scss">

</style>
