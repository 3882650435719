<!--租赁协议-->
<template>
  <div class="RoomProtocal" v-loading="loading">
    <div class="div-logo">
      <span class="label">租赁协议:</span>
      <yd-ue v-model="protocol" class="introduce"></yd-ue>
    </div>
    <el-button class="c-btn" @click="onSave">保存</el-button>
  </div>
</template>

<script>
export default {
  name: 'RoomProtocal',
  data() {
    return {
      loading: false,
      protocol: ''
    };
  },
  created() {
    this.loading = true;
    this.getProtocol();
  },
  methods: {
    getProtocol() {
      this.$axios({
        method: 'get',
        url: '/room/protocol'
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.protocol = res.data;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    onSave() {
      this.$axios({
        method: 'put',
        url: '/room/protocol',
        data: {
          protocol: this.protocol
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('修改成功', 'success');
          this.getProtocol();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$$showAlert(e.message, 'error');
      })
    }
  }
};
</script>

<style scoped lang="scss">
.RoomProtocal {
  padding-bottom: 20px;
  padding-left: 20px;

  .label {
    margin-right: 30px;
    text-align: right;
  }

  .div-logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .introduce {
      width: 70%;
    }
  }
}
</style>
