<!--房位订单详情-->
<template>
  <div class="RoomDetail order-common">
    <div class="title-box">
      <div class="icon"></div>
      <div class="title">订单编号：{{orderDetailObj.orderCode}}</div>
      <div
        class="operate"
      >{{orderDetailObj.orderStatus ? orderStatus[orderDetailObj.orderStatus] : '-'}}</div>
    </div>
    <div class="main-box">
      <div class="head" style="display: flex;flex-direction: row;width: 100%;align-items: center">
        商品信息
        <div style="flex: 1; text-align: right;font-size: 14px;">
          <span style="margin-right: 10px;">总数量:</span>
          {{orderDetailObj.count ? orderDetailObj.count : '-'}}
        </div>
      </div>
      <div style="margin-top: 20px;margin-bottom: 50px;">
        <div class="item-div">
          <span style="width: 30%;">{{orderDetailObj.roomName}}</span>
          <span style="width: 30%;text-align: center;">{{orderDetailObj.startDate}} - {{orderDetailObj.endDate}}</span>
          <span style="width: 20%;text-align: center;">{{orderDetailObj.useTime}}天</span>
          <span style="width: 20%;text-align: right;">小计：￥{{orderDetailObj.actualAmount}}</span>
        </div>
      </div>
      <div class="item-list">
        <div class="right-div">
          <div class="label" style="margin-right: 20px;">应收合计：</div>
          <div class="value">¥{{orderDetailObj.totalPrice ? orderDetailObj.totalPrice : '-'}}</div>
        </div>
        <div class="right-div">
          <div class="label" style="margin-right: 20px;color:black;">实收合计：</div>
          <div
            style="font-weight: bold;color: #e74c3c;font-size: 18px;"
          >¥{{orderDetailObj.totalPrice ? orderDetailObj.totalPrice : '-'}}</div>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="head">订单信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">订单编号：</div>
          <div class="value">{{orderDetailObj.orderCode ? orderDetailObj.orderCode : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单状态：</div>
          <div
            class="value"
          >{{orderDetailObj.orderStatus ? orderStatus[orderDetailObj.orderStatus] : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="value">{{orderDetailObj.createTime ? orderDetailObj.createTime : '-'}}</div>
        </div>

        <!-- <div class="item" v-if="orderDetailObj.orderStatus == '已支付'">
          <div class="label">支付方式：</div>
          <div
            class="value"
          >{{orderDetailObj.platformType ? platformType[orderDetailObj.platformType] : '-'}}</div>
        </div>-->
        <div
          class="item"
          v-if="orderDetailObj.orderStatus == '待使用' || orderDetailObj.orderStatus == '已使用' || orderDetailObj.orderStatus == '已退款'"
        >
          <div class="label">支付时间：</div>
          <div class="value">{{orderDetailObj.payTime ? orderDetailObj.payTime : '-'}}</div>
        </div>
        <div
          class="item"
          v-if="orderDetailObj.orderStatus == '待使用' || orderDetailObj.orderStatus == '已使用' || orderDetailObj.orderStatus == '已退款'"
        >
          <div class="label">第三方支付交易号：</div>
          <div class="value">{{orderDetailObj.transactionNo ? orderDetailObj.transactionNo : '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';

export default {
  name: 'RoomDetail',
  data() {
    return {
      orderDetailObj: {},
      platformType: constantValue.platformType,
      orderStatus: constantValue.orderStatus,
      liveLength:0,
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      let id = this.$route.query.id;
      this.$axios({
        method: "get",
        url: `/roomOrder/detail/${id}`
      })
        .then(res => {
          if (res.code === 200) {
            this.orderDetailObj = res.data ? res.data : {};
          } else {
            // 请求出错
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";
.RoomDetail {
  width: 800px;
  margin: 0 auto;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .item-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #676a6c;
    margin-bottom: 10px;
  }
  .right-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
