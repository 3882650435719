<!--运营设置-->
<template>
  <div class="Setting" v-loading="loading">
    <div class="div-input">
      <span class="label">储物区分布图:</span>
      <el-upload
        :headers="headers"
        :action="imgUrl"
        name="uploadFile"
        :on-success="handlePublicSuccess"
        :on-preview="handlePreview"
        :file-list="publicImgList"
        :on-remove="handlePublicRemove"
        :limit="1"
      >
        <el-button type="primary" class="c-btn" size="mini">点击上传</el-button>
      </el-upload>
    </div>
    <div class="div-logo" style="margin-bottom: 0px;">
      <span class="label">格口设置:</span>
      <div style="font-size: 14px">
        <div class="item-div" v-for="(item,index) in des.list" :key="index">
          <span
            style="margin-right: 30px;"
          >{{item.id == 1 ? '小格口' : item.id == 2 ? '中格口' : item.id == 3 ? '大格口' : ''}}</span>
          <span style="margin-right: 10px;">收费:</span>
          <el-input
            style="width: 200px; margin-right: 30px;"
            v-model="item.rent"
            placeholder="请输入"
            clearable
            type="number"
            v-enter-number
            size="small"
          >
            <template slot="append">元/小时</template>
          </el-input>
          <span style="margin-right: 10px;">logo:</span>
          <el-upload
            :headers="headers"
            :action="imgUrl"
            name="uploadFile"
            :limit="1"
            :file-list="item.logoList"
            :on-preview="handlePreview"
            :on-success="(res, file) => handleUploadSuccess(res, file, index)"
            :on-remove="() => handleUploadRemove(index)"
            style="margin-right: 30px;maxWidth: 300px;"
          >
            <el-button size="small">点击上传</el-button>
          </el-upload>
          <span style="margin-right: 10px;">尺寸:</span>
          <el-input
            style="width: 150px;"
            v-model="item.size"
            placeholder="请输入"
            clearable
            size="small"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="div-input">
      <span class="label">是否允许临时开柜:</span>
      <el-radio-group v-model="des.temporaryOpen">
        <el-radio
          :label="item.value"
          :key="item.value"
          v-for="item in temporaryOpenList"
        >{{item.label}}</el-radio>
      </el-radio-group>
    </div>
    <div class="div-logo">
      <span class="label">储物柜租赁协议:</span>
      <yd-ue v-model="des.agreement" class="introduce"></yd-ue>
    </div>
    <el-button class="c-btn" @click="saveSetting">保存</el-button>
    <el-dialog :visible.sync="imgDialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>

<script>
import { getCookie, baseServiceUrl } from '@/plugins/util';
export default {
  name: 'Setting',
  data() {
    return {
      loading: false,
      headers: {
        token: getCookie('token')
      },
      imgUrl: baseServiceUrl() + '/image/upload',
      des: { temporaryOpen: '', agreement: '', image: '', list: [] },
      dialogImageUrl: '',
      imgDialogVisible: false,
      publicImgList: [],
      temporaryOpenList: [
        {
          label: '是',
          value: 2
        },
        {
          label: '否',
          value: 1
        }
      ]
    };
  },

  created() {
    this.getSettingDetail();
  },
  methods: {
    getSettingDetail() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/locker/set'
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.des = res.data;
            this.des.list = this.des.list ? this.des.list : [];
            this.publicImgList =
              this.des.image && this.des.image !== ''
                ? [{ url: this.des.image }]
                : [];
            for (const item of this.des.list) {
              item.logoList =
                item.logo && item.logo !== '' ? [{ url: item.logo }] : [];
            }
          } else {
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialogVisible = true;
    },
    handlePublicSuccess(res, file) {
      this.publicImgList = [{ url: res.data[file.name], name: file.name }];
    },
    handlePublicRemove(file, fileList) {
      this.publicImgList = [];
    },
    handleUploadSuccess(res, file, index) {
      this.des.list[index].logoList = [
        { url: res.data[file.name], name: file.name }
      ];
    },
    handleUploadRemove(index) {
      this.des.list[index].logoList = [];
    },
    saveSetting() {
      if (this.publicImgList === null || this.publicImgList.length === 0) {
        this.$message.error('请上传储物区分布图');
        return;
      }
      for (const item of this.des.list) {
        if (item.rent === null || item.rent === '') {
          this.$message.error('请填写格口收费');
          return;
        }
        if (item.logoList === null || item.logoList.length === 0) {
          this.$message.error('请上传格口logo');
          return;
        }
        if (item.size === null || item.size === '') {
          this.$message.error('请填写格口尺寸');
          return;
        }
        item.logo = item.logoList[0].url;
      }
      if (this.des.temporaryOpen === null || this.des.temporaryOpen === '') {
        this.$message.error('请选择是否允许临时开柜');
        return;
      }
      this.des.image = this.publicImgList[0].url;
      this.loading = true;
      this.$axios({
        method: 'put',
        url: '/locker/set',
        data: this.des
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
          } else {
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.Setting {
  padding-left: 30px;
  padding-bottom: 20px;

  .div-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
  .label {
    width: 150px;
    margin-right: 30px;
    text-align: right;
  }

  .div-logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .introduce {
      width: 70%;
    }

    .item-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
</style>
