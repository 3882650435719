<!--设置，菜单管理-->
<template>
  <div class="menuManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchModel" class="menu-line">
        <el-form-item label="菜单名称">
          <el-input v-model="searchModel.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <!--        <el-form-item label="类型">-->
        <!--          <el-select v-model="searchModel.parentId" placeholder="请选择">-->
        <!--            <el-option label="一级菜单" :value="0"></el-option>-->
        <!--            <el-option label="二级菜单" :value="1"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item class="add-menu">
          <el-button type="primary" class="c-btn" @click="showDialog()">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="role-table">
      <el-table
        class="c-table" v-loading="loading" :data="tableData" style="width: 100%" :tree-props="{children: 'child'}"
        row-key="id">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="菜单名称"></el-table-column>
        <el-table-column prop="account" label="类型" width="100">
          <template slot-scope="scope">
            <span v-text="scope.row.parentId === 0 ? '一级菜单' : '二级菜单'"></span>
          </template>
        </el-table-column>
        <el-table-column prop="iphone" label="链接">
          <template slot-scope="scope">
            <span v-text="scope.row.href"></span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="permission" label="权限码"></el-table-column>-->
        <el-table-column prop="statue" label="状态">
          <template slot-scope="scope">
            <span v-text="scope.row.status === 1 ? '启用' : '禁用'"></span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showDialog(scope.row.id)">编辑</el-button>
            <el-button type="text" size="small" @click="changeStatus(scope.row)"
                       :class="{'red': scope.row.status === 1}">
              {{scope.row.status === 1 ? '禁用' : '启用'}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <menuDialog :menu-id="menuId" v-if="isShowDialog" @close="isShowDialog = false"
                :is-show-dialog="isShowDialog" @callBackList="onQuery"></menuDialog>
  </div>
</template>

<script>
  import menuDialog from './dialog/menuDialog.vue'
  import {deepCopy} from '../../plugins/util';

  export default {
    name: 'menuManage',
    components: {
      menuDialog
    },
    data() {
      return {
        isShowDialog: false,
        operateId: '',
        loading: false,
        menuId: null,
        searchModel: {},
        tableData: []
      }
    },
    methods: {
      reset() {
        this.searchModel = {}
        this.onQuery()
      },
      // 查询
      onQuery() {
        const params = Object.assign(this.searchModel)
        this.loading = true
        this.$axios({
          url: '/menu/all',
          method: 'get',
          // data: params
          params: {
            name: this.searchModel.name,
            type: this.searchModel.type
          }
        }).then(res => {
          this.loading = false
          if (res.code === 200) {
            this.tableData = res.data;
          } else {
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false
        })
      },
      // 打开模态框
      showDialog(id) {
        this.menuId = id;
        this.isShowDialog = true
      },
      // 禁用和启用切换
      changeStatus(item) {
        let value = '';
        if (item.status === 1) {
          value = '禁用'
        } else {
          value = '启用'
        }
        this.$confirm(`是否${value}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          this.$axios({
            method: 'put',
            url: '/menu/updateStatus',
            data: {
              id: item.id,
              status: item.status === 0 ? 1 : 0
            }
          }).then(res => {
            if (res.code === 200) {
              this.$showAlert(res.message)
              this.onQuery();
            } else {
              // 请求出错
              this.$showAlert(res.message, 'error')
              console.log(res)
            }
          }).catch(e => {
            console.log(e)
          })
        })
      }
    },
    created() {
      this.onQuery()
    }
  }
</script>

<style scoped lang="scss">
  .menuManage {
    .menu-line {
      position: relative;
      .add-menu {
        position: absolute;
        right: 0;
      }
    }
    .c-table {
      .el-table__row {
        .el-button.red {
          color: red;
        }
      }
    }
    .box-card {
      margin-bottom: 20px;
    }
    .role-table {
      margin-bottom: 20px;
    }
    .el-card.is-always-shadow {
      box-shadow: none;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
</style>
