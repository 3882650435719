<!--格口列表-->
<template>
  <div class="GridList">
    <el-card>
      <el-form
        :inline="true"
        :model="formData"
        class="form-inline"
        style="padding-right: 100px;"
        label-width="80px"
      >
        <el-form-item label="储物区">
          <el-select v-model="formData.area" placeholder="请选择">
            <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="柜机编号">
          <el-input v-model="formData.cabinet" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="格口编号">
          <el-input v-model="formData.grid" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="门状态">
          <el-select v-model="formData.door" placeholder="请选择">
            <el-option
              v-for="item in doorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="格口类型">
          <el-select v-model="formData.size" placeholder="请选择">
            <el-option
              v-for="item in sizeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-select v-model="formData.lockerStatus" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="area" label="分区名称">
          <template slot-scope="scope">
            <span>{{scope.row.area}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="柜机编号">
          <template slot-scope="scope">
            <span>{{scope.row.cabinet}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grid" label="格口编号">
          <template slot-scope="scope">
            <span>{{scope.row.grid}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="size" label="格口类型">
          <template slot-scope="scope">
            <span>{{scope.row.size == 1 ? '小格口' : scope.row.size == 2 ? '中格口' : '大格口'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lockerStatus" label="使用状态">
          <template slot-scope="scope">
            <span>{{scope.row.lockerStatus == 1 ? '空闲' : scope.row.lockerStatus == 2 ? '在用' : '维护'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="door" label="门状态">
          <template slot-scope="scope">
            <span>{{scope.row.door == 1 ? '开' : '关'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button
                type="text"
                size="small"
                v-if="scope.row.door == 0"
                @click="showConfirmDialog(scope.row)"
              >开柜</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isConfirmShow"
      width="420px"
      title="提示"
      center
      v-loading="confirmLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>是否进行开柜操作</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onConfirm()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'gridList',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableData: [],
      doorList: [
        {
          value: 1,
          label: '开'
        },
        {
          value: 2,
          label: '关'
        }
      ],
      sizeList: [
        {
          value: 1,
          label: '小格口'
        },
        {
          value: 2,
          label: '中格口'
        },
        {
          value: 3,
          label: '大格口'
        }
      ],
      statusList: [
        {
          value: 1,
          label: '空闲'
        },
        {
          value: 2,
          label: '在用'
        },
        {
          value: 3,
          label: '维护'
        }
      ],
      areaList: [],
      isConfirmShow: false,
      confirmLoading: false,
      currentData: {}
    };
  },
  created() {
    this.getAreaList();
    this.getGridList();
  },
  methods: {
    getGridList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/locker/details',
        params: this.pageConfig,
        data: this.searchCondition
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 储物区列表
    getAreaList() {
      this.$axios({
        method: 'get',
        url: '/locker/details/area'
      }).then(res => {
        if (res.code === 200) {
          this.areaList = res.data;
        }
      });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getGridList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getGridList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getGridList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getGridList();
    },
    showConfirmDialog(data) {
      this.currentData = data;
      this.isConfirmShow = true;
    },
    // 开柜
    onConfirm() {
      this.confirmLoading = true;
      this.$axios({
        method: 'post',
        url: '/v1.0/locker/admin/open',
        params: { id: this.currentData.id }
      })
        .then(res => {
          this.confirmLoading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.isConfirmShow = false;
            this.getGridList();
          } else {
            // 请求出错
            this.confirmLoading = false;
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.GridList {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
