<!--手环管理-->
<template>
  <div class="WristbandManage">
    <el-tabs v-model="activeName">
      <el-tab-pane label="手环管理" name="first">
        <WristbandList/>
      </el-tab-pane>
      <el-tab-pane label="手环描述" name="second">
        <WristbandDesc/>
      </el-tab-pane>
      <el-tab-pane label="租赁协议" name="third">
        <WristbandProtocal/>
      </el-tab-pane>
      <el-tab-pane label="租赁管理" name="fourth">
        <RentManage/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import WristbandList from './WristbandList.vue';
import RentManage from './RentManage.vue';
import WristbandDesc from './WristbandDesc.vue';
import WristbandProtocal from './WristbandProtocal.vue';
export default {
  name: 'WristbandManage',
  data() {
    return {
      activeName: 'first'
    };
  },
  mounted() {},
  components: {
    WristbandList,
    RentManage,
    WristbandDesc,
    WristbandProtocal
  },
  methods: {}
};
</script>

<style lang="scss">
.WristbandManage {
  .el-tabs__header {
    padding: 20px;
  }

  .el-tabs__nav {
    div {
      font-size: 18px;
    }
  }
}
</style>
