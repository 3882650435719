/* eslint-disable quotes */
<!--手环管理列表-->
<template>
  <div class="wristbandList">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="物理编码">
          <el-input v-model="formData.physicalEncoding" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.deviceId"
            placeholder="请输入"
            clearable
            type="number"
            v-enter-number
          ></el-input>
        </el-form-item>
        <el-form-item label="设备状态">
          <el-select v-model="formData.deviceStatus" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btn-div">
        <el-button
          type="primary"
          class="c-btn"
          size="mini"
          @click="showAddModal({})"
          style="margin-right: 10px"
        >添加</el-button>
        <el-upload
          :headers="headers"
          action="http://127.0.0.1:8081/v1.0/barcelet/import"
          accept=".xlsx, .xls"
          :on-success="handleUploadSuccess"
          :on-error="handleUploadError"
          name="multipartFile"
          :show-file-list="false"
        >
          <el-button type="primary" class="c-btn" size="mini" :show-file-list="false">批量导入</el-button>
        </el-upload>
        <span class="download">
          点击<span style="color: #2300FF;" @click="download()">下载</span>导入手环模板
        </span>
      </div>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="deviceId" label="设备编号">
          <template slot-scope="scope">
            <span>{{scope.row.deviceId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="physicalEncoding" label="物理编码">
          <template slot-scope="scope">
            <span>{{scope.row.physicalEncoding}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="imei" label="IMEI" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.imei}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceStatus" label="设备状态">
          <template slot-scope="scope">
            <span>{{deviceStatus[scope.row.deviceStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ticketCode" label="票务编码">
          <template slot-scope="scope">
            <span>{{scope.row.ticketCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="storageCabinetCoding" label="储物柜编码">
          <template slot-scope="scope">
            <span>{{scope.row.storageCabinetCoding}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="foodCode" label="餐饮编码">
          <template slot-scope="scope">
            <span>{{scope.row.foodCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="posCode" label="POS编码">
          <template slot-scope="scope">
            <span>{{scope.row.posCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="物理状态">
          <template slot-scope="scope">
            <span>{{devicePhysicalStatus[scope.row.status]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showAddModal(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="showDeleteModal(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p v-if="currentData.deviceStatus != 0">
          当前手环状态
          <br>不可删除操作
        </p>
        <p v-else>确认删除当前手环</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      :title="title"
      :visible.sync="isAddShow"
      width="50%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="currentData" status-icon :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item label="物理编码：" prop="physicalEncoding">
            <el-input v-model="currentData.physicalEncoding" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="设备编号：" prop="deviceId">
            <el-input v-model="currentData.deviceId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="IMEI：" prop="imei">
            <el-input v-model="currentData.imei" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="票务编码：" prop="ticketCode">
            <el-input v-model="currentData.ticketCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="储物柜编码：" prop="storageCabinetCoding">
            <el-input v-model="currentData.storageCabinetCoding" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="餐饮编码：" prop="foodCode">
            <el-input v-model="currentData.foodCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="POS编码：" prop="posCode">
            <el-input v-model="currentData.posCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="物理状态" prop="physicalDeviceStatus">
            <el-radio-group v-model="currentData.status" size="mini">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCookie } from '@/plugins/util';
import { constantValue } from '@/plugins/constantValue';
export default {
  name: 'wristbandList',
  data() {
    return {
      formData: {},
      searchCondition: {},
      title: '新增',
      deviceStatus: constantValue.deviceStatus,
      devicePhysicalStatus: constantValue.devicePhysicalStatus,
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      headers: {
        token: getCookie('token')
      },
      statusList: [
        { label: '未使用', value: 0 },
        { label: '使用中', value: 1 },
        { label: '已挂失', value: 2 },
        { label: '未退还', value: 3 }
      ],
      tableData: [],
      loading: false,
      isDeleteShow: false,
      deleteLoading: false,
      isAddShow: false,
      addLoading: false,
      currentData: {},
      rules: {
        physicalEncoding: [
          { required: true, message: '物理编码不可为空！', trigger: 'blur' }
        ],
        deviceId: [
          { required: true, message: '设备编号不可为空！', trigger: 'blur' }
        ],
        imei: [
          { required: true, message: 'IMEI不可为空！', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getWristbandList();
  },
  methods: {
    getWristbandList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/getBraceletInfoByCriteria',
        params: this.pageConfig,
        data: this.searchCondition
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getWristbandList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getWristbandList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getWristbandList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getWristbandList();
    },
    showAddModal(data) {
      if (data.id === null) {
        this.title = '新增';
      } else {
        this.title = '编辑';
      }
      this.currentData = { ...data };
      this.isAddShow = true;
    },
    showDeleteModal(data) {
      this.currentData = { ...data };
      this.isDeleteShow = true;
    },
    // 删除
    onDelete() {
      if (this.currentData.deviceStatus !== 0) {
        this.isDeleteShow = false;
      } else {
        this.deleteLoading = true;
        this.$axios({
          method: 'delete',
          url: `/bracelet/${this.currentData.id}/deleteBracelet`
        })
          .then(res => {
            this.deleteLoading = false;
            if (res.code === 200) {
              this.$showAlert(res.message);
              this.isDeleteShow = false;
              this.getWristbandList();
            } else {
              this.$showAlert(res.message, 'error');
            }
          })
          .catch(e => {
            this.deleteLoading = false;
            console.log(e);
          });
      }
    },
    // 添加、编辑
    submitData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addLoading = true;
          let url = '';
          if (this.title == '新增') {
            url = '/bracelet/createBracelet';
          } else {
            url = '/bracelet/updateBracelet';
          }
          this.$axios({
            method: 'post',
            url,
            data: this.currentData
          })
            .then(res => {
              this.addLoading = false;
              if (res.code === 200) {
                this.$showAlert(res.message);
                this.isAddShow = false;
                this.getWristbandList();
              } else {
                // 请求出错
                this.$showAlert(res.message, 'error');
              }
            })
            .catch(e => {
              this.addLoading = false;
              console.log(e);
            });
        }
      });
    },

    /**
     * 导入手环成功
     */
    handleUploadSuccess(res, file) {
      console.log(JSON.stringify(res));
      let code = res.code;
      if(code == 200){
        this.$showAlert('导入成功');
        this.getWristbandList();
      }
      else{
        this.$showAlert(res.message);
      }
    },

    /**
     * 导入手环失败
     */
    // eslint-disable-next-line handle-callback-err
    handleUploadError(err) {
      this.$showAlert('导入失败', 'error');
    },
    // 下载模版
    download() {
      window.open('http://117.48.209.37:8081/bracelet/downloadTemplate');
    }
  }
};
</script>

<style scoped lang="scss">
.wristbandList {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .btn-div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;

    .download {
      flex: 1;
      text-align: right;
      font-size: 14px;
      color: grey;
    }
  }
}
</style>
