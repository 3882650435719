<!--退房确认-->
<template>
  <div class="WristbandBind order-common" v-loading="loading">
    <div class="title-box">
      <div class="title">退房确认</div>
    </div>
    <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
      <el-form-item label="房间号:">
        <el-input v-model="formData.orderCode" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单号:">
        <el-input v-model="formData.phone" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="c-btn" size="mini" @click="onQuery()">检索</el-button>
      </el-form-item>
    </el-form>
    <el-card v-if="orderData.id != null">
      <div class="desc-div">
        <div class="item-div">
          <span class="item-label">创建时间:</span>
          <span>1111111</span>
        </div>
        <div class="item-div">
          <span class="item-label">订单编号:</span>
          <span>1111111</span>
        </div>
        <div class="item-div">
          <span class="item-label">手机号:</span>
          <span>1111111</span>
        </div>
        <div class="item-div">
          <span class="item-label">支付时间:</span>
          <span>1111111</span>
        </div>
      </div>
      <div class="card-table">
        <el-table
          class="c-table"
          :data="roomList"
          style="width: 100%"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="cardNumber" label="房名">
            <template slot-scope="scope">
              <span>111</span>
            </template>
          </el-table-column>
          <el-table-column prop="no" label="房号">
            <template slot-scope="scope">
              <span>{{scope.row.no}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceNo" label="房型">
            <template slot-scope="scope">
              <span>{{scope.row.deviceNo}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName1" label="商品状态">
            <template slot-scope="scope">
              <span>是</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName1" label="房位状态">
            <template slot-scope="scope">
              <span>是</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName1" label="入住时间">
            <template slot-scope="scope">
              <span>是</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName1" label="离店时间">
            <template slot-scope="scope">
              <span>是</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName2" label="是否退房">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                :disabled="scope.row.braceletLeaseStatus != 0"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <div class="bottom-btn">
      <el-button type="primary" class="c-btn" @click="cancelAll()">全部取消</el-button>
      <el-button type="primary" class="c-btn" @click="confirmCheckOut()">批量退房</el-button>
      <el-button class="c-btn" @click="goBack()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WristbandBind',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      currentCardId: '',
      orderData: {},
      roomList: []
    };
  },
  created() {},
  mounted() {
    this.timer = setInterval(() => {
      if (!this.loading) {
        this.getOrderDetail();
      }
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getOrderDetail() {
      // if (
      //   this.searchCondition.orderCode == null ||
      //   this.searchCondition.orderCode == ""
      // ) {
      //   return;
      // }
      // this.loading = true;
      // this.$axios({
      //   method: "get",
      //   url: "/bracelet/getOrderInfoByOrderCode",
      //   params: { orderCode: this.searchCondition.orderCode }
      // })
      //   .then(res => {
      //     if (res.code === 200) {
      //       const oldData = { ...this.orderData };
      //       this.orderData = res.data ? res.data : {};
      //       this.orderData.braceletOrderItemList = this.orderData
      //         .braceletOrderItemList
      //         ? this.orderData.braceletOrderItemList
      //         : [];
      //       if (
      //         JSON.stringify(this.orderData.braceletOrderItemList.sort()) ==
      //         JSON.stringify(oldData.braceletOrderItemList.sort())
      //       ) {
      //         return;
      //       }
      //       const list = [];
      //       for (const item of this.orderData.braceletOrderItemList) {
      //         const newItem = { ...item };
      //         newItem.checked = false;
      //         list.push(newItem);
      //       }
      //       this.roomList = list;
      //       this.loading = false;
      //     } else {
      //       // 请求出错
      //       this.loading = false;
      //     }
      //   })
      //   .catch(e => {
      //     this.loading = false;
      //     console.log(e);
      //   });
    },
    cancelAll() {
      for (const item of this.roomList) {
        item.checked = false;
      }
    },
    confirmCheckOut() {
      const idList = [];
      for (const item of this.roomList) {
        if (item.checked) {
          idList.push(item.id);
        }
      }
      if (idList.length === 0) {
        this.$message.warning('请选择房间');
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/braceletBind',
        data: { items: idList }
      })
        .then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.$showAlert(res.message);
          } else {
            // 请求出错
            this.loading = false;
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.getOrderDetail();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";

.WristbandBind {
  flex: 1;
  margin-left: 50px;
  margin-right: 50px;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bottom-btn {
    margin-top: 20px;
    padding-bottom: 20px;
    border: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .desc-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .item-div {
      display: flex;
      flex-direction: row;
      width: 30%;
      color: #676a6c;
      font-size: 14px;

      .item-label {
        margin-right: 8px;
      }
    }

    .status {
      width: 10%;
      font-size: 16px;
      margin-bottom: 10px;
      color: #e74c3c;
    }
  }
}
</style>
