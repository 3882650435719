<!--公众号配置-->
<template>
  <div class="PublicNumberSet" style="padding-top:20px;padding-bottom:20px;">
    <el-form :model="ruleForm" v-loading="loading" status-icon :rules="rules" ref="ruleForm" label-width="150px"
             class="demo-ruleForm">
      <el-form-item label="AppId：" prop="appId">
        <el-input v-model="ruleForm.appId" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="AppSecret：" prop="appSecret">
        <el-input v-model="ruleForm.appSecret" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="微信支付商户号：" prop="appReciever">
        <el-input v-model="ruleForm.appReciever" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="微信支付API密钥：" prop="paySecret">
        <el-input v-model="ruleForm.paySecret" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="c-btn" @click="savePublicSet">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import ElButton from '../../../node_modules/element-ui/packages/button/src/button.vue';

  export default {
    components: {ElButton},
    name: 'PublicNumberSet',
    data() {
      return {
        ruleForm: {},
        rules: {},
        loading: false
      }
    },
    created() {
      this.queryPublicSet();
    },
    methods: {
      // 保存
      savePublicSet() {
        this.loading = true;
        this.$axios({
          method: 'post',
          url: '/apps',
          data: this.ruleForm
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.queryPublicSet();
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      },
      // 查询
      queryPublicSet() {
        this.loading = true;
        this.$axios({
          method: 'get',
          url: '/apps'
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.ruleForm = res.data;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .PublicNumberSet {
    .el-form {
      width: 55%;
    }
  }

</style>
