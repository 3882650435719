<!--手环管理列表-->
<template>
  <div class="RentManage">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="物理编码">
          <el-input v-model="formData.physicalEncoding" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备状态">
          <el-select v-model="formData.deviceStatus" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.braceletUserTelephone" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.deviceId"
            placeholder="请输入"
            clearable
            type="number"
            v-enter-number
          ></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="formData.braceletOrderCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="deviceId" label="设备编号">
          <template slot-scope="scope">
            <span>{{scope.row.deviceId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="physicalEncoding" label="物理编码">
          <template slot-scope="scope">
            <span>{{scope.row.physicalEncoding}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceStatus" label="设备状态">
          <template slot-scope="scope">
            <span>{{deviceStatus[scope.row.deviceStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="braceletOrderStatus" label="订单状态">
          <template slot-scope="scope">
            <span>{{orderStatus[scope.row.braceletOrderStatus]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="braceletOrderCode" label="订单">
          <template slot-scope="scope">
            <span>{{scope.row.braceletOrderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="braceletUserTelephone" label="手机">
          <template slot-scope="scope">
            <span>{{scope.row.braceletUserTelephone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="braceletUseTime" label="使用时间">
          <template slot-scope="scope">
            <span>{{scope.row.braceletUseTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button
                type="text"
                size="small"
                @click="showConfirmModal(scope.row)"
                v-if="scope.row.deviceStatus == 1 || scope.row.deviceStatus == 2 || scope.row.deviceStatus == 3"
              >{{scope.row.deviceStatus == 3 ? '初始化' : scope.row.deviceStatus == 1 ? '挂失' : '解除挂失'}}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isConfirmShow"
      width="420px"
      title="提示"
      center
      v-loading="dialogLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>{{currentData.deviceStatus == 3 ? '确认初始化操作' : currentData.deviceStatus == 1 ? '确认挂失操作' : '确认解除挂失操作'}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onConfirm()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';
export default {
  name: 'RentManage',
  data() {
    return {
      formData: {},
      isConfirmShow: false,
      dialogLoading: false,
      tableData: [],
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      statusList: [
        { label: '未使用', value: 0 },
        { label: '使用中', value: 1 },
        { label: '已挂失', value: 2 },
        { label: '未退还', value: 3 }
      ],
      orderStatus: constantValue.orderStatus,
      deviceStatus: constantValue.deviceStatus,
      currentData: {}
    };
  },
  created() {
    this.getRentList();
  },
  methods: {
    getRentList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/getBraceletLeaseByCriteria',
        params: this.pageConfig,
        data: this.searchCondition
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.pageConfig.pageNum = 1;
      this.getRentList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getRentList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getRentList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = { pageSize: 10, pageNum: 1 };
      this.searchCondition = {};
      this.getRentList();
    },
    showConfirmModal(data) {
      this.currentData = data;
      this.isConfirmShow = true;
    },
    onConfirm() {
      this.dialogLoading = true;
      let url = '';
      let method = 'put';
      if (this.currentData.deviceStatus == 1) {
        console.log(this.currentData.status)
        // 挂失
        url = `/bracelet/${this.currentData.id}/braceletLost`;
      } else if (this.currentData.status == 2) {
        // 解除挂失
        url = `/bracelet/${this.currentData.id}/braceletRmLost`;
      } else {
        // 初始化
        url = `/bracelet/${this.currentData.id}/braceletInit`;
        method = 'get';
      }
      this.$axios({
        method,
        url
      })
        .then(res => {
          this.dialogLoading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.isConfirmShow = false;
            this.getRentList();
          } else {
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          this.dialogLoading = false;
          console.log(e);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.RentManage {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
