<!--伞位订单列表-->
<template>
  <div class="umbrella">
    <el-card class="box-card">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="订单状态：">
          <el-select v-model="formInline.orderStatus" placeholder="请选择订单状态" clearable>
            <el-option :label="val" :value="key" v-for="(val, key) in orderStatus" :key="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号：">
          <el-input placeholder="请输入订单编号" v-model="formInline.orderCodeLike" clearable></el-input>
        </el-form-item>
        <el-form-item label="伞位编号：">
          <el-input placeholder="请输入伞位编号" v-model="formInline.leaseCodeLike" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input placeholder="请输入手机号" v-model="formInline.userTelephone" clearable></el-input>
        </el-form-item>
        <el-form-item label="日期筛选：">
          <el-date-picker
            v-model="formInline.date"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期时间"
            end-placeholder="结束日期时间"
            align="right"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="onReset">重置</el-button>
          
        </el-form-item>
          <el-form-item >
            <download-excel
              :data= "tableData"
              :fields = "json_fields"
              worksheet = "My Worksheet"
              :meta="json_meta"
              :beforeGenerate="beforeGenerate"
              name= "filename.xls">
           <el-button  type="primary" class="c-btn" @click="exportConfirmShow = true">导出</el-button>
          </download-excel>
          
        </el-form-item>

      </el-form>
    </el-card>
    <el-table class="c-table" v-loading="loading" :data="tableData">
      <el-table-column prop="name" label="订单编号" width="180px">
        <template slot-scope="scope">
          <span :title="scope.row.orderCode">{{scope.row.orderCode}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="会员手机号" width="120px">
        <template slot-scope="scope">
          <span :title="scope.row.userTelephone">{{scope.row.userTelephone}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="订单来源">
        <template slot-scope="scope">
          <span :title="scope.row.orderSource">{{orderSource[scope.row.orderSource]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="订单创建时间" width="200px">
        <template slot-scope="scope">
          <span :title="scope.row.createTime">{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="订单金额（￥）" align="right" width="160px">
        <template slot-scope="scope">
          <span :title="scope.row.totalPrice">{{scope.row.totalPrice | toMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="订单状态">
        <template slot-scope="scope">
          <span :title="scope.row.orderStatus">{{orderStatus[scope.row.orderStatus]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="实付金额（￥）" align="right" width="120px">
        <template slot-scope="scope">
          <span
            :title="scope.row.actualAmount"
          >{{scope.row.actualAmount === null ? '-' : toMoney(scope.row.actualAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="支付方式" align="center">
        <template slot-scope="scope">
          <span :title="scope.row.platformType">{{platformType[scope.row.platformType] || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template slot-scope="scope">
          <div class="operate">
            <el-button type="text" size="small" @click="toDetail(scope.row.id)">详情</el-button>
            <el-button
              type="text"
              size="small"
              @click="onConfirmDialogShow(scope.row.id)"
              v-if="scope.row.payType === 'part' && scope.row.partPayTime == null"
            >尾款确认</el-button>
            <el-button
              type="text"
              size="small"
              style="color:red"
              @click="onDialogShow(scope.row)"
              v-if="scope.row.orderStatus === 'PRE_USE' && (new Date(scope.row.useStartTime).getTime() > new Date().getTime() - 3600 * 1000 * 24)"
            >协商取消</el-button>
            <el-button
              type="text"
              size="small"
              @click="onNoPayReasonShow(scope.row)"
              v-if="scope.row.payType === 'part' && scope.row.partPayTime == null"
            >免单</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <yd-page
      :page-config="pageConfig"
      :size-change="handleSizeChange"
      :current-change="handleCurrentChange"
    ></yd-page>
    <!-- 协商取消弹窗 -->
    <el-dialog
      :visible.sync="cancelDialogVisible"
      width="50%"
      title="协商取消"
      center
      :close-on-click-modal="false"
    >
      <div class="content" v-loading="cancelDialogLoading">
        <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="150px">
          <el-form-item label="伞位：" prop="umbrella">
            <el-select v-model="formData.umbrella" placeholder="请选择" multiple>
              <el-option
                v-for="item in leaseOrderItemList"
                :key="item.id"
                :label="item.leaseCode"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退订形式：" prop="exitForm">
            <el-radio-group v-model="formData.exitForm">
              <el-radio :label="0">退款且退伞</el-radio>
              <el-radio :label="1">仅退伞不退款</el-radio>
              <el-radio :label="2">仅退款不退伞</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否通知用户：" prop="isNotice">
            <el-radio-group v-model="formData.isNotice">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="c-btn"
          @click="cancelDialogVisible = false"
          :disabled="cancelDialogLoading"
        >放弃操作</el-button>
        <el-button
          type="primary"
          class="c-btn"
          @click="cancelOrder()"
          :disabled="cancelDialogLoading"
        >确认协商取消</el-button>
      </span>
    </el-dialog>
    <!-- 支付尾款弹窗 -->
    <el-dialog
      :visible.sync="payDialogVisible"
      width="50%"
      title="请完善尾款信息后确认尾款支付"
      center
      :close-on-click-modal="false"
    >
      <div class="content" v-loading="payDialogLoading">
        <el-form
          :model="payformData"
          status-icon
          :rules="rules"
          ref="payRuleForm"
          label-width="150px"
        >
          <el-form-item label="支付方式：" prop="partPayType">
            <el-select v-model="payformData.partPayType" placeholder="请选择">
              <el-option
                v-for="item in payTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="尾款实付：" prop="partPayMoney">
            <el-input
              type="number"
              v-model="payformData.partPayMoney"
              placeholder="请输入"
              clearable
              @keydown.native="handleInput"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="payDialogVisible = false" :disabled="payDialogLoading">取消</el-button>
        <el-button
          type="primary"
          class="c-btn"
          @click="confirmTail()"
          :disabled="payDialogLoading"
        >确认已付</el-button>
      </span>
    </el-dialog>
    <!-- 免费理由弹窗 -->
    <el-dialog
      :visible.sync="noPayReasonShow"
      width="50%"
      title="免单理由"
      center
      :close-on-click-modal="false"
      v-loading="noPayLoading"
    >
      <el-form :model="reasonForm" status-icon :rules="reasonRules" ref="reasonForm" label-width="0px">
        <el-form-item prop="reason">
          <el-input
            v-model="reasonForm.reason"
            placeholder="请输入"
            clearable
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="c-btn"
          @click="submitFreeOrder"
        >提交</el-button>
      </span>
    </el-dialog>
    <!-- 确认导出弹窗 -->
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="exportConfirmShow"
      width="420px"
      title="提示"
      v-loading="exportLoading"
      center
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        请确认导出订单列表
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="exportConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="exportExcel">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { constantValue } from '../../plugins/constantValue';

export default {
  name: 'Umbrella',
  data() {
    return {
      // 确认导出弹窗显隐
      exportConfirmShow: false,
      exportLoading: false,
      // 控制列
      json_fields: {
            '订单编号': {
              field: 'orderCode',
              callback: (value) => `${value}&nbsp`
            },
            '手机号': 'userTelephone',
            '订单来源': {
                field: 'orderSource',
                callback: (value) => this.orderSource[value]
            },
            '创建时间': 'createTime',
            '订单金额(¥)': {
              field: 'totalPrice',
              callback: (value) => `${value}`
            },
            '订单状态': {
              field: 'orderStatus',
              callback: (value) => this.orderStatus[value]
            },
            '实付金额(¥)': {
              field: 'actualAmount',
              callback: (value) => `${value}`
            },
            '支付方式': {
              field: 'platformType',
              callback: (value) => this.platformType[value]
            }
        },
        json_data: [
            {
                'name': 'Tony Peña',
                'city': 'New York',
                'country': 'United States',
                'birthdate': '1978-03-15',
                'phone': {
                    'mobile': '1-541-754-3010',
                    'landline': '(541) 754-3010'
                }
            },
            {
                'name': 'Thessaloniki',
                'city': 'Athens',
                'country': 'Greece',
                'birthdate': '1987-11-23',
                'phone': {
                    'mobile': '+1 855 275 5071',
                    'landline': '(2741) 2621-244'
                }
            }
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ],
            [{ 'key': 'head', 'value': 'Raw Data' }] 
        ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      tableData: [{ id: 1 }],
      formInline: {},
      oldSearchCondition: {},
      pageConfig: {
        rows: 10,
        page: 1
      },
      orderStatus: constantValue.orderStatusSelect,
      orderSource: constantValue.orderSource,
      platformType: constantValue.platformType,
      loading: false,
      cancelDialogVisible: false,
      cancelDialogLoading: false,
      payDialogVisible: false,
      payDialogLoading: false,
      formData: { umbrella: [] },
      payformData: {},
      rules: {
        umbrella: [
          { required: true, message: '伞位不可为空！', trigger: 'blur' }
        ],
        isNotice: [
          { required: true, message: '请选择是否通知用户！', trigger: 'blur' }
        ],
        exitForm: [
          { required: true, message: '请选择退订形式！', trigger: 'blur' }
        ],
        partPayType: [
          { required: true, message: '支付方式不可为空！', trigger: 'blur' }
        ],
        partPayMoney: [
          { required: true, message: '尾款实付不可为空！', trigger: 'blur' }
        ]
      },
      currentId: -1,
      payTypeList: [
        { label: '现金', value: 'CASH' },
        { label: '微信', value: 'WECHAT' },
        { label: '支付宝', value: 'ALIPAY' },
        { label: '对公转帐', value: 'PUBLIC_TRANSFER' }
      ],
      leaseOrderItemList: [],
      noPayReasonShow: false,
      noPayReason: '',
      noPayLoading: false,
      reasonForm: {},
      reasonRules: {
        reason: [
          { required: true, message: '免单理由不能为空！', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getUmbrellaList();
  },
  methods: {
    beforeGenerate() {
      return false;
    },
    handleInput(e) {
      // 通过正则过滤小数点后两位
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
    },
    handleSizeChange(val) {
      this.pageConfig.page = 1;
      this.pageConfig.rows = val;
      this.getUmbrellaList();
    },
    handleCurrentChange(val) {
      this.pageConfig.page = val;
      this.getUmbrellaList();
    },
    toDetail(id) {
      this.$router.push('/orderDetail?id=' + id);
    },
    onQuery() {
      this.oldSearchCondition = { ...this.formInline };
      this.pageConfig.page = 1;
      this.getUmbrellaList();
    },
    // excel导出
    exportExcel() {
      this.formInline = { ...this.oldSearchCondition };
      let dateArr = this.formInline.date;
      if (dateArr) {
        this.formInline.useStartTimeStart = this.formatTime(
          dateArr[0],
          'YYYY-MM-DD HH:mm:ss'
        );
        this.formInline.useStartTimeEnd = this.formatTime(
          dateArr[1],
          'YYYY-MM-DD HH:mm:ss'
        );
      }
      this.exportLoading = true;
      this.$axios({
        method: 'post',
        url: '/leaseOrder4Web/umbrella/export',
        data: { ...this.formInline }
      })
      .then(response => {
        this.exportLoading = false;
        this.exportConfirmShow = false;
        console.log(response)
        // 下载
        // window.location.href
      })
      .catch(() => {
        this.exportLoading = false;
      })
    },
    // 获取订单列表
    getUmbrellaList() {
      this.formInline = { ...this.oldSearchCondition };
      let dateArr = this.formInline.date;
      if (dateArr) {
        this.formInline.useStartTimeStart = this.formatTime(
          dateArr[0],
          'YYYY-MM-DD HH:mm:ss'
        );
        this.formInline.useStartTimeEnd = this.formatTime(
          dateArr[1],
          'YYYY-MM-DD HH:mm:ss'
        );
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/leaseOrder4Web/umlist',
        data: { ...this.formInline, ...this.pageConfig }
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig(res);
            console.log('res', res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onReset() {
      this.formInline = {};
      this.pageConfig = {};
      this.oldSearchCondition = {};
      this.getUmbrellaList();
    },
    onDialogShow(data) {
      this.currentId = data.id;
      this.leaseOrderItemList = data.leaseOrderItemList
        ? data.leaseOrderItemList
        : [];
      this.cancelDialogVisible = true;
    },
    // 点击免单
    onNoPayReasonShow(data) {
      this.currentId = data.id;
      this.noPayReasonShow = true;
    },
    onConfirmDialogShow(id) {
      this.currentId = id;
      this.payDialogVisible = true;
    },
    // 免单提交
    submitFreeOrder() {
      let el = this.$refs.reasonForm;
      const checkValid = (valid) => {
        if (valid) {
          this.noPayLoading = true;
          this.$axios({
            method: 'post',
            url: `/leaseOrder4Web/confirm/free/${this.currentId}`,
            data: {reason: this.reasonForm.reason}
          })
          .then(res => {
            if (res.code === 200) {
              this.noPayLoading = false;
              this.noPayReasonShow = false;
              this.formData = {};
              this.getUmbrellaList();
            } else {
              // 请求出错
              this.noPayLoading = false;
              this.$showAlert(res.message, 'error');
            }
          })
          .catch(e => {
            this.noPayLoading = false;
          })
        }
      }
      el.validate(checkValid);
    },
    // 取消订单
    cancelOrder() {
      // 协议取消
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.cancelDialogLoading = true;
          const params = {
            sendMessage: this.formData.isNotice === 0,
            typeOfOrderCancel: Number(this.formData.exitForm),
            umbrellaId: this.formData.umbrella
          };
          this.$axios({
            method: 'put',
            url: `/leaseOrder4Web/${this.currentId}/cancelOrderByAdmin`,
            data: params
          })
            .then(res => {
              if (res.code === 200) {
                this.cancelDialogLoading = false;
                this.cancelDialogVisible = false;
                this.formData = {};
                this.getUmbrellaList();
              } else {
                // 请求出错
                this.cancelDialogLoading = false;
                this.$showAlert(res.message, 'error');
              }
            })
            .catch(e => {
              this.cancelDialogLoading = false;
              console.log(e);
            });
        }
      });
    },

    confirmTail() {
      // 确认尾款
      this.$refs.payRuleForm.validate(valid => {
        if (valid) {
          this.payDialogLoading = true;
          const params = {
            partPayType: this.payformData.partPayType,
            partPayMoney: Number(this.payformData.partPayMoney)
          };
          this.$axios({
            method: 'post',
            url: `/leaseOrder4Web/confirm/part/pay/${this.currentId}`,
            data: params
          })
            .then(res => {
              if (res.code === 200) {
                this.payDialogLoading = false;
                this.payDialogVisible = false;
                this.payformData = {};
                this.getUmbrellaList();
              } else {
                // 请求出错
                this.payDialogLoading = false;
                this.$showAlert(res.message, 'error');
              }
            })
            .catch(e => {
              this.payDialogLoading = false;
              console.log(e);
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.umbrella {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .box-card {
    margin-bottom: 20px;
  }
  .demo-form-inline {
    position: relative;
    .btn-div {
      position: absolute;
      right: 0;
    }
  }
}
</style>
