<template>
    <div class="block clearfix yd-page" id="ydPage">
        <el-pagination background
                       v-if="resultConfig.total > 0"
                       class="paging"
                       @size-change="sizeChange"
                       @current-change="currentChange"
                       :page-sizes="resultConfig.pageSizes"
                       :page-size="resultConfig.rows"
                       :layout="layout"
                       :current-page="resultConfig.page"
                       :total="resultConfig.total">
            <span class=".{} tip">共<span v-text="resultConfig.total"></span>条记录，每页显示条数</span>
        </el-pagination>
        <!-- <div class="jump">
            跳转到
          <span class="jump_edit">
              <input autocomplete="off" type="number" rows="2" max="1" min="1" validateevent="true" class="el-input__inner">
              <el-button class="yd_btn" @click="goPage">GO</el-button>
          </span>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: {
            sizeChange: {
                type: Function,
                default: function () {
                    return {}
                }
            },
            currentChange: {
                type: Function,
                default: function () {
                  
                }
            },
            pageConfig: {
                type: Object,
                default: function () {
                    return {}
                },
                request: true
            }
        },
        name: 'ydPage',
        data() {
            return {
            }
        },
        created: function () {
        },
        computed: {
            resultConfig() {
                const baseConfig = {
                    rows: 10,
                    page: 1,
                  // eslint-disable-next-line no-magic-numbers
                    pageSizes: [5, 10, 20, 50],
                    layout: 'slot,sizes, prev, pager, next, jumper'
                }
                return Object.assign(baseConfig, this.pageConfig)
            },
            layout() {
                if (this.pageConfig.layout) {
                    return this.pageConfig.layout
                } else {
                  // eslint-disable-next-line no-magic-numbers
                    if (Math.ceil(this.resultConfig.total / this.resultConfig.rows) < 5) {
                        return 'slot,sizes, prev, pager, next'
                    } else {
                        return 'slot,sizes, prev, pager, next,jumper'
                    }
                }
            }
        },
        methods: {
            goPage() {

            }
        }
    }
</script>
<style>
    .el-pagination span:not([class*=suffix]) {
        min-width: auto;
    }
</style>

<style lang="scss" scoped>
#ydPage.yd-page.block.clearfix {
        text-align: right;
        padding: 0 0 20px 0;
        margin: 20px 20px 0 0;
}
</style>


<style lang="scss">
    .yd-page.block.clearfix {
        // position: relative;
        // margin-top: 20px;
        // padding-bottom: 40px;
        // text-align: right;
        // padding: 0 0 20px 0;
        // margin: 0 20px 0 0;
        .el-pagination__sizes, .tip {
            float: left;
            color: rgba(103, 106, 108, 1);
            font-size: 12px;
            font-weight: normal
        }


        .tip{margin-left:20px}
        .paging.el-pagination.is-background {
            // margin-right:137px;
        }
        .jump {
            position: absolute;
            right: 0px;
            top: 0;
            font-size: 12px;
            color: rgba(145, 150, 153, 1);
            line-height: 32px;
            .jump_edit .el-input__inner {
                width: 28px;
                height: 28px;
                padding: 0;
                margin: 0 8px;
            }
            .yd_btn {
                width: 28px;
                height: 28px;
                padding: 0;
            }
            .yd_btn:active,
            .yd_btn:hover {
                border-color: #1AB394;
                color: #1AB394;
                background: #fff
            }
        }
    }
</style>
