<!--会员详情-->
<template>
  <div class="userDetail">
    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="150px"
             class="demo-ruleForm" v-loading="loading">
      <el-form-item label="昵称：">
        <span>{{ruleForm.nickName ? ruleForm.nickName : '-'}}</span>
      </el-form-item>
      <el-form-item label="头像：">
        <img :src="ruleForm.headimgurl" width="50px"  alt="暂无图片"/>
      </el-form-item>
      <el-form-item label="openid：">
        <span>{{ruleForm.openId ? ruleForm.openId : '-'}}</span>
      </el-form-item>
      <el-form-item label="关注公众号：">
        <span>{{ruleForm.subscribe === '0' ?'否' : '是'}}</span>
      </el-form-item>
      <el-form-item label="手机号：">
        <span>{{ruleForm.phone ? ruleForm.phone : '-'}}</span>
      </el-form-item>
      <el-form-item label="用户类型：">
        <span>{{ruleForm.userType ? typeListMap[ruleForm.userType] : '-'}}</span>
      </el-form-item>
      <el-form-item label="注册时间：">
        <span>{{ruleForm.registTime ? ruleForm.registTime : '-'}}</span>
      </el-form-item>
      <el-form-item label="最近登录：">
        <span>{{ruleForm.lastLoginTime ? ruleForm.lastLoginTime : '-'}}</span>
      </el-form-item>
      <el-form-item label="累积充值：">
        <span>¥ {{toDecimal2(ruleForm.chargeMoney)}}</span>
      </el-form-item>
      <el-form-item label="累积消费：">
        <span>¥ {{toDecimal2(ruleForm.payMoney)}}</span>
      </el-form-item>
      <el-form-item label="钱包余额：">
        <span>¥ {{toDecimal2(ruleForm.balance)}}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="c-btn" @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    components: {},
    name: 'userDetail',
    data() {
      return {
        ruleForm: {},
        id: '',
        typeListMap: {
          1: '普通用户',
          2: '会员用户',
          3: '贵宾用户'
        },
        loading: false
        }
    },
    created() {
      this.getUserdetail();
    },
    methods: {
      getUserdetail() {
        this.id = this.$route.query.id;
        this.loading = true;
        this.$axios({
          method: 'get',
          url: '/weixin/getUserDetail',
          params: {openId: this.id}
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            console.log('res:', res.data);
            this.ruleForm = res.data;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(() => {
          this.loading = false;
        })
      },
      goBack() {
        this.$router.push({path: '/userManage'})
      },
      // 不够强制补0
      toDecimal2(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f1 = Math.round(x * 100) / 100;
        var s = f1.toString();
        var rs = s.indexOf('.');
        if (rs < 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;

      }
    }
  }
</script>

<style scoped lang="scss">

</style>

