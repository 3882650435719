
















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import uploadImg from '@/components/common/uploadImg.vue';
import { deepCopy } from '@/plugins/util';

interface Res {
  code: number;
  data: any;
  message: string;
}

@Component({
  components: {
    uploadImg
  }
})
export default class UmbrellaDialog extends Vue {
  // data
  areaList: Array<any> = [
    { label: '东区', value: 0 },
    { label: '西区', value: 1 }
  ];
  ruleForm: any = {};
  loading: boolean = false;
  manageList: Array<any> = [];
  umbrellaSetting: Array<object> = [{ position: '' }];
  selectManage: Array<any> = [];
  fileList: Array<any> = [];
  orgSettingList: Array<any> = [];
  showError: string = '';
  rules: any = {
    logo: [{ validator: this.checkLogo, trigger: 'change' }],
    name: [
      { required: true, message: '请输入类型名称', trigger: 'blur' },
      { min: 2, max: 10, message: '长度在2到10位之间', trigger: 'blur' }
    ],
    manager: [
      {
        validator: this.checkManage,
        trigger: 'change'
      }
    ],
    introduce: [{ validator: this.checkIntroduce, trigger: 'change' }]
  };
  // prop
  @Prop() operateId!: number;
  @Prop(Boolean) isShowDialog!: boolean;
  // @Directives() color:{
  //
  // }
  // methods
  deleteCol(index: number) {
    this.umbrellaSetting.splice(index, 1);
    this.showError = '';
  }
  checkManage(rule: any, value: any, callback: any) {
    console.log(this.selectManage, 'select');
    if (this.selectManage.length) {
      callback();
    } else {
      callback(new Error('请选择伞位管理员!'));
    }
  }
  checkUmbrella(): boolean {
    let keyMap = new Map<string, string>();
    keyMap.set('sequence', '排号');
    keyMap.set('number', '可预约位置数');
    keyMap.set('workDayPrice', '周一到周四金额');
    keyMap.set('playDayPrice', '周五到周日金额');
    keyMap.set('holidayPrice', '节假日金额');
    // 判断名称是否有重复
    let hash: any = {};
    this.showError = '';
    for (let i in this.umbrellaSetting) {
      let item: any = this.umbrellaSetting[i];
      for (let j of keyMap.keys()) {
        if (
          item[j] === undefined ||
          item[j] === null ||
          item[j].toString().trim() === ''
        ) {
          this.showError = `请填写${keyMap.get(j)}！`;
          return false;
        }
      }
      if (hash[item.sequence]) {
        this.showError = '排号不许重复!';
        return false;
      } else {
        hash[item.sequence] = true;
      }
    }
    return true;
  }
  checkLogo(rule: any, value: any, callback: any) {
    console.log(arguments, 'arg');
    if (this.ruleForm.logo) {
      callback();
    } else {
      callback(new Error('请上传伞位类型logo!'));
    }
  }
  changeIntroduce(val: string) {
    let el: any = this.$refs.ruleForm;
    el.validateField('introduce');
  }
  checkIntroduce(rule: any, value: any, callback: any) {
    if (value) {
      callback();
    } else {
      callback(new Error('请填写伞位类型介绍!'));
    }
  }
  handleClose() {
    this.$emit('close');
  }

  uploadSuccess(fileUrl: any) {
    this.ruleForm.logo = fileUrl[0].url;
    let el: any = this.$refs.ruleForm;
    el.validateField('logo');
  }

  getManage() {
    this.$axios({
      url: '/managementAccount/getRoleAndUserList',
      method: 'get'
    }).then((res: Res) => {
      if (res.code === 200) {
        this.manageList = res.data;
        if (this.operateId) {
          this.getTypeDetail();
        }
      } else {
        this.$showAlert(res.message, 'error');
      }
    });
  }

  submitData() {
    // 校验
    let el: any = this.$refs.ruleForm;
    const result: boolean = this.checkUmbrella();
    const successCallback = (valid: any) => {
      if (valid && result) {
        let submitData: any = {};
        // 编辑数据需要处理数据
        let deleteArr: any[] = [];
        this.orgSettingList.forEach((row: any) => {
          const findIndex = this.umbrellaSetting.findIndex(
            (value: any) => value.id === row.id
          );
          if (findIndex === -1) {
            row.status = 0;
            deleteArr.push(row);
          }
        });
        console.log(deleteArr, 'arr');
        submitData.configVOList = this.umbrellaSetting.concat(deleteArr);
        submitData.configVOList.forEach((row: any, index: number) => {
          row.position = `第${index + 1}排`;
        });
        submitData = { ...this.ruleForm, ...submitData };
        submitData.manager = this.selectManage[1];
        const url: string = this.operateId
          ? '/umbrella/update'
          : '/umbrella/save';
        const method: string = this.operateId ? 'put' : 'post';
        this.loading = true;
        this.$axios({
          url: url,
          method: method,
          data: submitData
        })
          .then((res: any) => {
            this.loading = false;
            if (res.code === 200) {
              this.$showAlert('伞位信息已更新');
              this.$emit('successCallBack');
              this.handleClose();
            } else {
              this.$showAlert(res.message, 'error');
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    };
    el.validate(successCallback);
  }

  getTypeDetail() {
    this.$axios({
      url: '/umbrella/getUmbrella',
      method: 'get',
      params: { id: this.operateId }
    }).then((res: any) => {
      if (res.code === 200) {
        this.ruleForm = res.data;
        this.fileList = [
          {
            name: '',
            url: this.ruleForm.logo
          }
        ];
        this.umbrellaSetting = res.data.configVOList;
        this.orgSettingList = deepCopy(this.umbrellaSetting);
        // 回选管理员
        for (let i in this.manageList) {
          const itemList = this.manageList[i].userVOS || [];
          for (let j in itemList) {
            if (itemList[j].id === this.ruleForm.manager) {
              this.selectManage = [itemList[j].parentId, itemList[j].id];
              break;
            }
          }
        }
      } else {
        this.$showAlert(res.message, 'error');
      }
    });
  }

  // computed
  get computedMsg() {
    return 'computed';
  }

  // 生命周期
  created() {
    this.getManage();
  }
}
