







































































































































































































import { Vue, Component } from 'vue-property-decorator';
import UmbrellaDialog from './component/UmbrellaDialog.vue';
import ReservationDialog from './component/ReservationDialog.vue';
import { constantValue } from '@/plugins/constantValue';
import { deepCopy } from '@/plugins/util';

@Component({
  components: {
    UmbrellaDialog,
    ReservationDialog
  }
})
export default class UmbrellaManage extends Vue {
  // data
  activeName: string = 'first';
  isShowDialog: boolean = false;
  operateId: number = 0;
  umbrellaTypeData: Array<any> = [];
  checked: boolean = false;
  cardDataList: Array<any> = [];
  colorList: Array<any> = ['gray', 'green', 'blue', 'purple'];
  priceColorList: Array<any> = ['black', 'green', 'purple'];
  active: number = 0;
  activeValue: string = '';
  timeList: Array<any> = [];
  activeDate: number = 0;
  currentDate: Date = new Date();
  umbrellaStatus: Array<any> = constantValue.umbrellaStatus;
  isShowResDialog: boolean = false;
  nextDate: Date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  afterDate: Date = new Date(this.nextDate.getTime() + 24 * 60 * 60 * 1000);
  loading: boolean = false;
  introduce: string = '';
  isInBatch: boolean = false;
  injectData: any = {};
  protocolId: any;
  currentItem: any = {};
  isLockDialog: boolean = false;
  pickerOptions: object = {
    disabledDate(time: any) {
      return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
    }
  };

  // methods
  isUnLockConfirm(item: any) {
    this.currentItem = item;
    this.isLockDialog = true;
  }
  // 跳转到详情
  goUmbrellaDetail(item: any) {
    let date = this.getStringCurrentDate();
    this.$router.push({
      path: '/umbrellaDetail',
      query: { id: item.id, date: date }
    });
  }

  isCanSelected() {}

  // 锁定或者解锁
  lockUmbrellas() {
    this.loading = true;
    let url: string =
      this.selectedStatus === 0 ? '/umbrella/batchOpen' : '/umbrella/batchLock';
    this.$axios({
      method: 'put',
      url: url + '?ids=' + this.selectIds
    })
      .then((res: any) => {
        this.isLockDialog = false;
        this.isInBatch = false;
        this.loading = false;
        if (res.code === 200) {
          this.getUmbrellaData();
        } else {
          // 请求出错
          this.loading = false;
        }
      })
      .catch(() => {
        this.isLockDialog = false;
        this.loading = false;
      });
  }

  changeSelect() {
    this.$nextTick(() => {
      this.dealCardDataList();
    });
  }

  batchOperate() {
    this.dealCardDataList(true);
    this.isInBatch = !this.isInBatch;
  }

  // 伞位
  getUmbrellaData() {
    this.loading = true;
    this.$axios({
      method: 'get',
      url: '/umbrella/getInformations',
      params: { id: this.active, date: this.getStringCurrentDate() }
    })
      .then((res: any) => {
        this.loading = false;
        if (res.code === 200) {
          if(res.data != null){
            this.cardDataList = res.data;
            this.dealCardDataList(true);
          }
          else{
            this.cardDataList = new Array();
          }
        } else {
          // 请求出错
          this.loading = false;
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  // 伞位类型
  getUmbrellaType() {
    this.loading = true;
    this.$axios({
      method: 'get',
      url: '/umbrella/getType',
      params: { date: this.getStringCurrentDate() }
    })
      .then((res: any) => {
        if (res.code === 200) {
          this.umbrellaTypeData = res.data;
          if (this.umbrellaTypeData && this.umbrellaTypeData[0]) {
            this.active = this.active
              ? this.active
              : this.umbrellaTypeData[0] && this.umbrellaTypeData[0].id;
          } else {
            this.loading = false;
            return;
          }
          if (this.active) {
            this.getUmbrellaData();
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
          // 请求出错
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  // 切换伞位类型
  selectActive(item: any) {
    console.log('item伞位：', item);
    this.active = item.id;
    this.activeValue = item.name;
    this.getUmbrellaData();
  }

  // 保存租赁协议
  saveProtocol() {
    this.loading = true;
    this.$axios({
      url: '/apps/addWalletSet',
      method: 'post',
      data: {
        type: constantValue.protocolType.umbrella,
        agreeMent: this.introduce,
        id: this.protocolId
      }
    })
      .then((res: any) => {
        this.loading = false;
        if (res.code === 200) {
          this.getProtocol();
          this.$showAlert(res.message);
        } else {
          this.$showAlert(res.message, 'error');
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }

  init() {
    this.timeList = [
      {
        date: this.formatTime(new Date(), 'MM月DD日'),
        desc: '今天',
        backTime:
          '' +
          this.formatTime(
            this.formatTime(new Date(), 'YYYY-MM-DD'),
            'YYYY-MM-DD HH:mm:ss'
          )
      },
      {
        date: this.formatTime(this.nextDate, 'MM月DD日'),
        desc: '明天',
        backTime:
          '' +
          this.formatTime(
            this.formatTime(this.nextDate, 'YYYY-MM-DD'),
            'YYYY-MM-DD HH:mm:ss'
          )
      },
      {
        date: this.formatTime(this.afterDate, 'MM月DD日'),
        desc: '后天',
        backTime:
          '' +
          this.formatTime(
            this.formatTime(this.afterDate, 'YYYY-MM-DD'),
            'YYYY-MM-DD HH:mm:ss'
          )
      }
    ];
  }

  // 切换时间
  selectDate(index: number) {
    this.activeDate = index;
    this.currentDate = new Date(this.timeList[this.activeDate].backTime);
    this.getUmbrellaType();
  }

  // 日历选择变化
  onDatePickerChange(value: any) {
    let isExit = false;
    for (let i = 0; i < this.timeList.length; i += 1) {
      if (this.timeList[i].backTime === this.getStringCurrentDate()) {
        this.activeDate = i;
        isExit = true;
      }
    }
    if (!isExit) {
      this.activeDate = -1;
    }
    this.getUmbrellaType();
  }

  addOrEdit(id: number) {
    this.operateId = id;
    this.isShowDialog = true;
  }

  getStringCurrentDate() {
    return (
      '' +
      this.formatTime(
        this.formatTime(this.currentDate, 'YYYY-MM-DD'),
        'YYYY-MM-DD HH:mm:ss'
      )
    );
  }

  // 获取租赁协议
  getProtocol() {
    this.$axios({
      url: '/apps/getWalletSet',
      method: 'get',
      params: { type: constantValue.protocolType.umbrella }
    }).then((res: any) => {
      if (res.code === 200) {
        this.protocolId = res.data.id;
        this.introduce = res.data.agreeMent;
      } else {
        this.$showAlert(res.message, 'error');
      }
    });
  }

  addSubscribe(item: any) {
    this.isShowResDialog = true;
    const injectData = {
      selectedList: item ? [item] : this.selectedList,
      activeValue: this.activeValue
      ? this.activeValue
      : this.umbrellaTypeData[0] && this.umbrellaTypeData[0].name
    }
    this.injectData = deepCopy(injectData)
    // this.injectData.selectedList = item ? [item] : this.selectedList;
    // // this.injectData.currentDate = this.currentDate;
    // this.injectData.activeValue = this.activeValue
    //   ? this.activeValue
    //   : this.umbrellaTypeData[0] && this.umbrellaTypeData[0].name;
  }

  dealCardDataList(isInit: boolean = false) {
    this.cardDataList.forEach((row: any, index: number) => {
      row.umbrellaPositionInformationVOS.forEach((item: any) => {
        if (isInit) {
          item.isSelect = false;
        }
        if (item.status === 3 || item.status === 2) {
          item.disabled = true;
        } else if (this.selectedStatus > -1) {
          if (item.status !== this.selectedStatus) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        } else {
          item.disabled = false;
        }
        this.$set(this.cardDataList, index, row);
      });
    });
  }

  initHeight() {
    let winHeight: number = 0;
    let winWidth: number = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    winWidth = document.body.clientWidth;
    let elem = document.querySelector('#umbrella-container');
    let asideType: any = document.querySelector('#aside-type');
    let detailList: any = document.querySelector('#detail-list');
    asideType.style.height = winHeight - 350 + 'px';
    detailList.style.height = winHeight - 500 + 'px';
    detailList.style.width = winWidth - 680 + 'px';
    // this.$refs.umbrellaType.$el.style.height = (winHeight - 300) + 'px';
  }

  // computed
  get selectedStatus() {
    if (this.selectedList.length) {
      return this.selectedList[0] && this.selectedList[0].status;
    } else {
      return -1;
    }
  }

  get selectedList() {
    let selectArr = [];
    if (!this.isInBatch) {
      selectArr.push(this.currentItem);
    } else {
      for (let i in this.cardDataList) {
        const itemList =
          this.cardDataList[i].umbrellaPositionInformationVOS || [];
        for (let j in itemList) {
          if (itemList[j].isSelect) {
            selectArr.push(itemList[j]);
          }
        }
      }
    }
    return selectArr;
  }

  get selectIds() {
    let arr: number[] = [];
    this.selectedList.forEach(row => {
      arr.push(row.id);
    });
    return arr.join();
  }

  get openNumber() {
    let total = 0;
    this.cardDataList.forEach((row: any) => {
      total += row.openNumber;
    });
    return total;
  }

  get orderNumber() {
    let total = 0;
    this.cardDataList.forEach((row: any) => {
      total += row.orderNumber;
    });
    return total;
  }

  get useNumber() {
    let total = 0;
    this.cardDataList.forEach((row: any) => {
      total += row.useNumber;
    });
    return total;
  }

  get lockNumber() {
    let total = 0;
    this.cardDataList.forEach((row: any) => {
      total += row.lockNumber;
    });
    return total;
  }

  get allTotal() {
    return {
      openNumber: this.openNumber,
      orderNumber: this.orderNumber,
      useNumber: this.useNumber,
      lockNumber: this.lockNumber
    };
  }

  // 生命周期
  created() {
    this.activeDate = 0;
    this.init();
    this.getUmbrellaType();
    this.getProtocol();
  }

  mounted() {
    this.$nextTick(() => {
      this.initHeight();
    });
    window.addEventListener('resize', this.initHeight, false);
  }
}
