<!--房位管理-->
<template>
  <div class="RoomManage">
    <el-tabs v-model="activeName">
      <el-tab-pane label="房位管理" name="first">
        <RoomList/>
      </el-tab-pane>
      <el-tab-pane label="房位描述" name="second">
        <RoomDesc/>
      </el-tab-pane>
      <el-tab-pane label="租赁协议" name="third">
        <RoomProtocal/>
      </el-tab-pane>
      <el-tab-pane label="房型管理" name="fourth">
        <RoomType/>
      </el-tab-pane>
      <el-tab-pane label="预定规则" name="fivth">
        <ReservationRule/>
      </el-tab-pane>
      <el-tab-pane label="退款规则" name="sixth">
        <RefundRule/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RoomProtocal from './RoomProtocal';
import ReservationRule from './ReservationRule';
import RoomType from './RoomType';
import RefundRule from './RefundRule';
import RoomList from './RoomList';
import RoomDesc from './RoomDesc';
export default {
  name: 'RoomManage',
  data() {
    return {
      activeName: 'first'
    };
  },
  mounted() {},
  components: {
    RoomProtocal,
    ReservationRule,
    RoomType,
    RefundRule,
    RoomList,
    RoomDesc
  },
  methods: {}
};
</script>

<style lang="scss">
.RoomManage {
  .el-tabs__header {
    padding: 20px;
  }

  .el-tabs__nav {
    div {
      font-size: 18px;
    }
  }
}
</style>
