<!--储物柜租赁-->
<template>
  <div class="Locker">
    <el-card>
      <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="订单状态">
          <el-select v-model="formData.orderStatus" placeholder="请选择" :clearable="true">
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="formData.orderCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="格口编号">
          <el-input v-model="formData.grid" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="orderCode" label="订单编号" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.orderCode}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="用户手机号">
          <template slot-scope="scope">
            <span>{{scope.row.userTelephone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="储物区">
          <template slot-scope="scope">
            <span>{{scope.row.area}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="柜机编号">
          <template slot-scope="scope">
            <span>{{scope.row.cabinet}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grid" label="格口编号">
          <template slot-scope="scope">
            <span>{{scope.row.grid}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="开始时间" width="200px">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="使用时长">
          <template slot-scope="scope">
            <span>{{scope.row.duration}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="订单金额(¥)" align="right">
          <template slot-scope="scope">
            <span>{{scope.row.orderAmount | numFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="订单状态">
          <template slot-scope="scope">
            <span>{{scope.row.orderStatus == 'PRE_PAYMENT' ? '待支付' : scope.row.orderStatus == 'ALREADY_PAYMENT' ? '已支付' : scope.row.orderStatus == 'FORCE_PAYMENT' ? '待支付，已结单': ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="toDetail(scope.row.id)">详情</el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.payStatus == 'PRE_PAYMENT'"
                @click="showConfirmDialig(scope.row)"
              >强制结单</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isConfirmShow"
      width="420px"
      title="提示"
      center
      v-loading="confirmLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>是否进行强制结单操作</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isConfirmShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onConfirm()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Locker',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableData: [],
      isConfirmShow: false,
      confirmLoading: false,
      currentData: {},
      orderStatusList: [
        {
          label: '待支付',
          value: 'PRE_PAYMENT'
        },
        {
          label: '已支付',
          value: 'ALREADY_PAYMENT'
        },
        {
          label: '待支付，已结单',
          value: 'FORCE_PAYMENT'
        }
      ]
    };
  },
  created() {
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/locker/order',
        params: this.pageConfig,
        data: this.searchCondition
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig1(res);
          } else {
            // 请求出错
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getOrderList();
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.pageConfig.pageNum = 1;
      this.getOrderList();
    },
    onReset() {
      this.formData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.searchCondition = {};
      this.getOrderList();
    },
    showConfirmDialig(data) {
      this.currentData = { ...data };
      this.isConfirmShow = true;
    },

    // 强制结单
    onConfirm() {
      this.confirmLoading = true;
      this.$axios({
        method: 'put',
        url: `/locker/${this.currentData.id}/order`
      })
        .then(res => {
          this.confirmLoading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
            this.isConfirmShow = false;
            this.getOrderList();
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    toDetail(id) {
      this.$router.push({ path: '/lockerDetail', query: { id } });
    }
  }
};
</script>

<style scoped lang="scss">
.Locker {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
