<!--房位管理-->
<template>
  <div class="RoomList">
    <el-card>
      <el-form :inline="true" :model="queryData" class="form-inline" style="padding-right: 100px;">
        <el-form-item label="房号">
          <el-input v-model="queryData.no" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="房型">
          <el-select v-model="queryData.type" placeholder="请选择">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.type" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房名">
          <el-input v-model="queryData.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" size="mini" @click="onQuery">搜索</el-button>
          <el-button type="primary" class="c-btn" size="mini" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="c-btn" size="mini" @click="showAddModal()" style="margin-top: 10px;">新增</el-button>
    </el-card>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="cardNumber" label="房号">
          <template slot-scope="scope">
            <span>{{scope.row.roomNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNumber" label="房名">
          <template slot-scope="scope">
            <span>{{scope.row.roomName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNumber" label="状态">
          <template slot-scope="scope">
            <span>{{scope.row.roomStatus == 0 ? '待入住' : '已入住'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNumber" label="房型">
          <template slot-scope="scope">
            <span>{{scope.row.roomTypeMean}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNumber" label="面积">
          <template slot-scope="scope">
            <span>{{scope.row.roomArea}}m²</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNumber" label="床位数量">
          <template slot-scope="scope">
            <span>{{scope.row.bedNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" size="small" @click="showAddModal(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="showDeleteModal(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
    <el-dialog
      class="lockConfirmDialog"
      :visible.sync="isDeleteShow"
      width="420px"
      title="提示"
      center
      v-loading="deleteLoading"
      :close-on-click-modal="false"
    >
      <div style="text-align: center;font-size: 16px;">
        <p>确认删除当前房型</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isDeleteShow = false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="onDelete()">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="c-dialog"
      :title="type != 1 ? '编辑房型' : '新增房型'"
      :visible.sync="isAddShow"
      width="50%"
      :close-on-click-modal="false"
      v-loading="addLoading"
    >
      <div class="content">
        <el-form :model="formData" status-icon :rules="rules" ref="formData" label-width="100px">
          <el-form-item label="房号：" prop="roomNumber">
            <el-input v-model="formData.roomNumber" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="房名：" prop="roomName">
            <el-input v-model="formData.roomName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="价格：" prop="roomPrice">
            <el-input v-model="formData.roomPrice" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="房型：" prop="roomType">
            <el-select v-model="formData.roomType" placeholder="请选择">
              <el-option
                v-for="(item,index) in typeList"
                :key="index"
                :label="item.type"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="面积：" prop="roomArea">
            <el-input v-model="formData.roomArea" placeholder="请输入" type="number" v-enter-number></el-input>
          </el-form-item>
          <el-form-item label="床位：" prop="bedNum">
            <el-input v-model="formData.bedNum" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="床位尺寸：" prop="bedSize">
            <el-input v-model="formData.bedSize" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="基础设施：">
            <el-checkbox-group v-model="formData.base">
              <el-checkbox :label="item.id" v-for="(item, index) in facilityList.base" :key="index">{{item.facility}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="卫浴设施：">
            <el-checkbox-group v-model="formData.bath">
              <el-checkbox :label="item.id" v-for="(item, index) in facilityList.bath" :key="index">{{item.facility}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="厨房设施：">
            <el-checkbox-group v-model="formData.kitchen">
              <el-checkbox :label="item.id" v-for="(item, index) in facilityList.kitchen" :key="index">{{item.facility}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="安全设施：">
            <el-checkbox-group v-model="formData.safe">
              <el-checkbox :label="item.id" v-for="(item, index) in facilityList.safe" :key="index">{{item.facility}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="c-btn" @click="isAddShow=false">取 消</el-button>
        <el-button type="primary" class="c-btn" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RoomList',
  data() {
    return {
      loading: false,
      deleteId: 0,
      type: 1,
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      facilityList: {
        base: [],
        bath: [],
        kitchen: [],
        safe: [],
        recreation: [],
        around500: []
      },
      typeList: [],
      formData: {
        roomNumber: '',
        roomName: '',
        roomPrice: '',
        roomType: '',
        roomArea: '',
        bedNum: '',
        bedSize: '',
        base: [],
        bath: [],
        kitchen: [],
        safe: []
      },
      queryData: {
        no: '',
        type: '',
        name: ''
      },
      tableData: [],
      isDeleteShow: false,
      isAddShow: false,
      deleteLoading: false,
      addLoading: false,
      rules: {
        roomNumber: [{ required: true, message: '房号不可为空！', trigger: 'blur' }],
        roomName: [{ required: true, message: '房名不可为空！', trigger: 'blur' }],
        roomPrice: [{ required: true, message: '价格不可为空！', trigger: 'blur' }],
        roomType: [{ required: true, message: '房型不可为空！', trigger: 'blur' }],
        roomArea: [{ required: true, message: '面积不可为空！', trigger: 'blur' }],
        roomSize: [{ required: true, message: '尺寸不可为空！', trigger: 'blur' }],
        bedNum: [{ required: true, message: '床位不可为空！', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.getFacility();
    this.getType();
    this.getList();
  },
  methods: {
    getFacility() {
      this.$axios({
        method: 'get',
        url: '/room/facility/all'
      }).then(res => {
        if (res.code === 200) {
          this.facilityList = res.data;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    getType() {
      this.$axios({
        method: 'get',
        url: '/room/type'
      }).then(res => {
        if (res.code === 200) {
          this.typeList = res.data;
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      this.getList();
    },
    onQuery() {
      this.pageConfig.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.queryData = {};
      this.pageConfig = {
        pageSize: 10,
        pageNum: 1
      };
      this.getList();
    },
    showDeleteModal(data) {
      this.deleteId = data.id;
      this.isDeleteShow = true;
    },
    onDelete() {
      this.delRoom();
    },
    showAddModal(data) {
      if (data != null) {
        this.formData = {...data};
        this.type = 2;
      } else {
        this.formData = {base: [], bath: [], kitchen: [], safe: []};
        this.type = 1;
      }
      this.isAddShow = true;
    },
    submitData() {
      if (this.type = 2) {
        this.editRoom();
      } else {
        this.addRoom();
      }
    },
    getList() {
      this.$axios({
        method: 'post',
        url: '/room/list',
        params: this.pageConfig,
        data: {
          roomNumber: this.queryData.no,
          roomType: this.queryData.type,
          roomName: this.queryData.name
        }
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.pageConfig = this.$getPageConfig1(res);
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    addRoom() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'post',
            url: '/room',
            data: this.formData
          }).then(res => {
            if (res.code === 200) {
              this.$showAlert('新增房位成功', 'success');
              this.isAddShow = false;
              this.formData = {};
              this.getList();
            } else {
              this.$showAlert(res.message, 'error');
            }
          }).catch(e => {
            this.$showAlert(e.message, 'error');
          })
        } else {
          this.$showAlert('信息没有填写完整', 'error');
        }
      })
    },
    editRoom() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'put',
            url: '/room',
            data: this.formData
          }).then(res => {
            if (res.code === 200) {
              this.$showAlert('修改成功', 'success');
              this.getList();
              this.isAddShow = false;
            } else {
              this.$showAlert(res.message, 'error');
            }
          }).catch(e => {
            this.$showAlert(e.message, 'error');
          })
        }
      })
    },
    delRoom() {
      this.$axios({
        method: 'delete',
        url: '/room',
        params: {
          id: this.deleteId
        }
      }).then(res => {
        this.isDeleteShow = false;
        if (res.code === 200) {
          this.$showAlert('删除成功', 'success');
          this.getList();
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
  }
};
</script>

<style scoped lang="scss">
.RoomList {
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
