<!--伞位分布图(大屏)-->
<template>
  <div class="umbrella-map" v-loading="loading">
    <div class="umbrella">
      <div class="left">
        <div class="left-arch">
          <div v-for="(item, index) in data.eastUmbrellaList" :key="index" class="arch-item">
            <img style="width:50px;height:28px;"
              v-for="(archItem, archIndex) in item.umbrellaPositionInformationVOS ? item.umbrellaPositionInformationVOS : []"
              :key="archIndex"
              :src="archItem.status === 0 ? archDisabledImg : archItem.status === 1 ? archSelectImg: archItem.status === 2 ? archSelectedImg : archConfirmImg"
            >
          </div>
        </div>
        <div>
          <div v-for="(item, index) in data.eastIndianList" :key="index">
            <img style="width:50px;height:28px;"
              v-for="(indianItem, indianIndex) in item.umbrellaPositionInformationVOS ? item.umbrellaPositionInformationVOS : []"
              :key="indianIndex"
              :src="indianItem.status === 0 ? indianDisabledImg : indianItem.status === 1 ? indianSelectImg: indianItem.status === 2 ? indianSelectedImg : indianComfirmImg"
            >
          </div>
        </div>
      </div>
      <div class="center">
        <div>
          <div v-for="(item, index) in data.centerArchList" :key="index" class="center-row">
            <img style="width:50px;height:28px;"
              v-for="(umbrellaItem, umbrellaIndex) in item.umbrellaPositionInformationVOS ? item.umbrellaPositionInformationVOS : []"
              :key="umbrellaIndex"
              :src="umbrellaItem.status === 0 ? umbrellaDisabledImg : umbrellaItem.status === 1 ? umbrellaSelectImg: umbrellaItem.status === 2 ? umbrellaSelectedImg : umbrellaConfirmImg"
            >
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <div v-for="(item, index) in data.weatUmbrellaList" :key="index" class="right-row">
            <img style="width:50px;height:28px;"
              v-for="(archItem, archIndex) in item.umbrellaPositionInformationVOS ? item.umbrellaPositionInformationVOS : []"
              :key="archIndex"
              :src="archItem.status === 0 ? archDisabledImg : archItem.status === 1 ? archSelectImg: archItem.status === 2 ? archSelectedImg : archConfirmImg"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mark">
      <div>
        <div class="mark-div">
          <div class="item-square" style="background: #82E7B0;"></div>
          <span class="item-text">可预约</span>
        </div>
        <div class="mark-div">
          <div class="item-square" style="background: #67A5F8;"></div>
          <span class="item-text">已预约</span>
        </div>
        <div class="mark-div">
          <div class="item-square" style="background: #EE795A;"></div>
          <span class="item-text">已使用</span>
        </div>
        <div class="mark-div">
          <div class="item-square" style="background: #B4B4B4;"></div>
          <span class="item-text">不可预约</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import archDisabledImg from '@/assets/img/map/arch_disabled.png';
import archSelectImg from '@/assets/img/map/arch_select.png';
import archSelectedImg from '@/assets/img/map/arch_selected.png';
import archConfirmImg from '@/assets/img/map/arch_confirm.png';
import indianDisabledImg from '@/assets/img/map/indian_disabled.png';
import indianSelectImg from '@/assets/img/map/indian_select.png';
import indianSelectedImg from '@/assets/img/map/indian_selected.png';
import indianComfirmImg from '@/assets/img/map/indian_confirm.png';
import umbrellaDisabledImg from '@/assets/img/map/umbrella_disabled.png';
import umbrellaSelectImg from '@/assets/img/map/umbrella_select.png';
import umbrellaSelectedImg from '@/assets/img/map/umbrella_selected.png';
import umbrellaConfirmImg from '@/assets/img/map/umbrella_confirm.png';
import { setCookie } from '@/plugins/util';
export default {
  name: 'UmbrellaMapPage',
  data() {
    return {
      loading: false,
      data: {
        eastUmbrellaList: [],
        eastIndianList: [],
        centerArchList: [],
        weatUmbrellaList: []
      },
      archDisabledImg: archDisabledImg,
      archSelectImg: archSelectImg,
      archSelectedImg: archSelectedImg,
      indianDisabledImg: indianDisabledImg,
      indianSelectImg: indianSelectImg,
      indianSelectedImg: indianSelectedImg,
      umbrellaDisabledImg: umbrellaDisabledImg,
      umbrellaSelectImg: umbrellaSelectImg,
      umbrellaSelectedImg: umbrellaSelectedImg
    };
  },

  mounted() {
    setCookie('path', '/screen');
    this.loading = true;
    this.getUmbrellaList();
    this.timer = setInterval(() => {
      this.getUmbrellaList();
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    getUmbrellaList() {
      this.$axios({
        method: 'get',
        url: '/umbrella/map/cat'
      })
        .then(res => {
          if (res.code === 200) {
            for (const item of res.data) {
              if (item.id === 8) {
                this.data.eastUmbrellaList = item.configVOList
                  ? item.configVOList
                  : [];
              } else if (item.id === 11) {
                this.data.eastIndianList = item.configVOList
                  ? item.configVOList
                  : [];
              } else if (item.id === 9) {
                this.data.weatUmbrellaList = item.configVOList
                  ? item.configVOList
                  : [];
              } else if (item.id === 10) {
                this.data.centerArchList = item.configVOList
                  ? item.configVOList
                  : [];
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            // 请求出错
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss">
.umbrella-map {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/map/umbrella_map_bg.png");
  background-repeat:no-repeat ;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  .umbrella {
    width: 100%;
    margin-top:900px;
    display: flex;
    flex-direction: row;

    .left {
      width: 36%;
      height: 380px;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .left-arch {
        margin-bottom: 0px;

        .arch-item {
          margin-bottom: 6px;
        }
      }
    }

    .center {
      min-width: 24%;
      height: 500px;
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .center-row {
        margin-bottom: 12px;
      }
    }

    .right {
      flex: 1;
      height: 370px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .right-row {
        margin-bottom: 6px;
      }
    }
  }
  .mark {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    padding-bottom: 16px;

    .mark-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 6px;

      .item-square {
        width: 40px;
        height: 40px;
        border: 2px white solid;
        border-radius: 10px;
        margin-right: 20px;
      }

      .item-text {
        font-size:32px;
        color: #9f824b;
      }
    }
  }
}
</style>
