import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuActive: [],
    isCollapse: false,
    userInfo: {},
    menuAndPermission: []
  },
  mutations: {
    setMenuActive(state, value) {
      state.menuActive = value;
    },
    setIsCollapse(state, value) {
      state.isCollapse = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setMenuAndPermission(state, value) {
      state.menuAndPermission = value;
    }
  },
  actions: {}
});
