<!--储物柜订单详情-->
<template>
  <div class="LockerDetail order-common" v-loading="loading">
    <div class="title-box">
      <div class="icon"></div>
      <div class="title">订单编号：{{orderDetailObj.orderCode}}</div>
      <div
        class="operate"
      >{{orderDetailObj.payStatus ? orderDetailObj.payStatus == 'PRE_PAYMENT' ? '待支付' : orderDetailObj.payStatus == 'ALREADY_PAYMENT' ? '已支付': '待支付，已结单' : '-'}}</div>
    </div>
    <div class="main-box">
      <div class="head">租赁信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">用户手机号：</div>
          <div class="value">{{orderDetailObj.userTelephone ? orderDetailObj.userTelephone : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">储物区：</div>
          <div class="value">{{orderDetailObj.area ? orderDetailObj.area : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">柜机编号：</div>
          <div class="value">{{orderDetailObj.cabinet ? orderDetailObj.cabinet : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">格口编号：</div>
          <div class="value">{{orderDetailObj.grid ? orderDetailObj.grid : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">开始时间：</div>
          <div class="value">{{orderDetailObj.createTime ? orderDetailObj.createTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">使用时长：</div>
          <div class="value">{{orderDetailObj.duration ? orderDetailObj.duration : '-'}}</div>
        </div>
        <div class="item" style="font-weight: bold;">
          <div class="label" style="color: black;">订单金额：</div>
          <div
            class="value"
            style="color: #e74c3c;font-size: 18px;"
          >{{orderDetailObj.orderAmount ? '¥' + (orderDetailObj.orderAmount | numFormat) : '-'}}</div>
        </div>
      </div>
    </div>
    <div class="main-box">
      <div class="head">订单信息</div>
      <div class="item-list">
        <div class="item">
          <div class="label">订单编号：</div>
          <div class="value">{{orderDetailObj.orderCode ? orderDetailObj.orderCode : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">创建时间：</div>
          <div class="value">{{orderDetailObj.createTime ? orderDetailObj.createTime : '-'}}</div>
        </div>
        <div class="item">
          <div class="label">订单状态：</div>
          <div
            class="value"
          >{{orderDetailObj.payStatus ? orderDetailObj.payStatus == 'PRE_PAYMENT' ? '待支付' : orderDetailObj.payStatus == 'ALREADY_PAYMENT' ? '已支付': '待支付，已结单' : '-'}}</div>
        </div>
        <div class="item" v-if="orderDetailObj.payStatus == 'ALREADY_PAYMENT'">
          <div class="label">支付方式：</div>
          <div
            class="value"
          >{{orderDetailObj.payMethod ? platformType[orderDetailObj.payMethod] : '-'}}</div>
        </div>
        <div class="item" v-if="orderDetailObj.payStatus == 'ALREADY_PAYMENT'">
          <div class="label">成交时间：</div>
          <div class="value">{{orderDetailObj.payTime ? orderDetailObj.payTime : '-'}}</div>
        </div>

        <div class="item" v-if="orderDetailObj.payStatus == 'ALREADY_PAYMENT'">
          <div class="label">支付交易号：</div>
          <div class="value">{{orderDetailObj.tradingNumber ? orderDetailObj.tradingNumber : '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';

export default {
  name: 'LockerDetail',
  data() {
    return {
      loading: false,
      orderDetailObj: {},
      platformType: constantValue.platformType
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      this.loading = true;
      let id = this.$route.query.id;
      this.$axios({
        method: 'get',
        url: '/locker/details/order',
        params: { id }
      })
        .then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.orderDetailObj = res.data ? res.data : {};
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";

.LockerDetail {
  width: 800px;
  margin: 0 auto;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
