<!--布局文件，菜单和路由所在-->
<template>
  <el-container class="main-container">
    <el-aside :width="isCollapse ? '70px' : '220px'" class="active">
      <el-scrollbar class="menu-scrollbar">
        <div class="left-menu">
          <div class="logo">
            <img src="../assets/img/layout/logo.png" alt="塔河湾logo">
            <span v-if="!isCollapse">塔河湾</span>
          </div>
          <menuComponent></menuComponent>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container>
        <el-header>
          <layout-header></layout-header>
        </el-header>
        <el-main>
          <el-scrollbar class="content-scrollbar">
            <div class="right-container">
              <transition enter-active-class="pageFadeInRight">
                <router-view></router-view>
              </transition>
            </div>
          </el-scrollbar>
        </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import menuComponent from '../components/layout/MenuComponent.vue'
  import layoutHeader from '../components/layout/LayoutHeader'

  export default {
    data() {
      return {}
    },
    computed: {
      isCollapse() {
        return this.$store.state.isCollapse
      }
    },
    beforeRouteEnter (to, from, next) {
      // 在渲染该组件的对应路由被 confirm 前调用
      // 不！能！获取组件实例 `this`
      // 因为当钩子执行前，组件实例还没被创建
      console.log(to, 'to')
      next()
    },
    name: 'Layout',
    components: {menuComponent, layoutHeader}
  }
</script>
<style lang="scss">
  .page-view{
    width: 100%;
    height: 100%;
    transition: all .5s cubic-bezier(.55,0,.1,1);
  }
  .menu-scrollbar.el-scrollbar{
    height: 100%;
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  .content-scrollbar.el-scrollbar{
    width: 100%;
    height: 100%;
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .right-container{
      padding: 20px;
    }
  }
</style>

<style scoped lang="scss">
  .main-container {
    .el-main {
      padding: 0;
      flex: 0 0 calc(100% - 60px);
      flex-direction: column;
    }
    .active {
      transition: width 0.7s;
    }
    .el-header {
      border-bottom: 1px solid #eee;
    }
    .el-aside {
      background: #1c2d3b;
    }

    .left-menu {
      .logo {
        background: #1c2d3b;
        overflow: hidden;
        word-break: keep-all;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 56px;
          height: 56px;
          vertical-align: middle;
        }
        span {
          color: #2db8c5;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

</style>
