<!--手环绑定-->
<template>
  <div class="WristbandBind order-common" v-loading="loading">
    <div class="title-box">
      <div class="title">手环绑定</div>
    </div>
    <!-- <div class="read-card">
      <div style="margin-right: 15px;">请选择读卡器：</div>
      <div style="flex: 1;">
        <el-radio-group v-model="currentCardId">
          <el-radio
            :label="3"
            style="margin-bottom:5px"
            v-for="(cardItem, index) in 4"
            :key="index"
          >备选项</el-radio>
        </el-radio-group>
      </div>
    </div>-->
    <!-- <div class="line"></div> -->
    <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
      <el-form-item label="订单编号:">
        <el-input v-model="formData.orderCode" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="c-btn" size="mini" @click="onQuery()">检索</el-button>
      </el-form-item>
    </el-form>
    <el-card v-if="orderData.id != null">
      <div class="desc-div">
        <div class="item-div">
          <span class="item-label">创建时间:</span>
          <span>{{orderData.createTime ? orderData.createTime : '-'}}</span>
        </div>
        <div class="item-div">
          <span class="item-label">订单编号:</span>
          <span>{{orderData.orderCode ? orderData.orderCode : '-'}}</span>
        </div>
        <div class="item-div">
          <span class="item-label">手机号:</span>
          <span>{{orderData.userTelephone ? orderData.userTelephone : '-'}}</span>
        </div>
        <div class="status">{{orderData.orderStatus ? orderStatus[orderData.orderStatus] : '-'}}</div>
        <div class="item-div">
          <span class="item-label">商品数量:</span>
          <span>{{orderData.braceletOrderItemList ? orderData.braceletOrderItemList.length : '-'}}</span>
        </div>
        <div class="item-div">
          <span class="item-label">用户姓名:</span>
          <span>{{orderData.userName ? orderData.userName : '-'}}</span>
        </div>
      </div>
      <div class="card-table">
        <el-table
          class="c-table"
          :data="orderData.braceletOrderItemList"
          style="width: 100%"
          empty-text="当前订单不可更新绑定设备..."
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="cardNumber" label="商品单号">
            <template slot-scope="scope">
              <span>{{scope.row.braceletCommodityNumber}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="physicalEncoding" label="物理编码">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.physicalEncoding"
                placeholder="请刷卡"
                clearable
                size="mini"
                @blur="blurFillBraceletInfo"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="braceletLeaseId" label="设备编号">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.braceletLeaseId"
                placeholder=""
                clearable
                :disabled="true"
                size="mini"
                type="number"
                v-enter-number
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="checked" label="是否绑定">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <div class="bottom-btn">
      <!-- <el-button
        type="primary"
        class="c-btn"
        @click="cancelAll()"
        :disabled="orderData.orderStatus != 'PRE_USE'"
      >全部取消</el-button> -->
      <el-button
        type="primary"
        class="c-btn"
        @click="confirmBind()"
        :disabled="orderData.orderStatus != 'PRE_BIND'"
      >确定绑定</el-button>
      <el-button class="c-btn" @click="goBack()">返回</el-button>
    </div>
  </div>
</template>

<script>
import { constantValue } from '@/plugins/constantValue';
export default {
  name: 'WristbandBind',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      // currentCardId: "",
      orderData: { braceletOrderItemList: [] },
      orderStatus: constantValue.orderStatus
    };
  },
  created() {},
  methods: {
    getOrderDetail() {
      if (
        this.searchCondition.orderCode === null ||
        this.searchCondition.orderCode === ''
      ) {
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/bracelet/getBraceletCode',
        params: this.searchCondition
      })
        .then(res => {
          if (res.code === 200) {
            this.orderData = res.data ? res.data : {};
            //待绑定状态
            if(this.orderData.orderStatus === 'PRE_BIND'){
              //赋值订单手环列表
              this.orderData.braceletOrderItemList = this.orderData
                .braceletOrderItemList
                ? this.orderData.braceletOrderItemList
                : [];
            }
            // if (this.orderData.orderStatus !== 'PRE_USE') {
            //   this.orderData.braceletOrderItemList = [];
            // } else {
            //   this.orderData.braceletOrderItemList = this.orderData
            //     .braceletOrderItemList
            //     ? this.orderData.braceletOrderItemList
            //     : [];
            //   let hasUnuse = false;
            //   for (const item of this.orderData.braceletOrderItemList) {
            //     // item.checked = false;
            //     item.deviceStatus = item.deviceStatus ? item.deviceStatus : 0;
            //     if (item.braceletLeaseStatus === 1) {
            //       hasUnuse = true;
            //     }
            //   }
            //   if (!hasUnuse) {
            //     this.orderData.braceletOrderItemList = [];
            //   }
            // }

            this.loading = false;
          } else {
            // 请求出错
            this.loading = false;
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    cancelAll() {
      for (const item of this.orderData.braceletOrderItemList) {
        item.checked = false;
      }
    },
    blurFillBraceletInfo(e){
      var pe = e.target.value;
      console.log("手环物理编号-->"+pe);
      this.$axios({
        method: 'get',
        url: '/bracelet/getBraceletDeviceCode?pe='+pe
      })
      .then(response =>{
        if(response.code == 200){
          for (const item of this.orderData.braceletOrderItemList) {
              if (item.physicalEncoding === pe) {
                item.braceletLeaseId = response.data.deviceId;
                break;
              }
          }
        }
        else{
          this.$message.warning(response.message);
        }
      });
    },
    confirmBind() {
      const bindList = [];
      for (const item of this.orderData.braceletOrderItemList) {
        if (item.checked) {
          if (item.physicalEncoding === null || item.physicalEncoding === '') {
            this.$message.warning('请输入物理编码');
            return;
          }
          if (item.braceletLeaseId === null || item.braceletLeaseId === '') {
            this.$message.warning('请输入设备编号');
            return;
          }
          bindList.push(item);
        }
      }
      if (bindList.length === 0) {
        this.$message.warning('请选择设备');
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/braceletBind',
        data: { orderCode: this.formData.orderCode, items: bindList }
      })
        .then(res => {
          if (res.code === 200) {
            this.getOrderDetail();
            this.$showAlert(res.message);
          } else {
            // 请求出错
            this.loading = false;
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.getOrderDetail();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";

.WristbandBind {
  flex: 1;
  margin-left: 50px;
  margin-right: 50px;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .read-card {
    display: flex;
    flex-direction: row;
    color: #676a6c;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .line {
    height: 1px;
    background: #676a6c;
    flex: 1;
    margin-bottom: 20px;
  }

  .bottom-btn {
    margin-top: 20px;
    padding-bottom: 20px;
    border: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .desc-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .item-div {
      display: flex;
      flex-direction: row;
      width: 30%;
      color: #676a6c;
      font-size: 14px;

      .item-label {
        margin-right: 8px;
      }
    }

    .status {
      width: 10%;
      font-size: 16px;
      margin-bottom: 10px;
      color: #e74c3c;
    }
  }
}
</style>
