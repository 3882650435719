<!--员工管理-->
<template>
  <div class="accountManage">
    <el-card class="box-card">
      <el-form :inline="true" :model="formData" class="demo-form-inline" style="padding-right: 100px;">
        <el-form-item label="姓名">
          <el-input v-model="formData.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.telephone" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="formData.roleId" placeholder="请选择" clearable>
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in roleLists" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="c-btn" @click="onQuery" size="mini">搜索</el-button>
          <el-button type="primary" class="c-btn" @click="onReset" size="mini">重置</el-button>
        </el-form-item>
        <el-form-item class="add-account">
          <el-button type="primary" class="c-btn" @click="openAccount('add')" size="mini">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="role-table">
      <el-table
        v-loading="loading"
        class="c-table"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
          <template slot-scope="scope">
            <span :title="scope.row.name">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="账号">
          <template slot-scope="scope">
            <span :title="scope.row.username">{{scope.row.username}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="手机号">
          <template slot-scope="scope">
            <span :title="scope.row.telephone">{{scope.row.telephone}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="添加时间">
          <template slot-scope="scope">
            <span :title="scope.row.createTime">{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色">
          <template slot-scope="scope">
            <span :title="scope.row.roleName">{{scope.row.roleName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span :title="scope.row.status">{{scope.row.status === 0 ? '禁用' : '启用'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <div class="operate ">
              <el-button type="text" size="small" @click="openAccount('edit',scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="changeStatus(scope.row)"
                         :class="{'red': scope.row.status === 1}">
                {{scope.row.status === 0 ? '启用' : '禁用'}}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <yd-page :page-config="pageConfig" :size-change="handleSizeChange" :current-change="handleCurrentChange"></yd-page>
    <accountDialog @close="isShowDialog = false" v-if="isShowDialog" :injectData="injectData"
                   :is-show-dialog="isShowDialog"></accountDialog>

  </div>
</template>

<script>
  import accountDialog from './dialog/accountDialog.vue'

  export default {
    name: 'accountManage',
    components: {
      accountDialog
    },
    data() {
      return {
        modal: null,
        injectData: {},
        isShowDialog: false,
        formData: {
          name: '',
          telephone: '',
          roleId: ''
        },
        tableData: [],
        roleLists: [],
        pageConfig: {
          rows: 10
        },
        loading: false,
        oldSearchCondition: {}
      }
    },
    created() {
      this.getUserManageList();
      this.getRoleList();
    },
    methods: {
      // 查询
      onQuery() {
        this.pageConfig.page = 1;
        this.oldSearchCondition = {...this.formData}
        this.getUserManageList();
      },
      handleSizeChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.rows = val;
        this.getUserManageList()
      },
      handleCurrentChange(val) {
        this.pageConfig.page = val
        this.getUserManageList()
      },
      // 打开对话框
      openAccount(type, item) {
        if (type === 'add') {
          this.injectData = {};
          this.injectData.type = 'add';
        } else if (type === 'edit') {
          this.injectData.id = item.id;
          this.injectData.roleName = item.roleName;
          this.injectData.type = 'edit';
        }
        this.isShowDialog = true
      },
      // 获取列表
      getUserManageList() {
        this.formData = {...this.oldSearchCondition}
        this.loading = true;
        this.$axios({
          method: 'post',
          url: '/managementAccount/getUserList',
          data: {...this.formData, ...this.pageConfig}
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.tableData = res.data.list;
            this.pageConfig = this.$getPageConfig(res)
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      },
      // 获取角色
      getRoleList() {
        this.$axios({
          method: 'post',
          url: '/role/getAll'
        }).then(res => {
          if (res.code === 200) {
            this.roleLists = res.data;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        })
      },
      // 禁用和启用切换
      changeStatus(item) {
        let value = '';
        if (item.status === 1) {
          value = '禁用'
        } else {
          value = '启用'
        }
        this.$confirm(`是否${value}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
            method: 'put',
            url: '/managementAccount/updateStatus',
            data: {
              id: item.id,
              status: item.status === 0 ? 1 : 0
            }
          }).then(res => {
            if (res.code === 200) {
              this.getUserManageList();
            } else {
              // 请求出错
              this.$showAlert(res.message, 'error')
            }
          })
        }).catch((e) => {
          this.$showAlert(e.message, 'info')
        });
      },
      // 重置
      onReset() {
        this.formData.name = '';
        this.formData.telephone = '';
        this.formData.roleId = '';
        this.oldSearchCondition = {}
        this.pageConfig = {}
        this.getUserManageList();
      }

    }
  }

</script>

<style scoped lang="scss">
  .accountManage {
    .box-card {
      margin-bottom: 20px;
    }
    .role-table {
      margin-bottom: 20px;
    }
    .el-card.is-always-shadow {
      box-shadow: none;
      .el-form-item {
        margin-bottom: 10px;
      }
    }
    .demo-form-inline {
      position: relative;
      .add-account {
        position: absolute;
        right: 0;
      }
    }
    .c-table {
      .el-table__row {
        .operate {
          .el-button.red {
            color: red;
          }
        }
      }
    }
  }
</style>
