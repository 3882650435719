<template>
  <el-dialog
    class="c-dialog"
    :title="type"
    :visible.sync="isShowDialog"
    width="700px"
    :close-on-click-modal="false"
    v-loading="loading"
    :before-close="handleClose">
    <div class="content">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="账号：" prop="username">
          <el-input v-model="ruleForm.username" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password" v-if="this.injectData.type === 'add'">
          <el-input v-model="ruleForm.password" placeholder="请输入密码" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="telephone">
          <el-input v-model="ruleForm.telephone" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item label="角色：" class="has-check" prop="roleId" style="width: 100%;">
          <el-select v-model="ruleForm.roleId" placeholder="请选择" multiple clearable @remove-tag="removeTag">
            <el-option :label="item.name" :value="item.id" v-for="(item,index) in roleLists" :disabled="item.disabled" :key="index"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button class="c-btn" @click="handleClose">取 消</el-button>
    <el-button type="primary" class="c-btn" @click="saveAccount">确 定</el-button>
   </span>
  </el-dialog>
</template>
<script>
  export default {
    name: 'modal',
    props: {
      injectData: {
        type: Object,
        default: function () {
          return {
            roleId: []
          }
        }
      },
      isShowDialog: {
        type: Boolean
      }
    },
    computed: {
      userInfo() {
        return this.$store.state.userInfo
      }
    },
    data() {
      const checkRole = (rule, value, callback) => {
        if (!this.ruleForm.roleId.length) {
          callback(new Error('请至少选择一个权限！'));
        } else {
          callback()
        }
      }
      // 校验姓名/^[a-zA-Z\u4e00-\u9fa5]+$/
      const validName = (rule, value, callback) => {
        const reg = /^[a-zA-Z\u4e00-\u9fa5]{1,10}$/
        if (!reg.test(value)) {
          callback(new Error('请输入10位以内的中英文'))
        } else {
          callback()
        }
      }
      // 校验手机号
      const validPhone = (rule, value, callback) => {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          if (!reg.test(value)) {
            callback(new Error('请输入正确的11位手机号码'))
          } else {
            callback()
          }
      }
      // 校验账号
      const validUsername = (rule, value, callback) => {
        const reg = /^[a-zA-Z]{1,14}$/
        if (!reg.test(value)) {
          callback(new Error('请输入14位以内的英文字符'))
        } else {
          callback()
        }
      }
      // 校验密码
      const validPassword = (rule, value, callback) => {
        const reg = /^[a-z_A-Z0-9-\.!@#\$%\\\^&\*\)\(\+=\{\}\[\]\/",'/-_<>~\·`\?:;|]{6,16}$/
        if (!reg.test(value)) {
          callback(new Error('请输入6-16位英文，数字或者符号'))
        } else {
          callback()
        }
      }
      return {
        down: true,
        ruleForm: {},
        rules: {
          name: [{required: true, message: '请输入10位以内的中英文', validator: validName, trigger: 'blur'}],
          roleId: [{validator: checkRole, trigger: 'change'}],
          username: [
            {required: true, message: '请输入账号', trigger: 'blur'},
            {validator: validUsername, trigger: 'change'}
          ],
          password: [
            {
              required: true,
              message: '密码不可以为空！',
              trigger: 'blur'
            },
            {
              required: true,
              message: '请输入请输入6-16位英文，数字或者符号密码',
              trigger: 'blur',
              validator: validPassword
            }],
          telephone: [{trigger: 'blur', validator: validPhone, required: true}]
        },
        roleLists: {},
        noAutoArrIds: [],
        loading: false,
        type: ''
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
      if (this.injectData.id && this.injectData.type === 'edit') {
        this.gitAccountDetail(this.injectData.id);
        this.type = '编辑员工'
      } else {
        this.type = '新增员工'
        this.getRoleList();
      }
    },
    methods: {
      removeTag(id) {
        const idx = this.noAutoArrIds.findIndex(row => row === id)
        if (idx > -1) {
          this.$showAlert('无法删除不属于自己的角色权限！', 'warning')
          this.ruleForm.roleId.push(id)
        }
      },
      handleClose() {
        this.$emit('close')
      },
      // 获取角色
      getRoleList() {
        const successCall = (res) => {
          if (res.code === 200) {
            this.roleLists = res.data || [];
            if (this.injectData.id) {
              const nameArr = this.ruleForm.roleName && this.ruleForm.roleName.split(',') || []
              this.ruleForm.roleId && this.ruleForm.roleId.forEach((row, idx) => {
                const findIndex = this.roleLists.findIndex(item => item.id === row)
                console.log(findIndex, 'find')
                if (findIndex === -1) {
                  this.$set(this.noAutoArrIds, this.noAutoArrIds.length, row)
                  this.$set(this.roleLists, this.roleLists.length, {
                    id: row,
                    name: nameArr[idx],
                    disabled: true
                  })
                }
              })
            }
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }
        if (this.userInfo.username === 'admin' || this.userInfo.username === 'ADMIN') {
          this.$axios({method: 'post', url: '/role/getAll'}).then(successCall)
        } else {
          this.$axios({method: 'get', url: `/role/getAllByUserId/${this.userInfo.id}`}).then(successCall)
        }
      },
      // 保存
      saveAccount() {
        if (this.injectData.type === 'add') {
          this.addAccount();
        } else if (this.injectData.type === 'edit') {
          this.upDateAccount(this.injectData.id);
        }
      },
      // 获取详情
      gitAccountDetail(id) {
        this.loading = true;
        this.$axios({
          method: 'post',
          url: `/managementAccount/detail/${id}`
        }).then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.ruleForm = res.data;
            this.getRoleList();
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false;
        })
      },
      // 更新保存
      upDateAccount() {
        this.$refs.ruleForm.validate((valid) => {
          console.log('rrr');
          if (valid) {
            console.log('success');
            this.loading = true;
            this.$axios({
              method: 'post',
              url: '/managementAccount/update',
              data: {
                ...this.ruleForm,
                id: this.injectData.id
              }
            }).then(res => {
              this.loading = false;
              if (res.code === 200) {
                this.$showAlert(res.message)
                this.$emit('close');
                this.$parent.getUserManageList();
              } else {
                // 请求出错
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false;
            })
          } else {
            console.log('error');
            return false;
          }
        });
      },
      // 新增保存
      addAccount() {
        this.$refs.ruleForm.validate((valid) => {
          console.log('新增');
          if (valid) {
            this.loading = true;
            this.$axios({
              method: 'post',
              url: '/managementAccount/save',
              data: this.ruleForm
            }).then(res => {
              this.loading = false;
              if (res.code === 200) {
                this.$showAlert(res.message)
                this.$emit('close');
                this.$parent.getUserManageList();
              } else {
                // 请求出错
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false;
            })
          } else {
            return false;
          }
        });

      }
    }
  }
</script>
<style scoped lang="scss">

</style>
