<template>
  <div id="app">
    <router-view class="router-view"></router-view>
  </div>
</template>
<script>
import { deepCopy, getCookie } from './plugins/util';
import { pageAndRouter } from './router/pageConfig';
import Layout from './views/Layout.vue';

import UserDetail from './views/baseManage/UserDetail.vue';
import OrderDetail from './views/ordeCenter/OrderDetail.vue';
import UmbrellaDetail from './views/smartService/UmbrellaDetail.vue';
import WristbandDetail from './views/ordeCenter/wristband/WristbandDetail.vue';
import WristbandBind from './views/ordeCenter/wristband/WristbandBind.vue';
import WristbandReturn from './views/ordeCenter/wristband/WristbandReturn.vue';
import LockerDetail from './views/ordeCenter/locker/LockerDetail.vue';
import CheckIn from './views/ordeCenter/room/CheckIn.vue';
import CheckOut from './views/ordeCenter/room/CheckOut.vue';
import RoomDetail from './views/ordeCenter/room/RoomDetail.vue';

const noNeedPer = [
  {
    path: 'userDetail',
    name: '用户详情',
    meta: {
      parent: 'userManage'
    },
    component: UserDetail
  },
  {
    path: 'orderDetail',
    meta: {
      parent: 'umbrella'
    },
    name: '订单详情',
    component: OrderDetail
  },
  {
    path: 'umbrellaDetail',
    meta: {
      parent: 'umbrellaManage'
    },
    name: '伞位详情',
    component: UmbrellaDetail
  },
  {
    path: 'wristbandDetail',
    meta: {
      parent: 'wristband'
    },
    name: '订单详情',
    component: WristbandDetail
  },
  {
    path: 'wristbandBind',
    meta: {
      parent: 'wristband'
    },
    name: '手环绑定',
    component: WristbandBind
  },
  {
    path: 'wristbandReturn',
    meta: {
      parent: 'wristband'
    },
    name: '手环退还',
    component: WristbandReturn
  },
  {
    path: 'lockerDetail',
    meta: {
      parent: 'locker'
    },
    name: '订单详情',
    component: LockerDetail
  },
  {
    path: 'checkIn',
    meta: {
      parent: 'room'
    },
    name: '入住确认',
    component: CheckIn
  },
  {
    path: 'checkOut',
    meta: {
      parent: 'room'
    },
    name: '退房确认',
    component: CheckOut
  },
  {
    path: 'roomDetail',
    meta: {
      parent: 'room'
    },
    name: '订单详情',
    component: RoomDetail
  }
];

export default {
  name: 'app',
  data() {
    return {
      menuAndPermission: []
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        let routeArr = [];
        let path = val.path.substring(1);
        if (val.meta && val.meta.parent) {
          path = val.meta.parent;
          routeArr = [
            {
              name: val.name,
              url: val.path
            }
          ];
        }
        for (let i in this.menuAndPermission) {
          for (let j in this.menuAndPermission[i].child) {
            if (this.menuAndPermission[i].child[j].href === path) {
              routeArr.unshift({
                index: j,
                name: this.menuAndPermission[i].child[j].name,
                url: this.menuAndPermission[i].child[j].href
              });
              routeArr.unshift({
                index: i,
                name: this.menuAndPermission[i].name,
                url: this.menuAndPermission[i].href
              });
              break;
            }
          }
        }
        this.$store.commit('setMenuActive', routeArr);
        this.$store.commit('setMenuAndPermission', this.menuAndPermission);
      },
      // 深度观察监听
      deep: true
    }
  },
  components: {},
  provide: function() {
    return {
      getMenuPermission: this.getMenuPermission,
      setMenuPermission: this.setMenuPermission
    };
  },
  methods: {
    async setMenuPermission() {
      console.log('lalalallallalallala');
      const dataList = await this.getMenuPermission();
      let routeArr = [
        {
          path: '/',
          name: 'layout',
          component: Layout,
          children: noNeedPer
        }
      ];
      let firstPagePath = '';
      const oldPath =
        this.$route.query.oldPath || this.$route.path.substring(1);
      const query = this.$route.query;
      delete query.oldPath;

      for (let i = 0; i < dataList.length; i += 1) {
        let menu = dataList[i];
        if (menu.child && menu.child.length) {
          for (let j = 0; j < menu.child.length; j += 1) {
            let child1 = menu.child[j];
            // 这里遍历页面，把有权限的页面add进来
            for (let i in pageAndRouter) {
              const path = pageAndRouter[i].path;
              if (child1.href === path) {
                const copyChild = deepCopy(pageAndRouter[i]);
                if (!firstPagePath) {
                  firstPagePath = pageAndRouter[i].path;
                  // firstPagePath = 'userAnalysis';
                }
                // 判断上一个页面是否存在，存在就回到上一个页面
                if (oldPath && oldPath !== 'login') {
                  if (oldPath === child1.href) {
                    firstPagePath = oldPath;
                  }
                }
                copyChild.name = child1.name;
                copyChild.meta = child1.child;
                copyChild.component = pageAndRouter[i].component;
                routeArr[0].children.push(copyChild);
                console.log('tag', copyChild);
                break;
              }
            }
          }

        }
      }


      // dataList.forEach(menu => {
      //   if (menu.child && menu.child.length) {
      //     menu.child.forEach(child1 => {
      //       // 这里遍历页面，把有权限的页面add进来
      //       for (let i in pageAndRouter) {
      //         const path = pageAndRouter[i].path;
      //         if (child1.href === path) {
      //           const copyChild = deepCopy(pageAndRouter[i]);
      //           if (!firstPagePath) {
      //             // firstPagePath = pageAndRouter[i].path;
      //             firstPagePath = 'userAnalysis';
      //           }
      //           // 判断上一个页面是否存在，存在就回到上一个页面
      //           if (oldPath && oldPath !== "login") {
      //             if (oldPath === child1.href) {
      //               firstPagePath = oldPath;
      //             }
      //           }
      //           copyChild.name = child1.name;
      //           copyChild.meta = child1.child;
      //           copyChild.component = pageAndRouter[i].component;
      //           routeArr[0].children.push(copyChild);
      //           break;
      //         }
      //       }
      //     });
      //   }
      // });
      // 这里添加不需要权限控制的部分的路径菜单
      this.$router.addRoutes(routeArr);
      for (let i in noNeedPer) {
        if (noNeedPer[i].path === oldPath) {
          firstPagePath = oldPath;
          break;
        }
      }
      this.$router.push({ path: firstPagePath, query: query });
    },
    getMenuPermission() {
      return new Promise((resolve, reject) => {
        this.$axios({
          url: '/menu/currentPermission',
          method: 'get'
        })
          .then(res => {
            // 根据菜单地址，来添加权限
            if (res.code === 200) {
              this.menuAndPermission = res.data;
              resolve(res.data);
            } else {
              reject(res);
            }
          })
          .catch(e => {
            reject(e);
          });
      });
    }
  },
  created() {
    // 判断是否有token
    if (getCookie('token')) {
      // 获取菜单数据
      this.setMenuPermission();
    } else {
      // 跳转到登录页面或大屏页面
      const path = getCookie('path');
      console.log('path', path)
      if (path === '/screen') {
        this.$router.push(path);
      } else {
        this.$router.push('/login');
      }
    }
  }
};
</script>
<style lang="scss">
#app {
  .router-view {
    width: 100%;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    height: 100%;
  }
  height: 100%;
}
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
