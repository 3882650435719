<template>
  <el-dialog
    class="c-dialog"
    :title="pageType === 'edit' ? '编辑节假日' : '新增节假日'"
    :visible.sync="isShowDialog"
    width="720px"
    :close-on-click-modal="false"
    v-loading="loading"
    :before-close="handleClose">
    <div class="content">
      <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="年度：" prop="name">
          <el-input v-model="formData.year" placeholder="请输入年份"></el-input>
        </el-form-item>
        <el-form-item label="日期：" prop="description">
          <el-date-picker v-model="formData.beginDate" type="date" placeholder="选择开始日期"></el-date-picker>至
          <el-date-picker v-model="formData.endDate" type="date" placeholder="选择结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="名称：" prop="name">
          <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="permission" class="has-check">
          <el-radio v-model="formData.radio" label="1">是</el-radio>
          <el-radio v-model="formData.radio" label="2">否</el-radio>
          <!-- <el-tree
            ref="three"
            :data="threeData"
            show-checkbox
            :check-strictly="false"
            node-key="id"
            :default-checked-keys="permissionIds"
            :props="defaultProps">
          </el-tree> -->
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="c-btn" @click="handleClose">取 消</el-button>
      <el-button type="primary" class="c-btn" @click="submitData" :disabled="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  const successCode = 200
  export default {
    name: 'roleDialog',
    props: {
      operateId: {
        require: false
      },
      isShowDialog: {
        type: Boolean
      }
    },
    computed: {
      pageType() {
        if (this.operateId) {
          return 'edit'
        } else {
          return 'add'
        }
      }
    },
    data() {
      const checkPermission = (rule, value, callback) => {
        if (!this.$refs.three.getCheckedKeys().length) {
          callback(new Error('请至少选择一个权限！'));
        } else {
          callback()
        }
      }
      const validRolename = (rule, value, callback) => {
        if (value !== '' && value !== null) {
          const reg = /^[\u4e00-\u9fa5]{1,10}$/
          if (!reg.test(value)) {
            callback(new Error('请输入10位以内的纯中文'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
      return {
        loading: false,
        permissionIds: [],
        threeData: [],
        formData: {},
        rules: {
          name: [
            {required: true, message: '角色名称不可为空！', trigger: 'change'},
            {validator: validRolename, trigger: 'blur'}
          ],
          description: [
            {min: 0, max: 50, message: '长度在小于50位', trigger: 'blur'}
          ],
          permission: [
            { validator: checkPermission, trigger: 'blur' }
          ]
        },
        defaultProps: {
          children: 'child',
          label: 'name'
        }
      }
    },
    methods: {
      getSelectIds(list) {
        let arr = []
        list.forEach(row => {
          arr.push(row.id)
        })
        return arr
      },
      submitData() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.formData.permissionIds = this.$refs.three.getCheckedKeys().concat(this.$refs.three.getHalfCheckedKeys())
            this.$axios({
              url: '/role/save',
              method: 'post',
              data: this.formData
            }).then(res => {
              this.loading = false
              if (res.code === successCode) {
                this.$showAlert(res.message)
                this.$emit('close')
                this.$emit('operateCallBack')
              } else {
                this.$showAlert(res.message, 'error')
              }
            }).catch(e => {
              this.loading = false
            })
          }
        })
      },
      handleClose() {
        this.$emit('close')
      },
      getAllMenu() {
        this.$axios({
          url: '/menu/currentPermission',
          method: 'get'
        }).then(res => {
          if (res.code === successCode) {
            this.threeData = res.data
          } else {
            this.$showAlert(res.message, 'error')
          }
        })
      }
    },
    created() {
      this.getAllMenu()
      if (this.operateId) {
        this.loading = true
        this.$axios({
          url: '/role/detail/' + this.operateId,
          method: 'post'
        }).then(res => {
          this.loading = false
          if (res.code === successCode) {
            this.permissionIds = this.getSelectIds(res.data.permission)
            console.log(res.data.permission, '12')
            this.formData = res.data.role
          } else {
            this.$showAlert(res.message, 'error')
          }
        }).catch(e => {
          this.loading = false
        })
      }
    }
  }
</script>
<style scoped lang="scss">

</style>
