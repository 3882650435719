<!--财务分析-->
<template>
  <div class="financeAnalysis">
    <div class="bar">
      <div class="line"></div>
      <div class="title">关键指标</div>
      <el-select
        v-model="key.date"
        placeholder="请选择"
        style="width: 150px;margin-right: 15px;"
        size="mini"
        @change=indexTypeChange
      >
        <el-option
          v-for="item in keyDateList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="key-div" v-loading="keyLoading">
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_list.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">¥{{indexData.incomeAmount}}</div>
        <div class="key-title">收入金额</div>
        <div class="content">环比<img v-if="indexData.incomePercent != null" :src="getImgSrc(indexData.incomePercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.incomePercent == null ? ' -' : (indexData.incomePercent >= 0 ? (indexData.incomePercent+'%') : ((-indexData.incomePercent)+'%'))}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_list.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">¥{{indexData.refundAmount}}</div>
        <div class="key-title">退款金额</div>
        <div class="content">环比<img v-if="indexData.refundPercent != null" :src="getImgSrc(indexData.refundPercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.refundPercent == null ? ' -' : (indexData.refundPercent >= 0 ? (indexData.refundPercent+'%') : ((-indexData.refundPercent)+'%'))}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_list.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">¥{{indexData.depositAmount}}</div>
        <div class="key-title">押金金额</div>
        <div class="content">环比<img v-if="indexData.depositPercent != null" :src="getImgSrc(indexData.depositPercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.depositPercent == null ? ' -' : (indexData.depositPercent >= 0 ? (indexData.depositPercent+'%') : ((-indexData.depositPercent)+'%'))}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <!-- <i class="el-icon-document" style="margin-bottom: 10px;"></i> -->
        <img src="@/assets/img/icon_list.png" style="width: 25px; margin-bottom: 10px;"/>
        <div class="count">¥{{indexData.rechargeAmount}}</div>
        <div class="key-title">充值金额</div>
        <div class="content">环比<img v-if="indexData.rechargePercent != null" :src="getImgSrc(indexData.rechargePercent >= 0)" style="height: 18px; margin: 0px 3px 0 3px;"
        />{{indexData.rechargePercent == null ? ' -' : (indexData.rechargePercent >= 0 ? (indexData.rechargePercent+'%') : ((-indexData.rechargePercent)+'%'))}}</div>
      </div>
    </div>
    <div class="bar">
      <div class="line"></div>
      <div class="title">收入来源（不含押金、充值金额）</div>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        @change="ringBeginDateChange"
        v-model="ringDate.begin"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        @change="ringEndDateChange"
        v-model="ringDate.end"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </div>
    <VeRing 
      :data="ringData"
      :settings="ringSettings"
      :extend="ringExtends"
      height="350px"
      width="100%"
      v-loading="ringLoading"
      style="float:left;"
      ></VeRing>
    <div class="bar">
      <div class="line"></div>
      <div class="title">趋势图</div>
      <el-select
        v-model="line.type"
        placeholder="请选择"
        @change="lineTypeChange"
        style="width: 180px;margin-right: 10px;"
        size="mini"
      >
        <el-option
          v-for="item in typeList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="lineDate.begin"
        @change="lineBeginDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        v-model="lineDate.end"
        @change="lineEndDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </div>
    <VeLine
      :data="lineData"
      :settings="lineSettings"
      :legend-visible="false"
      height="300px"
      v-loading="lineLoading"
    ></VeLine>
    <div class="bar">
      <div class="line"></div>
      <div class="title">详情</div>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 10px;"
        v-model="tableDate.begin"
        @change="tableBeginDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 10px;"
        v-model="tableDate.end"
        @change="tableEndDateChange"
        align="right"
        type="date"
        :editable="false"
        :clearable="false"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
      <el-button type="primary" size="mini" style="margin-right: 15px;" @click="downloadExcel">下载表格</el-button>
    </div>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="tableLoading">
        <el-table-column prop="orderCode" label="时间">
          <template slot-scope="scope">
            <span>{{subDate(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="充值（元）">
          <template slot-scope="scope">
            <span>{{scope.row.incomeAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="提取（元）">
          <template slot-scope="scope">
            <span>{{scope.row.drawAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="餐饮消费（元）">
          <template slot-scope="scope">
            <span>{{scope.row.foodIncome}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grid"  label="储物柜租赁（元）">
          <template slot-scope="scope">
            <span>{{scope.row.lockerIncome}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="伞位预约（元）">
          <template slot-scope="scope">
            <span>{{scope.row.umbrellaIncome}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="手环租赁（元）">
          <template slot-scope="scope">
            <span>{{scope.row.depositAmount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="景区购票（元）">
          <template slot-scope="scope">
            <span>{{scope.row.ticketIncome}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="停车缴费（元）">
          <template slot-scope="scope">
            <span>{{scope.row.parkingIncome}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="退款（元）">
          <template slot-scope="scope">
            <span>{{scope.row.refundAmount}}</span>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common';
import VeRing from 'v-charts/lib/ring.common';
import upIconUrl from '@/assets/img/icon_up.png';
import downIconUrl from '@/assets/img/icon_down.png';
export default {
  name: 'FinanceAnalysis',
  data() {
    return {
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      pickerOptions: {
        disabledDate: (time) => 
          // 不能选择今天之后的天
           time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime(),        
        shortcuts: [
          {
            text: '最近7天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近15天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      pickerOptions1: {
        disabledDate: (time) => 
          // 不能选择今天之后的天
           time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime()
        
      },
      ringDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      tableDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      key: { date: 0 },
      line: {type: 0},
      lineDate: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      keyDateList: [
        { label: '今日', value: 0 },
        { label: '本周', value: 1 },
        { label: '本月', value: 2 },
        { label: '本年', value: 3 }
      ],
      typeList: [
        { label: '全部收入', value: 0 },
        { label: '储物柜租赁', value: 1 },
        { label: '餐饮消费', value: 2 },
        { label: '停车消费', value: 3 },
        { label: '景区购票', value: 4 },
        { label: '伞位预约', value: 5 }
      ],
      indexData: {},
      keyLoading: false,
      tableLoading: false,
      ringLoading: false,
      lineLoading: false,
      tableData: [],
      lineData: {
        columns: ['time', 'amount'],
        rows: [
          { time: '01/01', price: 10 },
          { time: '01/03', price: 20 },
          { time: '01/05', price: 30 },
          { time: '01/07', price: 25 },
          { time: '01/09', price: 15 },
          { time: '01/11', price: 5 }
        ]
      },
      lineSettings: {
        metrics: ['amount'],
        dimension: ['time'],
        labelMap: { amount: '金额' }
       
      },
      ringExtends: {
        legend: { 
          show: true,
          // 图例组件离容器下侧的距离
          bottom: '10%',
          // 图例每项之间的间隔
          itemGap: 75,
          // 图例标记的图形宽度
          itemWidth: 20,
          // 图例标记的图形高度
          itemHeight: 20,
          // 圆角半径，单位px
          borderRadius: 0
        },  
	      series: {
	        center: ['50%', '35%']
	      }
      },
      ringData: {
        columns: ['type', 'amount'],
        rows: [
          { type: '01/01', amount: 10 },
          { type: '01/03', amount: 20 },
          { type: '01/05', amount: 30 },
          { type: '01/07', amount: 25 },
          { type: '01/09', amount: 15 },
          { type: '01/11', amount: 5 }
        ]
      },
      ringSettings: {
        metrics: ['amount'],
        dimension: ['type'],
        labelMap: { amount: '金额' },
        // 是否开启 hover 在扇区上的放大动画效果
        hoverAnimation: true,
        // 环图内外半径
        radius: ['88px', '100px'],
        // 视觉引导线
        labelLine: {
          // 引导线是否显示
          show: true,
          length: 0,
          length2: 80,
          lineStyle: {
            // color: '#666666',
            width: 1.5
          }
        },
        label: {
            show: true
        }
      }
    };
  },
  created() {
    this.getFinanceIndex();
    this.getRingData();
    this.getLineData();
    this.getTableData();
  },
  mounted() {
    this.keyLoading = true;
    this.ringLoading = true;
    this.lineLoading = true;
    this.tableLoading = true;
  },
  components: {
    VeLine,
    VeRing
  },
  methods: {
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      // TODO:获取表格数据
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      // TODO:获取表格数据
    },
    subDate(date) {
      var index = date.indexOf(' ');
      return date.substring(0, index);
    },
    getImgSrc(result) {
      if (result) {
        return upIconUrl;
      } else {
        return downIconUrl;
      }
    },
    getFinanceIndex() {
      this.$axios({
        method: 'get',
        url: '/analysis/finance/index',
        params: {
          type: this.key.date
        }
      }).then(res => {
        if (res.code === 200) {
          this.indexData = res.data;
          this.keyLoading = false;
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getRingData() {
      if (this.ringDate.end.getTime() >= this.ringDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/finance/ring',
          params: {
            begin: this.ringDate.begin,
            end: this.ringDate.end
          }
        }).then(res => {
          if (res.code === 200) {
            this.ringData.rows = res.data;
            this.ringLoading = false;
          } else {
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.ringLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    getLineData() {
      if (this.lineDate.end.getTime() >= this.lineDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/finance/line',
          params: {
            type: this.line.type,
            begin: this.lineDate.begin,
            end: this.lineDate.end
          }
        }).then(res => {
          if (res.code === 200) {
            this.lineData.rows = res.data;
            this.lineLoading = false;
          } else {
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.lineLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    getTableData() {
      if (this.tableDate.end.getTime() >= this.tableDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/finance/table',
          params: {
            begin: this.tableDate.begin,
            end: this.tableDate.end
          }
        }).then(res => {
          if (res.code === 200) {
            this.tableData = res.data;
            this.tableLoading = false;
          } else {
            this.$showAlert(res.message, 'error');
          }
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      } else {
        this.tableLoading = false;
        this.$showAlert('结束日期必须大于开始日期', 'error');
      }
    },
    indexTypeChange() {
      this.keyLoading = true;
      this.getFinanceIndex();
    },
    ringBeginDateChange() {
      this.ringDate.begin = new Date(this.ringDate.begin.getTime() + 8 * 3600 * 1000);
      this.ringLoading = true;
      this.getRingData();
    },
    ringEndDateChange() {
      this.ringDate.end = new Date(this.ringDate.end.getTime() + 8 * 3600 * 1000);
      this.ringLoading = true;
      this.getRingData();
    },
    lineTypeChange() {
      this.lineLoading = true;
      this.getLineData();
    },
    lineBeginDateChange() {
      this.lineDate.begin = new Date(this.lineDate.begin.getTime() + 8 * 3600 * 1000);
      this.lineLoading = true;
      this.getLineData();
    },
    lineEndDateChange() {
      this.lineDate.end = new Date(this.lineDate.end.getTime() + 8 * 3600 * 1000);
      this.lineLoading = true;
      this.getLineData();
    },
    tableBeginDateChange() {
      this.tableDate.begin = new Date(this.tableDate.begin.getTime() + 8 * 3600 * 1000);
      this.tableLoading = true;
      this.getTableData();
    },
    tableEndDateChange() {
      this.tableDate.end = new Date(this.tableDate.end.getTime() + 8 * 3600 * 1000);
      this.tableLoading = true;
      this.getTableData();
    },
    downloadExcel() {
      if (this.tableDate.end.getTime() >= this.tableDate.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/finance/excel',
          params: {
            begin: this.tableDate.begin,
            end: this.tableDate.end
          },
          responseType: 'blob'
        }).then(res => {
          this.download(res);
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      }
    },
    download(data) {
       if (!data) {
           return
       }
       var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
       var url = window.URL.createObjectURL(blob);
       var aLink = document.createElement('a');
       aLink.style.display = 'none';
       aLink.href = url;
       var fileName = '财务分析表.xls';
       aLink.setAttribute('download', fileName);
       document.body.appendChild(aLink);
       aLink.click();
       document.body.removeChild(aLink); // 下载完成移除元素
       window.URL.revokeObjectURL(url); // 释放掉blob对象
    }
  }
};
</script>

<style scoped lang="scss">
.financeAnalysis {
  .bar {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(245, 244, 247);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .line {
      width: 5px;
      height: 20px;
      background-color: #1ab394;
      margin-right: 10px;
    }

    .title {
      flex: 1;
    }
  }

  .key-div {
    border: 1px solid rgb(196, 193, 193);
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      margin-top: 15px;
      margin-bottom: 15px;

      .key-title {
        font-size: 16px;
        margin-bottom: 5px;
      }

      .count {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 18px;
      }

      .content {
        font-size: 14px;
      }
    }

    .key-line {
      width: 1px;
      height: 100px;
      background-color: rgb(196, 193, 193);
    }
  }
}
</style>
