'use strict';

import Vue from 'vue';
import axios from 'axios';
import { baseServiceUrl, getCookie } from './util';
import qs from 'qs';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common.token = getCookie('token');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 设置axios为form-data
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function (data) {
//   let ret = ''
//   for (let it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   return ret
// }]
let baseURL = baseServiceUrl();
let config = {
  baseURL: baseURL,
  // baseURL: 'http://localhost:8082',
  timeout: 60 * 1000 // Timeout
  // withCredentials: true // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    const token = getCookie('token');
    if (token) {
      config.headers.common.token = token;
    }
    // 登录接口、大屏显示接口特殊，使用form-data格式
    if (
      config.url.indexOf('/login') > -1 ||
      config.url.indexOf('/umbrella/map/cat') > -1 ||
      config.url.indexOf('/bracelet/downloadTemplate') > -1
    ) {
      config.data = qs.stringify(config.data);
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response.data;
  },
  function(error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === '401' &&
      error.config.url.indexOf('/login') === -1
    ) {
      // 这里把过期之前的页面的地址找出来
      const baseHref = location.origin + location.pathname + '#/login';
      if (location.hash.indexOf('/login') === -1) {
        location.href = baseHref + '?oldPath=' + location.hash.substring(2);
      } else {
        location.href = baseHref;
      }
    }
    Vue.prototype.$showAlert(error.response.data.message, 'error');
    // Do something with response error
    return Promise.reject(error.response && error.response.data);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
