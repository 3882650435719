<!--用户分析-->
<template>
  <div class="userAnalysis">
    <div class="bar">
      <div class="line"></div>
      <div class="title">关键指标</div>
    </div>
    <div class="key-div" v-loading="keyLoading">
      <div class="item">
        <div class="key-title">用户总数</div>
        <div class="count">{{userIndex.analysisUserCompare.totalNum}}</div>
        <div class="content">较昨日：{{userIndex.analysisUserCompare.incNumFromYesterday >= 0 ? '增加 '+ userIndex.analysisUserCompare.incNumFromYesterday : '减少 '+ -userIndex.analysisUserCompare.incNumFromYesterday}}</div>
        <div class="content">较上周：{{userIndex.analysisUserCompare.incNumFromLastWeek >= 0 ? '增加 '+ userIndex.analysisUserCompare.incNumFromLastWeek : '减少 '+ -userIndex.analysisUserCompare.incNumFromLastWeek}}</div>
        <div class="content">较上月：{{userIndex.analysisUserCompare.incNumFromLastMonth >= 0 ? '增加' + userIndex.analysisUserCompare.incNumFromLastMonth : '减少 '+ -userIndex.analysisUserCompare.incNumFromLastMonth}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <div class="key-title">会员总数</div>
        <div class="count">{{userIndex.analysisMemberCompare.totalNum}}</div>
        <div class="content">较昨日：{{userIndex.analysisMemberCompare.incNumFromYesterday >= 0 ? '增加 '+userIndex.analysisMemberCompare.incNumFromYesterday : '减少 '+ -userIndex.analysisMemberCompare.incNumFromYesterday}}</div>
        <div class="content">较上周：{{userIndex.analysisMemberCompare.incNumFromLastWeek >= 0 ? '增加 '+userIndex.analysisMemberCompare.incNumFromLastWeek : '减少 '+ -userIndex.analysisMemberCompare.incNumFromLastWeek}}</div>
        <div class="content">较上月：{{userIndex.analysisMemberCompare.incNumFromLastMonth >= 0 ? '增加 '+userIndex.analysisMemberCompare.incNumFromLastMonth : '减少 '+ -userIndex.analysisMemberCompare.incNumFromLastMonth}}</div>
      </div>
      <div class="key-line"></div>
      <div class="item">
        <div class="key-title">公众号关注数</div>
        <div class="count">{{userIndex.analysisConcernCompare.totalNum}}</div>
        <div class="content">较昨日：{{userIndex.analysisConcernCompare.incNumFromYesterday >= 0 ? '增加 '+userIndex.analysisConcernCompare.incNumFromYesterday : '减少 '+ -userIndex.analysisConcernCompare.incNumFromYesterday}}</div>
        <div class="content">较上周：{{userIndex.analysisConcernCompare.incNumFromLastWeek >= 0 ? '增加 '+userIndex.analysisConcernCompare.incNumFromLastWeek : '减少 '+ -userIndex.analysisConcernCompare.incNumFromLastWeek}}</div>
        <div class="content">较上月：{{userIndex.analysisConcernCompare.incNumFromLastMonth >= 0 ? '增加 '+userIndex.analysisConcernCompare.incNumFromLastMonth : '减少 '+ -userIndex.analysisConcernCompare.incNumFromLastMonth}}</div>
      </div>
    </div>
    <div class="bar">
      <div class="line"></div>
      <div class="title">趋势图</div>
      <el-select
        @change="cChartType"
        v-model="chartParams.type"
        placeholder="请选择"
        style="width: 150px;margin-right: 10px;"
        size="mini"
      >
        <el-option
          v-for="item in tendCountList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        @change="cChartLeftDate"
        v-model="chartParams.begin"
        align="right"
        :editable="false"
        :clearable="false"
        type="date"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        size="mini"
        style="width: 130px;margin-right: 15px;"
        @change="cChartRightDate"
        v-model="chartParams.end"
        :editable="false"
        :clearable="false"
        align="right"
        type="date"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </div>
    <VeLine :data="tendData" :settings="tendSettings" :legend-visible="false" height="300px" v-loading="tendLoading"></VeLine>
    <!-- 表格详情 -->
    <div class="bar">
      <div class="line"></div>
      <div class="title">详情</div>
      <el-date-picker
        @change="cTableLeftDate"
        size="mini"
        style="width: 130px;margin-right: 10px;"
        v-model="tableParams.begin"
        align="right"
        :editable="false"
        :clearable="false"
        type="date"
        placeholder="开始日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-date-picker
        @change="cTableRightDate"
        size="mini"
        style="width: 130px;margin-right: 10px;"
        v-model="tableParams.end"
        :editable="false"
        :clearable="false"
        align="right"
        type="date"
        placeholder="结束日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
      <el-button v-on:click="downloadExcel" type="primary" size="mini" style="margin-right: 15px;">下载表格</el-button>
    </div>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%" v-loading="tableLoading">
        <el-table-column prop="orderCode" label="时间">
          <template slot-scope="scope">
            <span>{{getDate(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="新用户数">
          <template slot-scope="scope">
            <span>{{scope.row.incUserNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="累计用户数">
          <template slot-scope="scope">
            <span>{{scope.row.userNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cabinet" label="新增会员数">
          <template slot-scope="scope">
            <span>{{scope.row.incMemberNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="grid" label="累计会员数">
          <template slot-scope="scope">
            <span>{{scope.row.memberNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="新增关注数">
          <template slot-scope="scope">
            <span>{{scope.row.incConcernNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="取消关注数">
          <template slot-scope="scope">
            <span>{{scope.row.concernCancelNum}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="累计关注数">
          <template slot-scope="scope">
            <span>{{scope.row.concernNum}}</span>
          </template>
        </el-table-column>
      </el-table>
      <yd-page
        :page-config="pageConfig"
        :size-change="handleSizeChange"
        :current-change="handleCurrentChange"
      ></yd-page>
    </div>
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common'
export default {
  name: 'UserAnalysis',
  data() {
    return {
      timeDifference: 8 * 3600 * 1000,
      userIndex: {
        // 用户分析关键指标
        analysisUserCompare: {
          totalNum: 0,
          incNumFromYesterday: 0,
          incNumFromLastWeek: 0,
          incNumFromLastMonth: 0
        },
        analysisMemberCompare: {
          totalNum: 0,
          incNumFromYesterday: 0,
          incNumFromLastWeek: 0,
          incNumFromLastMonth: 0
        },
        analysisConcernCompare: {
          totalNum: 0,
          incNumFromYesterday: 0,
          incNumFromLastWeek: 0,
          incNumFromLastMonth: 0
        }
      },
      pageConfig: {
        pageSize: 10,
        pageNum: 1
      },
      tableParams: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date()
      },
      chartParams: {
        begin: new Date(new Date().getTime() - 3600 * 1000 * 24 * 7),
        end: new Date(),
        type: 0
      },
      tendCountList: [
        { value: 0, label: '新增用户数' },
        { value: 1, label: '新增会员数' },
        { value: 2, label: '新增关注数' },
        { value: 3, label: '取消关注数' }
      ],
      pickerOptions: {
        disabledDate: (time) => {
          // 不能选择今天之后的天
          return time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime();
        },
        shortcuts: [
          {
            text: '最近7天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近15天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', date);
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      pickerOptions1: {
        disabledDate: (time) => {
          // 不能选择今天之后的天
          return time.getTime() > Date.now() || time.getTime() < new Date('2019/6/1').getTime();
        }
      },
      tendLoading: false,
      keyLoading: false,
      tableLoading: false,
      tableData: [],
      tendData: {
        columns: ['time', 'count'],
        rows: [
          { time: '01/01', count: 10 },
          { time: '01/03', count: 20 },
          { time: '01/05', count: 30 },
          { time: '01/07', count: 25 },
          { time: '01/09', count: 15 },
          { time: '01/11', count: 5 }
        ]
      },
      tendSettings: {
        metrics: ['count'],
        dimension: ['time'],
        labelMap: { count: '数量（人）' }
      }
    };
  },
  created() {
    this.getUserIndex();
    this.getUserChart();
    this.getUserTable();
  },
  mounted () {
    this.keyLoading = true;
    this.tendLoading = true;
    this.tableLoading = true;
  },
  components: {
    VeLine
  },
  methods: {
    handleSizeChange(val) {
      this.pageConfig.pageNum = 1;
      this.pageConfig.pageSize = val;
      // TODO:获取表格数据
    },
    handleCurrentChange(val) {
      this.pageConfig.pageNum = val;
      // TODO:获取表格数据
    },
    getDate(date) {
      var index = date.indexOf(' ');
      return date.substring(0, index);
    },
    parseDate(date) {
      var year = date.getFullYear();// 获取完整的年份(4位,1970-????)
      var month = date.getMonth() + 1;
      month = month >= 10 ? month : '0' + month;
      var day = date.getDate();
      day = day >= 10 ? day : '0' + day;
      return month + '/' + day;
    },
    cChartType() {
      this.tendLoading = true;
      this.getUserChart();
    },
    cChartLeftDate() {
      if (this.chartParams.end.getTime() >= this.chartParams.begin.getTime()) {
        this.chartParams.begin = new Date(this.chartParams.begin.getTime() + this.timeDifference);
        this.tendLoading = true;
        this.getUserChart();
      } else {
        this.$showAlert('结束时间必须大于开始时间', 'error');
      }
    },
    cChartRightDate() {
      if (this.chartParams.end.getTime() >= this.chartParams.begin.getTime()) {
        this.chartParams.end = new Date(this.chartParams.end.getTime() + this.timeDifference);
        this.tendLoading = true;
        this.getUserChart();  
      } else {
        this.$showAlert('结束时间必须大于开始时间', 'error');
      }
    },
    cTableLeftDate() {
      if (this.tableParams.end.getTime() >= this.tableParams.begin.getTime()) {
        this.tableParams.begin = new Date(this.tableParams.begin.getTime() + this.timeDifference);
        this.tableLoading = true;
        this.getUserTable();
      } else {
        this.$showAlert('结束时间必须大于开始时间', 'error');
      }
    },
    cTableRightDate() {
      if (this.tableParams.end.getTime() >= this.tableParams.begin.getTime()) {
        this.tableParams.end = new Date(this.tableParams.end.getTime() + this.timeDifference);
        this.tableLoading = true;
        this.getUserTable();
      } else {
        this.$showAlert('结束时间必须大于开始时间', 'error');
      }
    },
    getUserIndex() {
      this.$axios({
        method: 'get',
        url: '/analysis/user/index'
      }).then(res => {
        this.keyLoading = false;
        if (res.code === 200) {
          this.userIndex = res.data;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    getUserChart() {
      this.$axios({
        method: 'get',
        url: '/analysis/user/chart',
        params: {
          begin: this.chartParams.begin,
          end: this.chartParams.end,
          type: this.chartParams.type
        }  
      }).then(res => {
        if (res.code === 200) {
          this.tendData.rows = res.data;
          this.tendLoading = false;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        console.log('begin:' + this.chartParams.begin + ' end:' + this.chartParams.end);
        this.$showAlert(e.message, 'error');
      })
    },
    getUserTable() {
      this.$axios({
        method: 'get',
        url: '/analysis/user/table',
        params: {
          begin: this.tableParams.begin,
          end: this.tableParams.end
        }
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.tableLoading = false;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    },
    downloadExcel() {
      if (this.tableParams.end.getTime() >= this.tableParams.begin.getTime()) {
        this.$axios({
          method: 'get',
          url: '/analysis/user/excel',
          params: {
            begin: this.tableParams.begin,
            end: this.tableParams.end
          },
          responseType: 'blob'
        }).then(res => {
          this.download(res);
        }).catch(e => {
          this.$showAlert(e.message, 'error');
        })
      }
    },
    download(data) {
       if (!data) {
           return
       }
       var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
       var url = window.URL.createObjectURL(blob);
       var aLink = document.createElement('a');
       aLink.style.display = 'none';
       aLink.href = url;
       var fileName = '用户分析表.xls';
       aLink.setAttribute('download', fileName);
       document.body.appendChild(aLink);
       aLink.click();
       document.body.removeChild(aLink); // 下载完成移除元素
       window.URL.revokeObjectURL(url); // 释放掉blob对象
    }
  }
};
</script>

<style scoped lang="scss">
.userAnalysis {
  .bar {
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(245, 244, 247);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    .line {
      width: 5px;
      height: 20px;
      background-color: #1ab394;
      margin-right: 10px;
    }

    .title {
      flex: 1;
    }
  }

  .key-div {
    border: 1px solid rgb(196, 193, 193);
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.3%;
      margin-top: 15px;
      margin-bottom: 5px;

      .key-title {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .count {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .content {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    .key-line {
      width: 1px;
      height: 110px;
      background-color: rgb(196, 193, 193);
    }
  }
}
</style>
