<!--预定规则-->
<template>
  <div class="ReservationRule" v-loading="loading">
    <div class="div-input">
      <span class="label">规则名称:</span>
      <el-input class="input-value" v-model="des.ruleName" placeholder="请输入" clearable></el-input>
    </div>
    <div class="div-input">
      <span class="label">可预约周期(天):</span>
      <el-input
        class="input-value"
        v-model="des.reserveDay"
        placeholder="请输入"
        clearable
        v-enter-number-int
      ></el-input>
    </div>
    <el-button class="c-btn" style="margin-left: 30px;" @click="onSave">保存</el-button>
  </div>
</template>

<script>
export default {
  name: 'ReservationRule',
  data() {
    return {
      loading: false,
      des: {}
    };
  },
  created() {
    this.loading = true;
    this.getSet();
  },
  methods: {
    getSet() {
      this.$axios({
        method: 'get',
        url: '/room/rule/reservation'
      }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.des = res.data;
        } else {
          // 请求出错
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      });
    },
    onSave() {
      if (this.des.name === '') {
        this.$message.warning('规则名称不能为空!');
        return;
      }
      if (this.des.day === '') {
        this.$message.warning('可预约周期不能为空!');
        return;
      }
      this.$axios({
        method: 'put',
        url: 'room/rule/reservation',
        params: {
          name: this.des.ruleName,
          day: this.des.reserveDay
        }
      }).then(res => {
        if (res.code === 200) {
          this.$showAlert('保存规则成功', 'success');
        } else {
          this.$showAlert(res.message, 'error');
        }
      }).catch(e => {
        this.$showAlert(e.message, 'error');
      })
    }
  }
};
</script>

<style scoped lang="scss">
.ReservationRule {
  padding-bottom: 20px;

  .div-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    .input-value {
      width: 350px;
    }
  }
  .label {
    width: 160px;
    margin-right: 30px;
    text-align: right;
  }
}
</style>
