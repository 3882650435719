























import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {getCookie, baseServiceUrl, deepCopy} from '@/plugins/util';

@Component({

    /* 这里和js版本编写的 vue 组件内容相同，
     * 凡是不能在ts里面完成的都可以在这里完成 
     * 最终会被合并到一个实例中。
     * 在这里定义的内容，不会被语法器获取到，因此必须要同步在class中声明
     */
    data() {
        return {}
    }
})

export default class uploadImg extends Vue {
  // data
  serverUrl: string = baseServiceUrl() + '/image/upload';
  loading: boolean = false
  fileList: Array<object> = []
  headers: object = {
    token: getCookie('token')
  }

  @Prop() multiple!: boolean;
  @Prop() initImg!: Array<any>;
  @Prop({default: 100}) imgSize!: number;

  // methods
  uploadSuccess(res:any, file:any): void {
    const fileObj = {
      name: file.name,
      url: res.data[file.name]
    }
    // 单个文件上传，会覆盖之前的文件
    if (!this.multiple) {
      this.fileList = [fileObj]
    } else {
      this.fileList.push(fileObj)
    }
    this.loading = false
    this.$emit('successCallBack', this.fileList)
  }
  beforeUpload(file:any) : boolean {
    // 这里检测是否是图片
    const imgArr = ['bmp', 'dib', 'rle', 'emf', 'gif', 'jpg', 'jpeg', 'png']
    const fileName = file.name
    const suffix = fileName.split('.')[fileName.split('.').length - 1]
    const findIndex = imgArr.findIndex(item => item === suffix.toLocaleLowerCase()) > -1
    const size : number = file.size
    if (!findIndex) {
      this.$showAlert('请选择一张图片上传！', 'warning');
      return false
    }
    if (size > this.imgSize * 1024) {
      this.$showAlert('请选择小于' + this.imgSize + 'kb图片上传！', 'warning');
      return false
    }
    return true
  }

  uploadError(): void{
    this.loading = false
    this.$showAlert('上传失败', 'error');
  }

  @Watch('initImg')
  watchInitImg(newVal:any) {
    this.fileList = deepCopy(this.initImg) || []
  }
  // 生命周期
  created() {
  }
}
