<!--经营分析-->
<template>
  <div class="OperateAnalysis">
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户分析" name="first">
        <UserAnalysis/>
      </el-tab-pane>
      <el-tab-pane label="订单分析" name="second" :lazy="true">
        <OrderAnalysis/>
      </el-tab-pane>
      <el-tab-pane label="财务分析" name="third" :lazy="true">
        <FinanceAnalysis/>
      </el-tab-pane>
      <el-tab-pane label="伞位分析" name="fourth" :lazy="true">
        <UmbrellaAnalysis/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UserAnalysis from './UserAnalysis';
import OrderAnalysis from './OrderAnalysis';
import FinanceAnalysis from './FinanceAnalysis';
import UmbrellaAnalysis from './UmbrellaAnalysis';
export default {
  name: 'OperateAnalysis',
  data() {
    return {
      activeName: 'first',
      firstUrl: 'userAnalysis',
      secondUrl: 'orderAnalysis',
      thirdUrl: 'financeAnalysis',
      fourthUrl: 'umbrellaAnalysis',
      menuAndPermission: []
    };
  },
  mounted() {
    
  },
  watch: {
    $route: {
      handler(newRoute) {
        var path = newRoute.fullPath;
        if (path === ('/' + this.firstUrl)) {
          this.activeName = 'first';
        } else if (path === ('/' + this.secondUrl)) {
          this.activeName = 'second';
        } else if (path === ('/' + this.thirdUrl)) {
          this.activeName = 'third';
        } else {
          this.activeName = 'fourth';
        }
      },
      immediate: true
    },
    'activeName': function(val) {
      let path = '';
      this.menuAndPermission = this.$store.state.menuAndPermission;
      if (this.activeName === 'first') {
        this.$router.push(this.firstUrl);
      } else if (this.activeName === 'second') {
        this.$router.push(this.secondUrl);
      } else if (this.activeName === 'third') {
        this.$router.push(this.thirdUrl);
      } else {
        this.$router.push(this.fourthUrl);
      }
      let routeArr = [];
      for (let i in this.menuAndPermission) {
        for (let j in this.menuAndPermission[i].child) {
          console.log(this.menuAndPermission[i].child[j]);
          if (this.menuAndPermission[i].child[j].href === this.$route.path.substring(1)) {
              routeArr.unshift({
                index: j,
                name: this.menuAndPermission[i].child[j].name,
                url: this.menuAndPermission[i].child[j].href
              });
              routeArr.unshift({
                index: i,
                name: this.menuAndPermission[i].name,
                url: this.menuAndPermission[i].href
              });
              break;
            }
        }
      }
      this.$store.commit('setMenuActive', routeArr);
    }
  },
  components: {
    UserAnalysis,
    OrderAnalysis,
    FinanceAnalysis,
    UmbrellaAnalysis
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
.OperateAnalysis {
  .el-tabs__header {
    padding: 20px;
  }

  .el-tabs__nav {
    div {
      font-size: 18px;
    }
  }
}
</style>
