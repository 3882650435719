import { render, staticRenderFns } from "./PublicNumberSet.vue?vue&type=template&id=52a16fd2&scoped=true&"
import script from "./PublicNumberSet.vue?vue&type=script&lang=js&"
export * from "./PublicNumberSet.vue?vue&type=script&lang=js&"
import style0 from "./PublicNumberSet.vue?vue&type=style&index=0&id=52a16fd2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a16fd2",
  null
  
)

export default component.exports