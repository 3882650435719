<!--租赁协议-->
<template>
  <div class="WristbandProtocal" v-loading="loading">
    <div class="div-input">
      <span class="label">租赁有效期:</span>
      <el-input class="input-value" v-model="des.braceletValidityTime" placeholder="请输入" clearable v-enter-number-int>
        <template slot="append">天</template>
      </el-input>
    </div>
    <div class="div-logo">
      <span class="label">展示详情:</span>
      <yd-ue v-model="des.braceletAgreement" class="introduce"></yd-ue>
    </div>
    <el-button class="c-btn" style="margin-left: 20px;" @click="onSave">保存</el-button>
  </div>
</template>

<script>
export default {
  name: 'WristbandProtocal',
  data() {
    return {
      loading: false,
      des: { braceletAgreement: '', braceletValidityTime: '' }
    };
  },
  created() {
    this.getWristbandDesc();
  },
  methods: {
    getWristbandDesc() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/bracelet/getBraceletDes'
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.des = res.data;
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSave() {
      if (this.des.braceletValidityTime === '') {
        this.$message.warning('商品标题不能为空!');
        return;
      }
      if (this.des.braceletAgreement === '') {
        this.$message.warning('展示详情不能为空!');
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/editBraceletDes',
        data: this.des
      })
        .then(res => {
          this.loading = false;
          if (res.code === 200) {
            this.$showAlert(res.message);
          } else {
            // 请求出错
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.WristbandProtocal {
  padding-bottom: 20px;

  .div-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .input-value {
      width: 300px;
    }
  }
  .label {
    width: 120px;
    margin-right: 30px;
    text-align: right;
  }

  .div-logo {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .introduce {
      width: 70%;
    }
  }
}
</style>
