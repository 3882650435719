<!--储物柜管理-->
<template>
  <div class="LockerManage">
    <el-tabs v-model="activeName">
      <el-tab-pane label="格口列表" name="first">
        <GridList/>
      </el-tab-pane>
      <el-tab-pane label="储物柜维护" name="second">
        <Maintain/>
      </el-tab-pane>
      <el-tab-pane label="运营设置" name="third">
        <Setting/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import GridList from './GridList';
import Maintain from './Maintain';
import Setting from './Setting';
export default {
  name: 'LockerManage',
  data() {
    return {
      activeName: 'first'
    };
  },
  mounted() {},
  components: {
    GridList,
    Maintain,
    Setting
  },
  methods: {}
};
</script>

<style lang="scss">
.LockerManage {
  .el-tabs__header {
    padding: 20px;
  }

  .el-tabs__nav {
    div {
      font-size: 18px;
    }
  }
}
</style>
