<!--手环退还-->
<template>
  <div class="WristbandReturn order-common" v-loading="loading">
    <div class="title-box">
      <div class="title">手环退还</div>
    </div>
    <!-- <div class="read-card">
      <div style="margin-right: 15px;">请选择读卡器：</div>
      <div style="flex: 1;">
        <el-radio-group v-model="currentCardId">
          <el-radio
            :label="3"
            style="margin-bottom:5px"
            v-for="(cardItem, index) in 4"
            :key="index"
          >备选项</el-radio>
        </el-radio-group>
      </div>
    </div>-->
    <!-- <div class="line"></div> -->
    <el-form :inline="true" :model="formData" class="form-inline" style="padding-right: 100px;">
      <el-form-item label="物理编号:">
        <el-input v-model="formData.physicalEncoding" placeholder="请刷卡" clearable></el-input>
      </el-form-item>
      <!-- <el-form-item label="设备编号:">
        <el-input
          v-model="formData.deviceId"
          placeholder="请输入"
          clearable
          type="number"
          v-enter-number
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" class="c-btn" size="mini" @click="onQuery()">检索</el-button>
      </el-form-item>
    </el-form>
    <div class="card-table">
      <el-table class="c-table" :data="tableData" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="physicalEncoding" label="物理编码">
          <template slot-scope="scope">
            <span>{{scope.row.physicalEncoding ? scope.row.physicalEncoding : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceId" label="设备编码">
          <template slot-scope="scope">
            <span>{{scope.row.deviceId ? scope.row.deviceId : ''}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="braceletLeaseStatus" label="商品状态">
          <template slot-scope="scope">
            <span>{{scope.row.braceletLeaseStatus ? braceletLeaseStatus[scope.row.braceletLeaseStatus] : ''}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="deviceOrderCode" label="订单编号">
          <template slot-scope="scope">
            <span>{{scope.row.deviceOrderCode ? scope.row.deviceOrderCode : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="联系人">
          <template slot-scope="scope">
            <span>{{scope.row.userName ? scope.row.userName : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userTelephone" label="联系方式">
          <template slot-scope="scope">
            <span>{{scope.row.userTelephone ? scope.row.userTelephone : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isReturn" label="是否可退">
          <template slot-scope="scope">
            <span>{{scope.row.deviceStatus == 1 ? '是' : '否'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="checked" label="是否退还">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.checked"
              :disabled="scope.row.deviceStatus === 1"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-btn">
      <!-- <el-button type="primary" class="c-btn" @click="cancelAll()">全部取消</el-button> -->
      <el-button type="primary" class="c-btn" @click="confirmReturn()">确定退还</el-button>
      <el-button class="c-btn" @click="goBack()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WristbandReturn',
  data() {
    return {
      loading: false,
      formData: {},
      searchCondition: {},
      // currentCardId: "",
      tableData: [],
      braceletLeaseStatus: {
        1: '未使用',
        2: '已使用',
        3: '已退款',
        4: '已逾期'
      }
    };
  },
  created() {},
  methods: {
    getWristbandDetail() {
      this.loading = true;
      this.$axios({
        method: 'get',
        url: '/bracelet/getBraceletBack',
        params: this.searchCondition
      })
        .then(res => {
          if (res.code === 200) {
            const data = res.data ? res.data : {};
            if (data == null || data == '' || data.id == null) {
              this.$message.error('未找到已租用的手环设备');
              this.loading = false;
              return;
            }
            let isExit = false;
            for (const item of this.tableData) {
              if (item.id == data.id) {
                isExit = true;
                break;
              }
            }
            if (isExit) {
              this.$message.warning('设备已添加');
            } else {
              // data.checked = false;
              this.tableData.push(data);
            }
            this.loading = false;
          } else {
            // 请求出错
            this.loading = false;
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    cancelAll() {
      for (const item of this.tableData) {
        item.checked = false;
      }
    },
    confirmReturn() {
      const idList = [];
      for (const item of this.tableData) {
        if (item.checked) {
          idList.push(item.id);
        }
      }
      if (idList.length == 0) {
        this.$message.warning('请选择设备');
        return;
      }
      this.loading = true;
      this.$axios({
        method: 'post',
        url: '/bracelet/braceletBack',
        data: idList
      })
        .then(res => {
          if (res.code === 200) {
            this.loading = false;
            this.$showAlert(res.message);
            this.tableData = [];
            this.formData = {};
            this.searchCondition = {};
          } else {
            // 请求出错
            this.loading = false;
            this.$showAlert(res.message, 'error');
          }
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
    onQuery() {
      this.searchCondition = { ...this.formData };
      this.getWristbandDetail();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/order-detail.scss";

.WristbandReturn {
  flex: 1;
  margin-left: 50px;
  margin-right: 50px;

  .umbre-value {
    span {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
  .el-card.is-always-shadow {
    box-shadow: none;
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .form-inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .read-card {
    display: flex;
    flex-direction: row;
    color: #676a6c;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .line {
    height: 1px;
    background: #676a6c;
    flex: 1;
    margin-bottom: 20px;
  }

  .bottom-btn {
    margin-top: 20px;
    padding-bottom: 20px;
    border: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
